import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Membership} from '../../../admin/interfaces/membership';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {UserMembershipsService} from '../../../admin/services/user-memberships.service';
import {take} from 'rxjs/operators';
import { MembershipService } from 'src/app/admin/services/membership.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  @Input() user: any;
  membership: Membership;
  currentDate: number = (new Date()).getTime();
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron membresías registradas',
    title: 'Historial de membresías'
  };

  constructor(private _userMemberships: UserMembershipsService,
              private _membership: MembershipService) {
  }

  async ngOnInit() {
    this.membership = await this._membership.get(this.user.membership.reference.id).pipe(take(1)).toPromise();
  }
}
