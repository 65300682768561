import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {CategoryService} from '../../services/category.service';
import {Observable, of, Subscription} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Category} from '../../interfaces/category';
import {CategoryModalComponent} from '../../modals/category-modal/category-modal.component';
import {VideoService} from '../../services/video.service';
import {Video} from '../../interfaces/video';
import {ProgramsService} from '../../services/programs.service';
import {take} from 'rxjs/operators';
import {ObjectService} from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  videosSubscription: Subscription = new Subscription();
  videos: Video[] = [];
  categories$: Observable<Category[]>;
  categories: Category[];
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron categorías registradas',
    title: 'Lista de categorías'
  };
  categoriesSubscription: Subscription = new Subscription();
  programsIndexed = {};
  programs = [];
  programSelected = null;

  constructor(private _category: CategoryService,
              private _modal: BsModalService,
              private _video: VideoService,
              private _program: ProgramsService) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadPrograms();

    this.categoriesSubscription = this._category.getAll().subscribe(async data => {
      this.categories = data.filter(item => (item.program || '').toLowerCase() != 'challenge');

      this.handleChangeProgram()
    });

    this.videosSubscription = this._video.getAll().subscribe(data => {
      this.videos = data;
    });
  }

  ngOnDestroy() {
    this.categoriesSubscription.unsubscribe();
    this.videosSubscription.unsubscribe();
  }

  openAddCategoryModal() {
    this._modal.show(CategoryModalComponent, {
      initialState: {
        lastPosition: this.categories.length
      },
      class: 'modal-lg'
    });
  }

  editCategory(category: Category) {
    this._modal.show(CategoryModalComponent, {
      initialState: {
        category: {...category}
      },
      class: 'modal-lg'
    });
  }

  getTotal(key: string) {
    let count = 0;

    for (let i = 0; i < this.videos.length; i++) {
      if (!this.videos[i].isImage && this.videos[i].categories.filter(item => item.id == key).length == 1) {
        count++;
      }
    }

    return count;
  }

  async deleteCategory(category: Category, position: number) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar esta categoría?', '')) {

      await this._category.update(this.categories[position - 1].key, {position: -1} as Category);
      for (let i = position; i < this.categories.length; i++) {
        await this._category.update(this.categories[i].key, {position: this.categories[i].position - 1} as Category);
      }

      this._category.delete(category.key);

      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }

  async raisePosition(position: number, key: string) {
    if (position == 1) {
      return;
    }
    let raiseIndex = this.categories.findIndex(category => category.position == position - 1);
    await this._category.update(this.categories[raiseIndex].key, {position: this.categories[raiseIndex].position + 1} as Category);
    await this._category.update(key, {position: position - 1} as Category);
  }

  async lowerPosition(position: number, key: string) {
    if (this.categories.length == position) {
      return;
    }
    let raiseIndex = this.categories.findIndex(category => category.position == position + 1);
    await this._category.update(this.categories[raiseIndex].key, {position: this.categories[raiseIndex].position - 1} as Category);
    await this._category.update(key, {position: position + 1} as Category);
  }

  async handleChangePosition(row: any) {
    const newPosition = await AlertService.input('Nueva posición', '', 'Aceptar', 'number');

    this._category.update(row.key, {position: +newPosition} as Category);
  }

  handleChangeProgram() {
    this.categories$ = of([
      ...this.categories.filter(category => category.program == this.programSelected.code)
    ]);
  }

  async loadPrograms() {
    this.programs = (await this._program.getAll().pipe(take(1)).toPromise())
      .filter((program: any) => !program.trash);

    if (!this.programs[0]) return;

    this.programsIndexed = ObjectService.indexArray(this.programs, 'code');
    this.programSelected = this.programs[0];
  }
}

