import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PaymentType} from '../../../shared/enums/payment-type.enum';

@Component({
  selector: 'app-income-report',
  templateUrl: './income-report.component.html',
  styleUrls: ['./income-report.component.css']
})
export class IncomeReportComponent implements OnInit, OnChanges {
  @Input() history: any[] = [];
  @Input() stripeNet: number = 0;
  @Input() startDate: any;
  @Input() endDate: any;
  incomeTransfers: number = 0;
  incomePaypal: number = 0;
  incomeStripe: number = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.filterIncomes();
  }

  filterIncomes() {
    this.incomeTransfers = 0;
    this.incomePaypal = 0;

    const paypalPayments = this.history
      .filter((payment: any) => payment.paymentType === PaymentType.PAYPAL)
      .filter((payment: any) => !!payment.price)

    const transferPayments = this.history
      .filter((payment: any) => payment.paymentType === PaymentType.INTERBANK)
      .filter((payment: any) => !!payment.price)

    this.incomeTransfers = transferPayments.reduce((sum: number, payment: any) => sum + payment.price, 0);

    this.incomePaypal = paypalPayments.reduce((sum: number, payment: any) => sum + payment.price, 0);

    this.incomeStripe = this.stripeNet
  }
}
