import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../interfaces/user';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from '../../services/history.service';
import {Membership} from '../../interfaces/membership';
import {PreviewTicketComponent} from '../preview-ticket/preview-ticket.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Status} from '../../../shared/enums/status.enum';
import {MembershipService} from '../../services/membership.service';
import {ProgramsService} from '../../services/programs.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.css']
})
export class PaymentDetailComponent implements OnInit {
  user$: Observable<User>;
  payment$: Observable<any>;
  paymentKey: string;
  userKey: string;
  membership: Membership;
  rangeEnd: any = false;
  programs: any = [];

  constructor(private _user: UserService,
              private activatedRoute: ActivatedRoute,
              private _history: HistoryService,
              public router: Router,
              private _membership: MembershipService,
              private _program: ProgramsService,
              private modal: BsModalService) {
  }

  async ngOnInit(): Promise<void> {
    this.programs = (await this._program.getAll().pipe(take(1)).toPromise()).filter((program: any) => !program.trash);
    this.paymentKey = this.activatedRoute.snapshot.params['paymentKey'];
    this.userKey = this.activatedRoute.snapshot.params['userKey'];
    this.user$ = this._user.get(this.userKey);
    this.payment$ = this._history.getPayment(this.userKey, this.paymentKey);

    this._history.getPayment(this.userKey, this.paymentKey).subscribe(resp => console.log(resp, 'cale'));
    this._user.get(this.userKey).subscribe(resp => console.log(resp));
  }

  async openModalTicket(imageUrl: any) {
    this.modal.show(PreviewTicketComponent, {
      initialState: {
        imageUrl
      },
      class: 'modal-dialog-centered bg-transparent'
    });
  }

  async handleAcceptPayment(payment, user, membership) {
    if (await AlertService.confirm('¿Estás seguro de que deseas aceptar este pago?')) {
      this._history.update(this.userKey, payment.key, {status: Status.PAID});
      await this._user.update(this.userKey, {
        membership: {
          ...user.membership,
          status: Status.PAID
        }
      } as User);
      await AlertService.success('Pago aceptado correctamente');
      await this.sendMailPDF(membership, user);
      this.router.navigateByUrl('admin/payments');
    }
  }

  async sendMailPDF(membership, user) {
    const programFound: any = this.verifyWelcomeFileInMembership(membership);
    if (!!programFound && !!programFound.welcomeFile && await AlertService.confirm(`¿Desea que le enviemos el plan de alimentación a ${user.email}?`)) {
      await this._membership.sendMail(user.email, membership.name, user.firstName, user.lastName, programFound.welcomeFile);

      AlertService.toastSuccess('Se ha enviado el plan de alimentación');
    }
  }

  verifyWelcomeFileInMembership(membership) {
    if (!membership) return null;
    return this.programs.find(program => membership.name
      .toLowerCase()
      .includes(program.name.toLowerCase()));
  }

  async handleCancelPayment(payment) {
    if (await AlertService.confirm('¿Estás seguro de que deseas cancelar este pago?')) {
      this._history.update(this.userKey, payment.key, {trash: true});
      AlertService.success('Pago cancelado correctamente');
      this.router.navigateByUrl('admin/payments');
    }
  }

}
