import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {UserService} from '../../admin/services/user.service';
import {AuthService} from '../template-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private _auth: AuthService,
              private _user: UserService) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();

    if (!this._auth.user) {
      if (state.url == '/auth/login') return true;
      return this.router.navigateByUrl('auth');
    }

    return true;
  }
}
