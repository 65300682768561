<div class="p-2">
  <h4 class="mb-0">Video</h4>

  <form [formGroup]="videoForm" class="mt-2">

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Título</mat-label>
          <input matInput
                 formControlName="title">
          <mat-error *ngIf="formSubmitted && formControls.title.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Fecha de creación</mat-label>
          <input matInput [matDatepicker]="datepicker" formControlName="createdAt" class="cursor-pointer"
                 (click)="datepicker.open()">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
          <mat-error *ngIf="formSubmitted && formControls.createdAt.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12" *ngIf="categories$ | async as categories">
        <div class="form-group">
          <label>Categorías
            <div class="btn btn-success btn-sm" (click)="createCategory()">Crear categoría</div>
          </label>
          <ng-select formControlName="categories" [items]="categories" [multiple]="true" bindLabel="name"></ng-select>
          <mat-error *ngIf="formSubmitted && formControls.categories.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="memberships$ | async as memberships">
        <div class="form-group">
          <label>Membresías</label>
          <ng-select formControlName="memberships" [items]="memberships" [multiple]="true" bindLabel="name"
                     placeholder="">
          </ng-select>
          <mat-error *ngIf="formSubmitted && formControls.memberships.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <mat-checkbox formControlName="isImageFile">¿El archivo es una imagen?</mat-checkbox>
        </div>
      </div>

      <div class="col-sm-12 d-flex flex-column">
        <mat-form-field class="w-100">
          <mat-label>Archivo</mat-label>
          <ngx-mat-file-input formControlName="fileUrl"
                              (change)="choosePicture($event)"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formSubmitted && formControls.fileUrl.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center" *ngIf="path">
          <img class="preview-image mt-1"
               [src]="path">
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary" (click)="submit()">Guardar</button>
    </div>
  </form>
</div>
