<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Receta</h4>

    <form [formGroup]="recipeForm" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Título</mat-label>
            <input matInput
                   formControlName="title">
            <mat-error *ngIf="formSubmitted && formControls.title.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label>Tipo</label>
            <ng-select formControlName="type"
                       [items]="recipeTypes"
                       bindLabel="name">
            </ng-select>
            <mat-error *ngIf="formSubmitted && formControls.type.errors">
              Es obligatorio
            </mat-error>
          </div>
        </div>

        <div class="col-sm-12 d-flex flex-column">
          <mat-form-field class="w-100">
            <mat-label>Imagen</mat-label>
            <ngx-mat-file-input formControlName="imageUrl"
                                (change)="choosePicture($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.imageUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div class="d-flex justify-content-center" *ngIf="path">
            <img class="preview-image mt-1"
                 [src]="path">
          </div>
        </div>

        <div class="col-sm-12 d-flex flex-column" *ngIf="formControls.type.value.id != 2">
          <mat-form-field class="w-100">
            <mat-label>Archivo</mat-label>
            <ngx-mat-file-input formControlName="fileUrl"
                                (change)="chooseFile($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.fileUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div class="d-flex justify-content-center" *ngIf="pathFile">
            <img class="preview-image mt-1"
                 [src]="pathFile">
          </div>
        </div>

        <div class="col-sm-12 d-flex flex-column mt-2" *ngIf="formControls.type.value.id == 2">
          <mat-form-field class="w-100">
            <mat-label>Link de compra</mat-label>
            <input matInput
                   formControlName="fileUrl">
            <mat-error *ngIf="formSubmitted && formControls.fileUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary" (click)="submit()">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
