import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {PurchasedMembershipEmailSettings} from '../interfaces/purchased-membership-email-settings';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  update(setting): Promise<void> {
    return this.afs.doc(`settings/setting`).update(setting);
  }

  get(): Observable<any> {
    return this.afs.doc<any>(`settings/setting`).valueChanges();
  }

  getSettingSections(): Observable<any> {
    return this.db.colWithIds$(`settings`);
  }

  updateSection(section: 'workoutSeries' | 'calendar' | 'challenge' | 'wellness' | 'videos' | 'program', data): Promise<void> {
    return this.afs.doc(`settings/${section}`).set(data);
  }

  getPurchasedMembershipEmailSettings(): Observable<PurchasedMembershipEmailSettings> {
    return this.db.doc$(`settings/PurchasedMembershipEmailSettings`);
  }

  updatePurchasedMembershipEmailSettings(purchasedMembershipEmailSettings: PurchasedMembershipEmailSettings): Promise<void> {
    return this.afs.doc(`settings/PurchasedMembershipEmailSettings`).set(purchasedMembershipEmailSettings);
  }
}
