<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Usuario</h4>

    <form [formGroup]="userForm" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Nombre</mat-label>
            <input matInput
                   formControlName="firstName">
            <mat-error *ngIf="formSubmitted && formControls.firstName.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Correo electrónico</mat-label>
            <input matInput
                   formControlName="email">
            <mat-error *ngIf="formSubmitted && formControls.email.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="datepicker" formControlName="birthDate" class="cursor-pointer"
                   (click)="datepicker.open()">
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
            <mat-error *ngIf="formSubmitted && formControls.birthDate.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Membresías</mat-label>
            <mat-select formControlName="membership">
              <mat-option *ngFor="let membership of memberships" [value]="membership">
                {{membership.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formSubmitted && formControls.membership.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Fecha de finalización de membresía</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="membershipEndTime" class="cursor-pointer"
                   (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="formSubmitted && userForm.value.membershipEndTime && userForm.value.membershipEndTime != ''">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <mat-radio-group aria-label="Select an option" formControlName="status">
          <mat-radio-button value="1" style="margin-left: 10px">PAGADO</mat-radio-button>
          <mat-radio-button value="0" style="margin-left: 10px">PENDIENTE</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary" (click)="submit()">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
