import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AchievementService} from '../../services/achievement.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Achievement} from '../../interfaces/achievement';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DocumentReference} from '@angular/fire/firestore';

@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.css']
})
export class AchievementModalComponent implements OnInit {
  ref: any;
  type: 'category' | 'program' | 'workoutSeries' = 'category';
  achievement: Achievement;
  achievementForm: FormGroup = this.fb.group({
    name: [''],
    ref: [null],
    goals: [''],
    icons: [{}],
    visible: [true]
  });
  submitted: boolean = false;

  constructor(private _achievement: AchievementService,
              private fb: FormBuilder,
              private modal: BsModalRef) {
  }

  async ngOnInit(): Promise<void> {
    const ref: DocumentReference = this._achievement.getReference(`${this.type == 'category'
      ? 'categories'
      : this.type == 'program'
        ? 'programs'
        : 'workoutSeries'}/${this.ref.key}`);

    this.achievement = await this._achievement.getByRef(ref);

    this.achievementForm.patchValue({
      name: this.achievement?.name || this.ref.name || this.ref.title,
      goals: this.achievement?.goals || '1, 10, 15, 20, 25',
      icons: this.achievement?.icons || {},
      ref,
      visible: this.achievement?.visible
    });
  }

  async onSubmit() {
    this.submitted = true;

    if (this.achievementForm.invalid) return;

    if (await AlertService.confirm('¿Estás seguro de guardar los cambios?')) {
      this.achievement
        ? await this._achievement.update(this.achievement.key, this.achievementForm.value)
        : await this._achievement.add(this.achievementForm.value);

      AlertService.toastSuccess('Cambios guardados');
      this.onCancel();
    }
  }

  onCancel() {
    this.modal.hide();
  }

  get goalsArray() {
    return this.achievementForm.get('goals').value.replaceAll(' ', '').split(',').map(goal => Number(goal));
  }

  changeGoal($event: File, goal: any) {
    this.achievementForm.patchValue({
      icons: {
        ...this.achievementForm.get('icons').value,
        [goal]: $event
      }
    });
  }
}
