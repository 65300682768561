// IMPORTS
import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection, AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryFn} from '@angular/fire/firestore/interfaces';
import {AngularFireStorage} from '@angular/fire/storage';

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) {
          return data;
        }
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  createID() {
    return this.db.createId();
  }

  async uploadFile(path: string, file: File) {
    const snapshot = await this.storage.upload(path, file);
    return await snapshot.ref.getDownloadURL();
  }
}
