import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectService} from '../../template-services/object.service';

@Component({
  selector: 'ti-file',
  templateUrl: './ti-file.component.html',
  styleUrls: ['./ti-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TiFileComponent,
      multi: true
    }
  ]
})
export class TiFileComponent implements OnInit, ControlValueAccessor {
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  @Input() routeFile: string = `images/${new Date().getTime()}`;
  @Input() extension: string = 'png';
  @Input() showPreview: boolean = false;
  @Input() label: string = 'Archivo';
  @Input() errorLabel: string = 'Es obligatorio';
  @Input() formControl: any;
  @Input() url: string;
  @Output() onChangeFile: EventEmitter<File> = new EventEmitter<File>();
  path: any;
  isInputValid: boolean = false;
  submitted: boolean = false;
  isImage: boolean = false;
  onChange: Function;

  constructor() {
  }

  ngOnInit(): void {
    if (this.url) {
      this.path = this.url;
      this.loadMetadata();
    }
  }

  loadMetadata() {
    fetch(this.url).then(response => {
      const fileContentType = response.headers.get('Content-Type');

      this.isImage = fileContentType.includes('image');
    });
  }

  writeValue(value) {
    if (!ObjectService.isFile(value)) {
      this.path = value;
      this.loadMetadata();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }

  onFileChange(event) {
    if (event.target.files) {
      this.isImage = event.target.files[0].type.includes('image');

      let reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };

      reader.readAsDataURL(event.target.files[0]);

      this.onChangeFile.emit(event.target.files[0]);
      this.onChange(event.target.files[0]);
    }
  }

}
