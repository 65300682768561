import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Membership} from '../interfaces/membership';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private http: HttpClient) {

  }

  getAll(): Observable<Membership[]> {
    return this.db.colWithIds$<Membership>('memberships', ref => ref
      .where('trash', '==', false)
      .orderBy('position', 'asc'));
  }

  getAllActives(): Observable<Membership[]> {
    return this.db.colWithIds$<Membership>('memberships', ref => ref
      .where('trash', '==', false)
      .where('isActive', '==', true)
      .orderBy('position', 'asc'));
  }

  getAllWithTrash(): Observable<Membership[]> {
    return this.db.colWithIds$<Membership>('memberships', ref => ref
      .orderBy('position', 'asc'));
  }

  add(membership: Membership): Promise<DocumentReference> {
    return this.afs.collection(`memberships`).add(membership);
  }

  update(membershipKey: string, membership: Membership): Promise<void> {
    return this.afs.doc(`memberships/${membershipKey}`).update(membership);
  }

  get(membershipKey: string): Observable<Membership> {
    return this.db.doc$<Membership>(`memberships/${membershipKey}`);
  }

  delete(membershipKey: string): void {
    this.afs.doc(`memberships/${membershipKey}`).update({trash: true});
  }

  getReference(membershipKey: string): DocumentReference {
    return this.db.doc(`memberships/${membershipKey}`).ref;
  }

  createPlan(productName: string, amount: number, interval: string, intervalCount: number) {
    return this.http.post(`${environment.apiBaseURL}/createPlan`, {
      productName,
      amount: amount * 100,
      interval,
      intervalCount
    }).pipe(take(1)).toPromise();
  }

  createSession(isFirstTime: boolean, email: string, amount: number, description, planId, isOncePayment, couponId): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/createSession`, {
      amount: amount * 100,
      description,
      isOncePayment,
      planId,
      couponId,
      email,
      isFirstTime
    }).pipe(take(1))
      .toPromise();
  }

  getSession(sessionId: string): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/getSession`, {sessionId})
      .pipe(take(1))
      .toPromise();
  }

  cancel(subscription): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/cancelSubscription`, {
      subscription
    }).pipe(take(1))
      .toPromise();
  }

  sendMail(user: string, membership: string, name: string, lastName: string, welcomeFile: string) {
    return this.http.post(`${environment.apiBaseURL}/sendMailPDF`, {
      name,
      user,
      membership,
      lastName,
      welcomeFile
    }).pipe(take(1)).toPromise();
  }

  addCancellationOpinion(optionSelected: any, customOption: any, userKey: string) {
    this.db.col(`cancellationOpinions`).add({
      optionSelected,
      customOption,
      user: this.db.getReference(`users/${userKey}`),
      createdAt: new Date().getTime()
    });
  }

  getCancellationOpinions() {
    return this.db.colWithIds$('cancellationOpinions');
  }
}
