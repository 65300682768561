import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../admin/services/user.service';
import {User} from '../../../admin/interfaces/user';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UpdateProfileComponent} from '../../modals/update-profile/update-profile.component';
import {Subscription} from 'rxjs';
import {ChangePasswordComponent} from "../../modals/change-password/change-password.component";
import {ChangeEmailComponent} from "../../modals/change-email/change-email.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: User;
  tabIndex: number = 0;
  userSubscription: Subscription;

  constructor(private _user: UserService,
              private _modal: BsModalService) {
  }

  async ngOnInit() {
    this.userSubscription = this._user.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async editUser() {
    this._modal.show(UpdateProfileComponent, {
      initialState: {
        user: {...this.user},
      }
    });
  }

  async changePassword() {
    this._modal.show(ChangePasswordComponent, {
      initialState: {
        user: {...this.user},
      }
    });
  }

  async changeEmail() {
    this._modal.show(ChangeEmailComponent, {
      initialState: {
        user: {...this.user},
      }
    });
  }
}
