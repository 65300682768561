<block-ui>
  <ng-container *ngIf="!load">
    <div class=" d-flex flex-row align-items-end mb-2">
      <div class="d-flex filter" style="gap: 10px">
        <mat-form-field class="w-100">
          <mat-label>Fecha de inicio</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="startDate"
            [ngModelOptions]="{standalone: true}"
          >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Fecha final</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            [(ngModel)]="endDate"
            [ngModelOptions]="{standalone: true}"
          >
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <div>
          <button
            class="btn btn-primary"
            (click)="filterBetweenDate()"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12" *ngIf="isCurrentMonth">
        <h4 class="mb-2">Usuarios que han renovado este mes hasta el día de hoy {{ currentDay }}</h4>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Usuarios y ventas
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-4">
            <div class="card pull-up">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">Usuarios nuevos</h6>
                      <h3>{{ filterUsers?.length }}</h3>
                    </div>
                    <div class="align-self-center">
                      <i class="feather ft-user font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card pull-up">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">Usuarios renovados</h6>
                      <h3>{{ totalTransfers + stripeChargesCount }}</h3>
                    </div>
                    <div class="align-self-center">
                      <i class="feather ft-clipboard font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card pull-up">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">Usuarios activos</h6>
                      <h4>{{activeUsers}}</h4>
                    </div>
                    <div class="align-self-center">
                      <i class="feather ft-clipboard font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <app-income-report
              [history]="filterHistory"
              [stripeNet]="stripeNet"
              [startDate]="startDate"
              [endDate]="endDate"
            ></app-income-report>
          </div>

          <div class="col-4">
            <div class="card pull-up">
              <div class="card-content">
                <div class="card-body">
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h6 class="text-muted">Membresías</h6>
                      <h4 *ngFor="let membership of graphicMembership">
                        {{membership.name}}: {{membership.value | dotsCurrency}}
                      </h4>
                      <h4 class="mt-2">
                        Total: {{totalSales | dotsCurrency}}
                      </h4>
                    </div>
                    <div class="align-self-center">
                      <i class="feather ft-clipboard font-large-2 float-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ubicación, genero y edad
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <app-graphs
            *ngIf="graphicMembership"
            [graphicMembership]="graphicMembership"
            (onActiveUsers)="activeUsers = $event"
          ></app-graphs>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Últimos registros
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row my-2">
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Últimos 5 usuarios registrados</h4>
              </div>
              <div class="card-content collapse show">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tbody>
                      <ng-container *ngFor="let user of usersLatest">
                        <tr>
                          <th
                            scope="row"
                            class="border-top-0 text-capitalize"
                          >{{ user?.name }} {{ user?.firstName | lowercase }} {{ user?.lastName | lowercase }}
                          </th>
                          <td class="border-top-0">{{ user?.createdAt | date : 'd/MM/yyyy HH:mm' }}</td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Últimas 5 transacciones</h4>
              </div>
              <div class="card-content collapse show">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tbody>
                      <ng-container *ngFor="let membership of historyLatest">
                        <tr>
                          <th
                            scope="row"
                            class="border-top-0 text-capitalize"
                          >{{ getLatestUserTransaction(membership.userKey) }}
                          </th>
                          <td class="border-top-0">{{ membership.createdAt | date : 'd/MM/yyyy HH:mm' }}</td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</block-ui>
