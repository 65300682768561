import {Injectable} from '@angular/core';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll() {
    return this.db.colWithIds$('programs', ref => ref
      .orderBy('trash', 'asc'));
  }

  update(programKey: string, program) {
    this.afs.doc(`programs/${programKey}`).update(program);
  }

  add(programKey: string, program) {
    this.afs.doc(`programs/${programKey}`).set(program);
  }
}
