import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { RecipesV2Service } from '../../../../services/recipes-v2.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RecipesCategoriesService } from '../../../../services/recipes-categories.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-recipe-modal',
  templateUrl: './recipe-modal.component.html',
  styleUrls: ['./recipe-modal.component.css']
})
export class RecipeModalComponent implements OnInit {
  recipe: any;
  recipeForm = this.fb.group({
    title: [''],
    calories: [''],
    ingredients: this.fb.array([]),
    instructions: [''],
    categories: [[]],
    imageUrl: ['']
  });
  submitted = false;
  categories = [];

  constructor(private fb: FormBuilder,
              public modal: BsModalRef,
              private _recipesCategories: RecipesCategoriesService,
              private _recipe: RecipesV2Service) {
  }

  async ngOnInit() {
    this.categories = await this._recipesCategories.getAll().pipe(first()).toPromise()

    if (this.recipe) {
      this.recipeForm.patchValue(this.recipe);

      this.recipe.ingredients.forEach(ingredient => {
        this.addIngredient({ name: ingredient });
      });
    }
  }

  get ingredients() {
    return this.recipeForm.get('ingredients') as FormArray;
  }

  async submit() {
    this.submitted = true;

    if (this.recipeForm.invalid) return AlertService.toastError('Por favor, rellena todos los campos');

    this.recipe
      ? await this._recipe.update({
        data: {
          ...this.recipeForm.value,
          ingredients: this.recipeForm.value.ingredients.map(ingredient => ingredient.name),
          imageUrl: this.recipe.imageUrl != this.recipeForm.value.imageUrl
            ? await this._recipe.uploadFileV2(this.recipeForm.value.imageUrl[0], `recipes/${this.recipeForm.value.title}`, this.recipeForm.value.title)
            : this.recipe.imageUrl
        },
        id: this.recipe.key
      })
      : await this._recipe.add({
        data: {
          ...this.recipeForm.value,
          ingredients: this.recipeForm.value.ingredients.map(ingredient => ingredient.name),
          imageUrl: await this._recipe.uploadFileV2(this.recipeForm.value.imageUrl[0], `recipes/${this.recipeForm.value.title}`, this.recipeForm.value.title)
        }
      });

    AlertService.toastSuccess('Receta guardada correctamente');
    this.modal.hide();
  }

  addIngredient(ingredientValue?) {
    const variationGroup = this.fb.group({
      name: ['', Validators.required]
    });

    if (ingredientValue) variationGroup.patchValue(ingredientValue);

    this.ingredients.push(variationGroup);
  }

  deleteIngredient(ingredientIndex: number) {
    this.ingredients.removeAt(ingredientIndex);
  }
}
