<div class="p-2">
  <h4 class="mb-0">Video</h4>

  <form [formGroup]="videoForm" class="mt-2">

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Título</mat-label>
          <input matInput
                 formControlName="title">
          <mat-error *ngIf="formSubmitted && formControls.title.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Descripción</mat-label>
          <textarea rows="4"
                    formControlName="description"
                    matInput></textarea>
          <mat-error *ngIf="formSubmitted && formControls.description.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="legacy" class="w-100">
          <mat-label>Fecha de creación</mat-label>
          <input matInput [matDatepicker]="datepicker" formControlName="createdAt" class="cursor-pointer"
                 (click)="datepicker.open()">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
          <mat-error *ngIf="formSubmitted && formControls.createdAt.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>URL del video</mat-label>
          <input matInput
                 formControlName="videoUrl" (keydown)="handleUrlVideo()">
          <mat-error *ngIf="formSubmitted && formControls.videoUrl.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <label>Categorías
            <div class="btn btn-success btn-sm" (click)="createCategory()">Crear categoría</div>
          </label>
          <ng-select
            formControlName="categories"
            [items]="categories"
            [multiple]="true"
            [searchable]="true"
            [searchFn]="searchFn"
            [compareWith]="compareFn"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items">
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label">{{item.name}}</span>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="d-flex justify-content-between">
                <span>{{item.name}}</span>
              </div>
            </ng-template>

          </ng-select>
          <mat-error *ngIf="formSubmitted && formControls.categories.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="tools$ | async as tools">
        <div class="form-group">
          <label>Instrumentos
            <div class="btn btn-success btn-sm" (click)="createTools()">Crear instrumento</div>
          </label>
          <ng-select formControlName="tools" [items]="tools" [multiple]="true" bindLabel="name"></ng-select>
          <mat-error *ngIf="formSubmitted && formControls.tools.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="memberships$ | async as memberships">
        <div class="form-group">
          <label>Membresías</label>
          <ng-select formControlName="memberships" [items]="memberships" [multiple]="true" bindLabel="name"
                     placeholder="">
          </ng-select>
          <mat-error *ngIf="formSubmitted && formControls.memberships.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <mat-label>Intensidad</mat-label>
          <div>
            <mat-radio-group aria-label="Intesidad" formControlName="difficulty">
              <mat-radio-button value="1">Baja</mat-radio-button>
              <mat-radio-button value="2" style="margin-left: 10px">Media</mat-radio-button>
              <mat-radio-button value="3" style="margin-left: 10px">Alta</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-around">
        <div class="form-group">
          <mat-checkbox formControlName="isCarousel">¿El video es carrusel?</mat-checkbox>
        </div>

        <div>
          <mat-checkbox formControlName="hideTitle">Ocultar título?</mat-checkbox>
        </div>

        <div>
          <mat-checkbox formControlName="hideTime">Ocultar minutos?</mat-checkbox>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="this.videoForm.value.isCarousel">
        <mat-form-field class="w-100">
          <mat-label>Posición</mat-label>
          <input matInput type="number"
                 formControlName="position">
          <mat-error *ngIf="formSubmitted && formControls.position.errors && this.videoForm.value.isCarousel">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
          <mat-label>Color del titulo</mat-label>
          <input formControl [(colorPicker)]="color" cpOutputFormat="hex" [style.background]="color" [value]="color"
                 formControlName="color">
      </div>

      <div class="col-sm-6 d-flex flex-column mt-1">
        <mat-form-field class="w-100">
          <mat-label>Imagen</mat-label>
          <ngx-mat-file-input formControlName="photoUrl"
                              (change)="choosePicture($event)"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formSubmitted && formControls.photoUrl.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center" *ngIf="path">
          <img class="preview-image mt-1"
               [src]="path">
        </div>
      </div>

      <div class="col-sm-6 d-flex flex-column mt-1">
        <mat-form-field class="w-100">
          <mat-label>Imagen para compartir</mat-label>
          <ngx-mat-file-input formControlName="shareImageUrl"
                              (change)="chooseSharePicture($event)"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formSubmitted && formControls.shareImageUrl.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center" *ngIf="sharePath">
          <img class="preview-image mt-1"
               [src]="sharePath">
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary" (click)="submit()">Guardar</button>
    </div>
  </form>
</div>

<video width="0" height="0" [src]="videoForm.value.videoUrl" type="video/mp4"
       (loadedmetadata)="saveDurationVideo($event)"></video>

