import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-add-float-button',
  template: `
    <button class="btn btn-primary btn-float-right add-btn"
            app-tooltip="Agregar"
            (click)="clickEmmiter.emit()">
      <i class="fa fa-plus"></i>
    </button>
  `,
  styleUrls: ['./add-float-button.component.scss']
})
export class AddFloatButtonComponent implements OnInit {
  @Output() clickEmmiter: EventEmitter<void> = new EventEmitter()

  constructor() {
  }

  ngOnInit(): void {
  }

}
