import {Routes} from '@angular/router';
import {PrivateLayoutComponent} from '../layout/private-layout/private-layout.component';
import {VideoListComponent} from './pages/video-list/video-list.component';
import {CategoriesComponent} from './pages/categories/categories.component';
import {MembershipsComponent} from './pages/memberships/memberships.component';
import {UserListComponent} from './pages/user-list/user-list.component';
import {UserDetailsComponent} from './pages/user-details/user-details.component';
import {CouponsComponent} from './pages/coupons/coupons.component';
import {GraphicsComponent} from './pages/graphics/graphics.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {ImageCarouselComponent} from './pages/image-carousel/image-carousel.component';
import {PaymentsComponent} from './pages/payments/payments.component';
import {PaymentDetailComponent} from './modals/payment-detail/payment-detail.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {ToolsComponent} from './pages/tools/tools.component';
import {PaymentHistoryListComponent} from './pages/payment-history-list/payment-history-list.component';
import {ChallengesComponent} from './pages/challenges/challenges.component';
import {ProgramsComponent} from './pages/programs/programs.component';
import {FilesComponent} from './pages/files/files.component';
import {RecipesComponent} from './pages/recipes/recipes.component';
import {SlidesComponent} from './pages/slides/slides.component';
import {CancellationOpinionsComponent} from './pages/cancellation-opinions/cancellation-opinions.component';
import {WorkoutSeriesComponent} from './pages/workout-series/workout-series.component';
import {
  PurchasedMembershipEmailSettingsComponent
} from './pages/purchased-membership-email-settings/purchased-membership-email-settings.component';
import {CalendarComponent} from './pages/calendar/calendar.component';
import {AchievementsComponent} from './pages/achievements/achievements.component';
import { RecipesV2Component } from './pages/recipes-v2/recipes-v2.component';
import { RecipesCategoriesComponent } from './pages/recipes-categories/recipes-categories.component';
import { MealPlansComponent } from './pages/meal-plans/meal-plans.component';
import { ActivitiesComponent } from './pages/activities/activities.component';

const menuItems = [
  {
    title: 'Usuarios',
    page: 'null',
    icon: 'la-user',
    submenu: {
      items: [
        {
          title: 'Usuarios y cuentas',
          page: '/admin/user-list'
        },
        {
          title: 'Historial de pagos',
          page: '/admin/paymentHistory'
        },
        {
          title: 'Interbancarios pendientes',
          page: '/admin/payments'
        },
      ]
    }
  },
  {
    title: 'Video',
    page: 'null',
    icon: 'feather ft-film',
    submenu: {
      items: [
        {
          title: 'Lista de videos',
          page: '/admin/video-list'
        },
        {
          title: 'Lista de categorías',
          page: '/admin/categories'
        },
        {
          title: 'Lista de instrumentos',
          page: '/admin/tools'
        },
        {
          title: 'Categorías mensuales',
          page: '/admin/program-categories'
        }
      ]
    }
  },
  {
    title: 'Programas y otros',
    page: 'null',
    icon: 'fas fa-cubes',
    submenu: {
      items: [
        {
          title: 'Programas',
          page: '/admin/programs'
        },
        {
          title: 'Workout Series',
          page: '/admin/working-out-series'
        },
      ]
    }
  },
  {
    title: 'Imágenes',
    page: 'null',
    icon: 'la-image',
    submenu: {
      items: [
        {
          title: 'Slides de bienvenida',
          page: '/admin/slides'
        },
        {
          title: 'Imágenes de carrusel',
          page: '/admin/imagesCarousel'
        },
      ]
    }
  },
  {
    title: 'Herramientas',
    page: 'null',
    icon: 'la la-wrench',
    submenu: {
      items: [
        {
          title: 'Correos',
          icon: 'feather ft-star',
          page: '/admin/purchased-membership-email-settings'
        },
        {
          title: 'Notificaciones',
          icon: 'feather ft-bell',
          page: '/admin/notifications'
        },
        {
          title: 'Motivos de cancelación',
          icon: 'feather ft-user-x',
          page: '/admin/cancellation-opinions'
        },
        {
          title: 'Archivos / Fotos',
          icon: 'feather ft-file',
          page: '/admin/files'
        },
        {
          title: 'Cupones',
          icon: 'feather ft-tag',
          page: '/admin/coupons'
        },
        {
          title: 'Gráficas',
          icon: 'feather ft-bar-chart',
          page: '/admin/graphics'
        },
        {
          title: 'Configuraciones',
          icon: 'feather ft-settings',
          page: '/admin/settings'
        }
      ]
    }
  },
  {
    title: 'Calendario',
    icon: 'feather ft-calendar',
    page: '/admin/calendar'
  },
  {
    title: 'Recetas',
    icon: 'feather ft-file-text',
    page: '/admin/recipes-v2'
  },
  {
    title: 'Categorías de recetas',
    icon: 'feather ft-file-text',
    page: '/admin/recipes-categories'
  },
  {
    title: 'Planes de alimentación',
    icon: 'feather ft-file-text',
    page: '/admin/meal-plans'
  },
  {
    title: 'Logros',
    icon: 'feather ft-award',
    page: '/admin/achievements'
  },
  {
    title: 'Membresías',
    icon: 'feather ft-star',
    page: '/admin/memberships'
  },
];

export const AdminRoutes: Routes = [
  {
    path: '',
    component: PrivateLayoutComponent,
    data: {menuItems},
    children: [
      {
        path: 'video-list',
        component: VideoListComponent
      },
      {
        path: 'achievements',
        component: AchievementsComponent
      },
      {
        path: 'files',
        component: FilesComponent
      },
      {
        path: 'categories',
        component: CategoriesComponent
      },
      {
        path: 'recipes',
        component: RecipesComponent,
      },
      {
        path: 'recipes-v2',
        component: RecipesV2Component,
      },
      {
        path: 'recipes-categories',
        component: RecipesCategoriesComponent,
      },
      {
        path: 'slides',
        component: SlidesComponent,
      },
      {
        path: 'tools',
        component: ToolsComponent
      },
      {
        path: 'memberships',
        component: MembershipsComponent
      },
      {
        path: 'coupons',
        component: CouponsComponent
      },
      {
        path: 'working-out-series',
        component: WorkoutSeriesComponent
      },
      {
        path: 'program-categories',
        component: ChallengesComponent
      },
      {
        path: 'programs',
        component: ProgramsComponent
      },
      {
        path: 'user-list',
        component: UserListComponent
      },
      {
        path: 'user-details/:userKey',
        component: UserDetailsComponent
      },
      {
        path: 'graphics',
        component: GraphicsComponent
      },
      {
        path: 'imagesCarousel',
        component: ImageCarouselComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'payments',
        component: PaymentsComponent
      },
      {
        path: 'paymentHistory',
        component: PaymentHistoryListComponent
      },
      {
        path: 'cancellation-opinions',
        component: CancellationOpinionsComponent
      },
      {
        path: 'payment-detail/:userKey/:paymentKey',
        component: PaymentDetailComponent
      },
      {
        path: 'purchased-membership-email-settings',
        component: PurchasedMembershipEmailSettingsComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'calendar',
        component: CalendarComponent
      },
      {
        path: 'meal-plans',
        component: MealPlansComponent
      },
      {
        path: 'activities',
        component: ActivitiesComponent
      },
      {
        path: '**',
        redirectTo: 'video-list'
      }
    ]
  },
];
