import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../admin/interfaces/user";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthService} from "../../../shared/template-services/auth.service";
import {AlertService} from "../../../shared/template-services/alert.service";
import {FirebaseError} from "firebase";
import {UserService} from "../../../admin/services/user.service";

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {
  changeEmailForm: FormGroup;
  formSubmitted: boolean = false;
  user: User;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _auth: AuthService,
              private _user: UserService) {
    this.createForm();
  }

  async ngOnInit() {
    await this.changeEmailForm.patchValue({currentEmail: this.user.email});
  }

  get formControls() {
    return this.changeEmailForm.controls;
  }

  createForm(): void {
    this.changeEmailForm = this.formBuilder.group({
      currentEmail: ['', Validators.required],
      newEmail: ['', Validators.required],
      currentPassword: ['', Validators.required],
    });
  }

  async changeEmail() {
    this.formSubmitted = true;
    if (this.changeEmailForm.valid) {
      const userCredential = await this._auth.loginWithPassword(this.changeEmailForm.value.currentEmail, this.changeEmailForm.value.currentPassword)
        .catch((err: FirebaseError) => {
          AlertService.error('Error al insertar su correo electrónico actual', AuthService.getMessageError(err.code));
        });
      if (userCredential == undefined) {
        return;
      }

      if (userCredential) {
        userCredential.user.updateEmail(this.changeEmailForm.value.newEmail).then(() => {
          this._user.update(this._auth.user.uid, {email: this.changeEmailForm.value.newEmail} as User);
          AlertService.success('Se ha cambiado el email con éxito', `Ahora es ${this.changeEmailForm.value.newEmail}`);
          this.modal.hide();
        }).catch((err: FirebaseError) => {
          AlertService.error('Error al insertar su nuevo correo electrónico', AuthService.getMessageError(err.code));
        });
      }
    } else {
      AlertService.error('Favor de llenar todos los campos', '');
    }
  }
}
