import {Component, OnInit} from '@angular/core';
import {Challenge} from '../../interfaces/challenge';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Video} from '../../interfaces/video';
import {Observable} from 'rxjs';
import {VideoService} from '../../services/video.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-challenge-modal',
  templateUrl: './challenge-modal.component.html',
  styleUrls: ['./challenge-modal.component.css']
})
export class ChallengeModalComponent implements OnInit {
  challenge: Challenge;
  challengeForm: FormGroup;
  formSubmitted: boolean = false;
  videos$: Observable<Video[]>;
  video = [];

  constructor(private formBuilder: FormBuilder,
              private _video: VideoService,
              public modal: BsModalRef,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  get formControls() {
    return this.challengeForm.controls;
  }

  createForm() {
    this.challengeForm = this.formBuilder.group({
        name: ['', Validators.required],
        videos: [[], Validators.required],
        position: [''],
        program: ['challenge'],
        trash: [false]
      }
    );
  }

  ngOnInit() {
    this.videos$ = this._video.getAll();
    this._video.getAll().subscribe(data => {
      this.video = data.filter(video => video.categories.some(category => category.id == this.challenge.key));
      this.challengeForm.patchValue({...this.challenge, videos: this.video});
    });
  }

  submit() {
    let categoryTemp: any;
    categoryTemp = _.xorBy(this.video, this.challengeForm.value.videos, 'key');
    for (const categoryTempElement of categoryTemp) {
      categoryTempElement.categories = categoryTempElement.categories.filter(category => category.id != this.challenge.key);
      this._video.set(categoryTempElement.key, categoryTempElement);
    }
    this.challengeForm.value.videos.map(video => {
      if (!video.categories.some(category => category.id == this.challenge.key)) {
        video.categories.push(this._fds.getReference(`/categories/${this.challenge.key}`));
        this._video.set(video.key, video);
      }
    });
    AlertService.toastSuccess('Se guardó el challenge exitosamente');
    this.modal.hide();
  }

  saveCategoriesRefeference() {
    let categoriesReference = [];
    categoriesReference.push(this._fds.getReference(`/categories/${this.challenge.key}`));
    this.challengeForm.patchValue({categories: categoriesReference});
  }
}
