import { Component, OnInit } from '@angular/core';
import { SettingService } from "../../services/setting.service";
import { take } from "rxjs/operators";

@Component({
  selector: 'app-envivo',
  templateUrl: './envivo.component.html',
  styleUrls: ['./envivo.component.css']
})
export class EnvivoComponent implements OnInit {
  vimeoUrlStreaming: any;

  constructor(private _settigs: SettingService) {
  }

  async ngOnInit(): Promise<void> {
    this.vimeoUrlStreaming = (await this._settigs.get().pipe(take(1)).toPromise()).streaming
  }

}
