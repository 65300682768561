import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {BsModalService} from "ngx-bootstrap/modal";
import {VideoService} from "../../services/video.service";
import {AlertService} from "../../../shared/template-services/alert.service";
import {CouponService} from "../../services/coupon.service";
import {Coupon} from "../../interfaces/coupon";
import {CouponModalComponent} from "../../modals/coupon-modal/coupon-modal.component";
import {PeriodType} from "../../../shared/enums/period-type.enum";
import {PeriodOfTime} from "../../../shared/enums/period-of-time.enum";
import {DiscountType} from "../../../shared/enums/discount-type.enum";

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {
  coupons$: Observable<Coupon[]>;
  currentDate: number = (new Date()).getTime();
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron cupones registradas',
    title: 'Lista de cupones'
  };
  day: number = 86400000;
  week: number = 604800000;
  month: number = 2592000000;
  year: number = 31536000000;
  periodType = PeriodType;
  periodOfTime = PeriodOfTime;
  discountType = DiscountType;

  constructor(private _coupon: CouponService,
              private _modal: BsModalService,
              private _video: VideoService) {
  }

  ngOnInit(): void {
    this.coupons$ = this._coupon.getAll();
  }

  openAddCouponModal() {
    this._modal.show(CouponModalComponent, {});
  }

  editCoupon(coupon: Coupon) {
    this._modal.show(CouponModalComponent, {
      initialState: {
        coupon: {...coupon}
      }
    });
  }

  getRangeEnd(periodType: string, quantity: number, lastUpdate: number) {
    let period;
    if (periodType == 'days') period = this.day;
    if (periodType == 'weeks') period = this.week;
    if (periodType == 'months') period = this.month;
    if (periodType == 'ages') period = this.year;
    return (lastUpdate + (quantity * period));
  }

  async deleteCoupon(coupon: Coupon) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar este cupón?', '')) {
      this._coupon.delete(coupon.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }

  async reactivateCoupon(coupon) {
    if (await AlertService.confirm('¿Seguro que quieres reactivar el cupón?', '')) {
      this._coupon.reactivate(coupon.key);
      AlertService.toastSuccess('Se reactivo correctamente');
    }
  }
}

