<div class="p-2">
  <h4 class="mb-0">Challenge</h4>
  <form [formGroup]="challengeForm" class="mt-2">
    <div class="row">
      <div class="col-sm-12">
        <h3>{{this.challenge.name}}</h3>
      </div>
    </div>

    <div class="col-sm-12" *ngIf="videos$ | async as videos">
      <div class="form-group">
        <label>Videos</label>
        <ng-select formControlName="videos" [items]="videos" [multiple]="true" bindLabel="title"></ng-select>
        <mat-error *ngIf="formSubmitted && formControls.categories.errors">
          Es obligatorio
        </mat-error>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary" (click)="submit()">Guardar</button>
    </div>
  </form>
</div>
