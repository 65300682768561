import {
  Component,
  ContentChildren, ElementRef, EventEmitter,
  Input,
  OnInit, Output,
  QueryList, ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../interfaces/data-table-config';
import {DataTableColumnComponent} from '../data-table-column/data-table-column.component';
import {PrintService} from '../../services/print.service';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-datatable',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {
  @ContentChildren(DataTableColumnComponent) columns: QueryList<DataTableColumnComponent>;
  @Input() rows$: Observable<any>;
  @Input() config: DataTableConfig;
  @Input() printSmall: boolean = false;
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('componentID') componentID: ElementRef;
  isLocal: boolean = false;
  search: string;
  isPrinting: boolean = false;

  constructor(private modal: BsModalService) {
    this.isLocal = window.location.hostname == 'localhost';
  }

  ngOnInit(): void {

  }

  selectRow(isClickable: boolean, row) {
    if (isClickable) {
      this.rowSelected.emit(row);
    }
  }

  printList(): void {
    this.isPrinting = true;
    setTimeout(() => {
      PrintService.print(this.componentID.nativeElement);
      this.isPrinting = false;
    }, 100);
  }

  trackByKey(index: number, item: any): number {
    return item.key;
  }

  calculateTotal(items): number {
    return items.reduce((acc, item) => +(item[this.config.propTotal].toString().replaceAll('.', '')) + acc, 0);
  }
}
