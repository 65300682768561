<app-datatable [rows$]="history$"
               [config]="dataTableConfig">

  <app-datatable-column title="Membresía">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Día de adquisición">
    <ng-template let-row #column>
      {{ row.createdAt | date: 'd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      {{ row.isFree ? 'Gratis' : 'Paga'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Precio">
    <ng-template let-row #column>
      {{ row.price ? (row.price | currency) : '-' }}
    </ng-template>
  </app-datatable-column>

</app-datatable>

