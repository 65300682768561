<div (window:resize)="onResize($event)"></div>

<ng-container *ngIf="isLiveStreaming">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!isLiveStreaming">
  <app-navigation></app-navigation>
  <div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidenav-overlay d-none" id="sidenav-overlay" (click)="rightbar($event)"></div>
</ng-container>

<app-footer *ngIf="!isLiveStreaming"></app-footer>
