import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import moment from 'moment';

@Component({
  selector: 'app-active-users-by-transfers',
  templateUrl: './active-users-by-transfers.component.html',
  styleUrls: ['./active-users-by-transfers.component.css']
})
export class ActiveUsersByTransfersComponent implements OnInit, OnChanges {
  @Input() history: any[];
  @Input() stripeChargesCount: number = 0;
  @Input() startDate: any;
  @Input() endDate: any;
  activeUsersByTransfers: number = 0;
  activeUsersByPaypal: number = 0;
  activeUsersByStripe: number = 0;

  constructor() {
  }

  ngOnChanges() {
    this.calculateActiveUsers();
  }

  ngOnInit(): void {
    this.calculateActiveUsers();
  }

  private calculateActiveUsers() {
    const purchasesByRange = this.history
      .filter(item => item.createdAt >= moment(this.startDate).toDate().getTime()
        && item.createdAt <= moment(this.endDate).toDate().getTime());

    this.activeUsersByTransfers = purchasesByRange.filter(item => item.paymentType === PaymentType.INTERBANK).length;
    this.activeUsersByPaypal = purchasesByRange.filter(item => item.paymentType === PaymentType.PAYPAL).length;
    this.activeUsersByStripe = this.stripeChargesCount;
  }
}
