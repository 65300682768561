<app-datatable [rows$]="payments$"
               [config]="dataTableConfig">

  <app-datatable-column title="Cliente">
    <ng-template let-row #column>
      {{ row.user }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Correo">
    <ng-template let-row #column>
      {{ row?.email }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Membresía">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date : 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Método de pago">
    <ng-template let-row #column>
      {{ paymentsType[row.paymentType] }}
    </ng-template>
  </app-datatable-column>

</app-datatable>
