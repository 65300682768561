<div class="p-2">
  <h4 class="mb-0">Programa</h4>

  <form [formGroup]="programForm" (ngSubmit)="submit()" class="mt-2">

    <mat-form-field class="w-100">
      <mat-label>Nombre</mat-label>
      <input matInput
             formControlName="name">
      <mat-error *ngIf="formSubmitted && formControls.name.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Descripción</mat-label>
      <input matInput
             formControlName="text">
      <mat-error *ngIf="formSubmitted && formControls.text.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Texto corto</mat-label>
      <input matInput
             formControlName="shortText">
      <mat-error *ngIf="formSubmitted && formControls.shortText.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" *ngIf="!isEdit">
      <mat-label>Código del programa</mat-label>
      <input matInput
             formControlName="code">
      <mat-error *ngIf="formSubmitted && formControls.code.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Color</mat-label>
      <input matInput
             type="color"
             formControlName="color">
      <mat-error *ngIf="formSubmitted && formControls.color.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <mat-label>Posición</mat-label>
      <input matInput
             formControlName="position"
             type="number">
      <mat-error *ngIf="formSubmitted && formControls.position.errors">
        Es obligatorio
      </mat-error>
    </mat-form-field>

    <div>
      <mat-checkbox formControlName="isActive">Programa activo?</mat-checkbox>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="mt-1">
          <app-upload-file-storage
            [routeFile]="routeFile"
            [showPreview]="true"
            label="Imagen de portada"
            #uploadFileCoverImage
            (imageLoaded)="assignIsThereImage()">
          </app-upload-file-storage>

          <small class="text-danger" *ngIf="formSubmitted && !validateCoverImage()">Campo obligatorio</small>
        </div>

        <img [src]="programForm.value.coverImageUrl" class="w-100 mt-1"
             *ngIf="isEdit && !isThereImage && !!programForm.value.coverImageUrl" alt="Imagen de portada del programa">
      </div>
      <div class="col-6">
        <div class="mt-1">
          <app-upload-file-storage
            [routeFile]="routeFileWeb"
            [showPreview]="true"
            label="Imagen de portada web"
            #uploadFileCoverImageWeb
            (imageLoaded)="assignIsThereImageWeb()">
          </app-upload-file-storage>

          <small class="text-danger" *ngIf="formSubmitted && !validateCoverImageWeb()">Campo obligatorio</small>
        </div>

        <img [src]="programForm.value.webImageUrl" class="w-100 mt-1"
             *ngIf="isEdit && !isThereImageWeb && !!programForm.value.webImageUrl" alt="Imagen de portada del programa">
      </div>
    </div>

    <div class="mt-1">
      <ng-container *ngIf="!!programForm.value.welcomeFile">
        <p>Archivo de bienvenida</p>
        <a [href]="programForm.value.welcomeFile"
           target="_blank">
          <button class="btn btn-primary"
                  type="button">Descargar archivo</button>
        </a>
        <button class="btn btn-info ml-2"
                type="button"
                (click)="handleRemoveWelcomeFile()">Cambiar archivo</button>
      </ng-container>

      <app-upload-file-storage [routeFile]="getProgramRouteFile()"
                               *ngIf="!programForm.value.welcomeFile"
                               label="Archivo de bienvenida"
                               #uploadFileWelcomeFile></app-upload-file-storage>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary">Guardar</button>
    </div>
  </form>
</div>
