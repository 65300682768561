<block-ui>
  <div class="row" *ngIf="!!user">
    <div class="col-12">
      <div class="card-img-top img-fluid bg-cover height-200">
        <img src="assets/images/Logo-_umana_azul-copy.png"
             class="img-fluid rounded background-img"
             alt="timeline image">
      </div>

      <div class="user-data text-center bg-white rounded pb-2 mb-md-2">
        <img src="{{user.photoUrl ? user.photoUrl : 'assets/images/default.png'}}"
             class="img-fluid rounded-circle width-150 profile-image shadow-lg border border-3"
             alt="timeline image">
        <h4 class="mt-1 mb-0">{{user.name}}</h4>
        <p class="m-0">{{user.email}}</p>
      </div>

      <mat-tab-group [(selectedIndex)]="tabIndex">
        <mat-tab label="Resumen">
          <div class="row mt-2">
            <div class="col-12">
              <div class="card shadow-none">
                <div class="card-body">
                  <h5 class="card-title">Información general</h5>
                  <button class="btn btn-warning btn-sm" (click)="editUser()">Editar</button>
                  <button class="btn btn-facebook btn-sm ml-1" (click)="changePassword()">Cambiar contraseña</button>
                  <button class="btn btn-facebook btn-sm ml-1" (click)="changeEmail()">Cambiar email</button>
                  <hr>
                  <div class="mt-2">
                    <h5 class="card-title mb-1">Nombre</h5>
                    <p class="font-small-3">{{user.name}}</p>
                  </div>

                  <div class="mt-2">
                    <h5 class="card-title mb-1">email</h5>
                    <p class="font-small-3">{{user.email}}</p>
                  </div>

                  <div class="mt-2">
                    <h5 class="card-title mb-1">Fecha de nacimiento</h5>
                    <p class="font-small-3">{{user.birthDate | date: 'd/MM/yyyy'}}</p>
                  </div>

                  <div class="mt-2">
                    <h5 class="card-title mb-1">Fecha de ingreso</h5>
                    <p class="font-small-3">{{user.createdAt | date: 'd/MM/yyyy'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Suscripción">
          <app-membership [user]="user"></app-membership>
        </mat-tab>

        <mat-tab label="Historial de membresías">
          <app-payment-history [userKey]="user.key"></app-payment-history>
        </mat-tab>

        <mat-tab label="Pagos pendientes">
          <app-pending-payments [userKey]="user.key"></app-pending-payments>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</block-ui>
