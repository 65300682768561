<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Slide</h4>

    <form [formGroup]="slideForm" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Título</mat-label>
            <input matInput
                   formControlName="title">
            <mat-error *ngIf="formSubmitted && formControls.title.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Texto</mat-label>
            <input matInput
                   formControlName="text">
            <mat-error *ngIf="formSubmitted && formControls.text.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 d-flex flex-column">
          <mat-form-field class="w-100">
            <mat-label>Imagen</mat-label>
            <ngx-mat-file-input formControlName="imageUrl"
                                (change)="choosePicture($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.imageUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div class="d-flex justify-content-center" *ngIf="path">
            <img class="preview-image mt-1"
                 [src]="path">
          </div>
        </div>

        <div class="col-sm-12 d-flex flex-column mt-2">
          <mat-form-field class="w-100">
            <mat-label>Posición</mat-label>
            <input matInput
                   type="number"
                   formControlName="position">
            <mat-error *ngIf="formSubmitted && formControls.position.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary" (click)="submit()">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
