import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseDataV2Service } from '../../shared/template-services/firebase-data-v2.service';

@Injectable({
  providedIn: 'root'
})
export class MealPlanService extends FirebaseDataV2Service {

  constructor(_db: AngularFirestore, _storage: AngularFireStorage) {
    super(_db, _storage, 'meal-plans');
  }

  getAll() {
    return this.colWithIds$({
      queryFn: ref => ref.where('trash', '==', false).orderBy('createdAt', 'desc')
    });
  }
}
