import {Component, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProgramsService} from '../../services/programs.service';
import {Observable} from 'rxjs';
import {ProgramComponent} from '../../modals/program/program.component';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron programas registradas',
    title: 'Lista de programas'
  };
  programs$: Observable<any[]>;
  programs = [];

  constructor(private _program: ProgramsService,
              private _modal: BsModalService) {
  }

  async ngOnInit(): Promise<void> {
    this.programs$ = this._program.getAll();
    this.programs = (await this._program.getAll().pipe(take(1)).toPromise())

    for (const program of this.programs) {
      if(!program.isActive) {
        this._program.update(program.key, {isActive: false})
      }
    }
  }

  ngOnDestroy() {

  }

  openProgramModal(program?) {
    this._modal.show(ProgramComponent, {
      initialState: {
        program,
        isEdit: !!program
      },
      class: "modal-lg"
    });
  }

  handleDelete(program) {
    this._program.update(program.key, {trash: true});
  }

  handleRecovery(program) {
    this._program.update(program.key, {trash: false});
  }
}
