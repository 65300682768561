<div class="card pull-up">
  <div class="card-content">
    <div class="card-body">
      <div class="media d-flex">
        <div class="media-body text-left">
          <h6 class="text-muted">Transferencias</h6>
          <h3>{{ activeUsersByTransfers }}</h3>
        </div>
        <div class="align-self-center">
          <i class="la la-credit-card font-large-2 float-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card pull-up">
  <div class="card-content">
    <div class="card-body">
      <div class="media d-flex">
        <div class="media-body text-left">
          <h6 class="text-muted">Paypal</h6>
          <h3>{{ activeUsersByPaypal }}</h3>
        </div>
        <div class="align-self-center">
          <i class="la la-cc-paypal font-large-2 float-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card pull-up">
  <div class="card-content">
    <div class="card-body">
      <div class="media d-flex">
        <div class="media-body text-left">
          <h6 class="text-muted">Stripe</h6>
          <h3>{{ activeUsersByStripe }}</h3>
        </div>
        <div class="align-self-center">
          <i class="la la-cc-paypal font-large-2 float-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
