<div class="d-inline flex-row flex-nowrap align-items-center cursor-pointer redirect-button"
     [routerLink]="['/admin/payments']">
  <i class="feather ft-arrow-left"></i>
  <span class="subtitle-1 ml-3">Volver a la lista</span>
</div>

<div *ngIf="user$ | async as user; else loading">
  <ng-container *ngIf="user.membership.reference | doc | async as membership">
    <div class="card shadow-sm mt-4 d-flex flex-row" *ngIf="payment$ | async as payment; else loading">

      <div class="order-details shadow-sm">
        <div>
          <div class="row">
            <div class="col d-flex justify-content-center mb-4 flex-column align-items-center">
              <div class="d-flex flex-row">
                <h4 class="mb-0">Cliente: {{user.name}}</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex flex-column justify-content-center">
              <span class="subtitle-2">Nombre del usuario</span>
              <span class="subtitle-1 mb-2 ml-1 ">{{user.name}}</span>
              <span class="subtitle-2">Membresía</span>
              <span class="subtitle-1 mb-2 ml-1 ">{{membership?.name}}</span>
              <span class="subtitle-2">Precio</span>
              <span
                class="subtitle-1 mb-2 ml-1 ">{{membership?.price | currency}}</span>
            </div>
          </div>
        </div>
      </div>

      <!--  PAYMENT DETAILS-->
      <div class="w-100">
        <div class="card-body">
          <div class="row">
            <div class="col-4 d-flex flex-column align-items-center">
              <h4 class="text-center">Ticket</h4>
              <img [src]="payment.ticketURL"
                   *ngIf="payment.message == null && !!payment.ticketURL" class="w-100 mt-2 cursor-pointer"
                   (click)="openModalTicket(payment.ticketURL)">
              <app-not-found *ngIf="!payment.ticketURL" title="No hay ticket adjunto" size="medium"></app-not-found>
            </div>
            <div class="col-8 d-flex flex-column align-items-center justify-content-center">
              <button class="btn btn-primary" (click)="handleAcceptPayment(payment, user, membership)">Aceptar ticket</button>
              <button class="btn btn-danger mt-2" (click)="handleCancelPayment(payment)">Cancelar solicitud</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="w-100 mt-5 mb-5 d-flex justify-content-center ">
    <app-loader></app-loader>
  </div>
</ng-template>


