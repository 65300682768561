import {Injectable} from '@angular/core';
import {FirebaseDataService} from "../../shared/template-services/firebase-data.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {Coupon} from "../interfaces/coupon";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DiscountType} from "../../shared/enums/discount-type.enum";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private db: FirebaseDataService,
              private http: HttpClient,
              private afs: AngularFirestore) {

  }

  getAll(): Observable<Coupon[]> {
    return this.db.colWithIds$<Coupon>('coupons', ref => ref
      .where('trash', '==', false));
  }

  async add(coupon: Coupon): Promise<void> {
    let resp: any = await this.http.post(`${environment.apiBaseURL}/createCoupon`, {
      name: coupon.name,
      amount_off: coupon.type == DiscountType.DIRECT ? coupon.quantityDiscount * 100 : null,
      percent_off: coupon.type == DiscountType.PERCENTAGE ? coupon.quantityDiscount : null,
    }).pipe(take(1)).toPromise()

    coupon['couponId'] = resp.couponId

    this.afs.collection(`coupons`).add(coupon);
  }

  update(couponKey: string, coupon: Coupon): Promise<void> {
    return this.afs.doc(`coupons/${couponKey}`).update(coupon);
  }

  get(couponKey: string): Observable<Coupon> {
    return this.db.doc$<Coupon>(`coupons/${couponKey}`);
  }

  delete(couponKey: string): void {
    this.afs.doc(`coupons/${couponKey}`).update({trash: true});
  }

  reactivate(couponKey: string): void {
    this.afs.doc(`coupons/${couponKey}`).update({lastUpdate: (new Date()).getTime()});
  }
}
