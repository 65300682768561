<div class="p-2">
  <h4 class="mb-0">Configuraciones</h4>

  <form [formGroup]="settingForm" (ngSubmit)="submit()" class="mt-2">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Clabe</mat-label>
          <input matInput
                 formControlName="clabe">
          <mat-error *ngIf="formSubmitted && formControls.clabe.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Número de cuenta</mat-label>
          <input matInput
                 formControlName="accountNumber">
          <mat-error *ngIf="formSubmitted && formControls.accountNumber.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput
                 formControlName="email">
          <mat-error *ngIf="formSubmitted && formControls.email.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <button class="btn btn-primary">Guardar</button>
  </form>
</div>

<div class="p-2">
  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="Workout Series">
      <app-setting-program-form
        *ngIf="settingWorkoutSeries"
        sectionName="workoutSeries"
        [sectionData]="settingWorkoutSeries"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Calendario">
      <app-setting-program-form
        *ngIf="settingCalendar"
        sectionName="calendar"
        [sectionData]="settingCalendar"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Challenges">
      <app-setting-program-form
        *ngIf="settingChallenge"
        sectionName="challenge"
        [sectionData]="settingChallenge"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Wellness">
      <app-setting-program-form
        *ngIf="settingWellness"
        sectionName="wellness"
        [sectionData]="settingWellness"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Videos">
      <app-setting-program-form
        *ngIf="settingVideos"
        sectionName="videos"
        [sectionData]="settingVideos"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Programas">
      <app-setting-program-form
        *ngIf="settingProgram"
        sectionName="program"
        [sectionData]="settingProgram"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Planes de alimentación">
      <app-setting-program-form
        *ngIf="settingMealPlans"
        sectionName="meal-plans"
        [sectionData]="settingMealPlans"></app-setting-program-form>
    </mat-tab>

    <mat-tab label="Rider">
      <app-setting-program-form
        *ngIf="settingRider"
        sectionName="rider"
        [sectionData]="settingRider"></app-setting-program-form>
    </mat-tab>
  </mat-tab-group>
</div>
