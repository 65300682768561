import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Challenge} from '../interfaces/challenge';
import {Observable} from 'rxjs';
import {Category} from '../interfaces/category';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {


  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Category[]> {
    return this.db.colWithIds$<Category>('challengeCategories', ref => ref
      .where('trash', '==', false));
  }

  getMonthChallenge(): Observable<any>{
    return this.db.colWithIds$<any>('monthChallenges', ref => ref
      .where('trash', '==', false))
  }

  add(challenge: Challenge, key: string): void {
    this.db.doc(`categories/${key}-${challenge.program}`).set(challenge);
  }

  addMonthChallenge(month, key) {
    this.db.doc(`monthChallenges/${key}`).set(month)
  }

  deleteMonthChallenge(key: string){
    this.db.doc(`monthChallenges/${key}`).update({trash: true})
  }

  deleteChallenge(key: string){
    return this.db.doc(`categories/${key}`).update({trash: true})
  }

}


