import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../interfaces/user';
import { Membership } from '../../interfaces/membership';
import { take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { HistoryService } from '../../services/history.service';
import { MembershipService } from '../../services/membership.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { PaymentType } from '../../../shared/enums/payment-type.enum';
import { ObjectService } from '../../../shared/template-services/object.service';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.css']
})
export class GraphicsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  users$: Observable<User[]>;
  usersLatest$: Observable<User[]>;
  history$: Observable<Membership[]>;
  historyLatest$: Observable<Membership[]>;
  memberships$: Observable<Membership[]>;
  totalAmount: number = 0;
  topMembership: any = '';
  load: boolean = true;
  users: any[];
  usersLatest: any[];
  history: any[];
  historyLatest: any[];
  memberships: any[];
  gradient: boolean = true;
  graphicMembership;
  view: any[] = [500, 200];
  startDate = moment().startOf('month').toDate();
  previousStartDate = moment().startOf('month').toDate();
  endDate = moment().endOf('month').toDate();
  previousEndDate = moment().endOf('month').toDate();
  filterHistory: any;
  totalTransfers: any = 0;
  filterUsers: any;
  isFilterByDate: boolean = false;
  stripeNet: number = 0;
  stripeChargesCount: number = 0;
  currentDay = moment().format('DD/MM/YYYY');
  isCurrentMonth: boolean = true;
  stripeHistory: any[] = [];
  totalSales: number = 0;
  activeUsers: number = 0;
  panelOpenState = false;

  constructor(private _user: UserService,
    private _history: HistoryService,
    private _membership: MembershipService) {
  }

  async ngOnInit() {
    await this.getUsers();
    await this.getHistory();
    this.load = false;
  }

  async getUsers() {
    this.users$ = this._user.getAll();
    this.users = await this.users$.pipe(take(1)).toPromise();
    this.usersLatest$ = this._user.getLatest();
    this.usersLatest = await this.usersLatest$.pipe(take(1)).toPromise();
  }

  async getHistory() {
    this.blockUI.start('Cargando membresías de stripe...');

    this.history$ = this._history.getAll();
    this.history = await this.history$.pipe(take(1)).toPromise();

    this.historyLatest$ = this._history.getLatest();
    this.historyLatest = await this.historyLatest$.pipe(take(1)).toPromise();

    this.filterHistoryByDate();
    this.filterUserByDate();

    await this.loadStripeHistory();
    await this.getMemberships();

    this.blockUI.stop();
  }

  private async loadStripeHistory() {
    if (!this.startDate || !this.endDate) return;
    if (this.startDate == this.previousStartDate && this.endDate == this.previousEndDate) return;

    this.previousStartDate = this.startDate;
    this.previousEndDate = this.endDate;

    const resp = await this._history.getByStripe(
      momentTz.utc(this.startDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).toDate().getTime().toString().slice(0, -3),
      momentTz.utc(this.endDate).isAfter(moment().endOf('day'))
        ? moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }).utc(true).toDate().getTime().toString().slice(0, -3)
        : (
          momentTz(this.endDate).isSame(moment(), 'day')
            ? momentTz
              .utc(this.endDate)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
              })
              .toDate().getTime().toString().slice(0, -3)
            : momentTz
              .utc(this.endDate)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 99
              })
              .toDate().getTime().toString().slice(0, -3)
        )
    );

    this.stripeNet = +resp.result?.find(resultItem => resultItem?.reporting_category == 'total')?.net;
    this.stripeChargesCount = +resp.result?.find(resultItem => resultItem?.reporting_category == 'charge')?.count;

    this.stripeHistory = (await this._history.getChargesByStripe(
      moment(this.startDate).toDate().getTime().toString().slice(0, -3),
      moment(this.endDate).toDate().getTime().toString().slice(0, -3)
    )).filter(payment => payment.paid);
  }

  async getMemberships() {
    this.memberships$ = this._membership.getAll();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();

    for (let history of this.filterHistory) {
      let membership = this.memberships.some(membership => membership.key == history.key);
      if (!membership) {
        let membership = await this._membership.get(history.key).pipe(take(1)).toPromise();
        this.memberships.push(membership);
      }
    }

    await this.filterGraphicMembershipsByDate();
  }

  getLatestUserTransaction(userKey) {
    let userIndex = this.users.findIndex(user => user.key === userKey);
    return (`${this.users[userIndex].name ?? this.users[userIndex].firstName} ${this.users[userIndex]?.lastName}`).toLowerCase();
  }

  async filterBetweenDate() {
    this.isFilterByDate = true;

    this.isCurrentMonth = moment(this.startDate).format('MM/YYYY') == moment().format('MM/YYYY') || moment(this.endDate).format('MM/YYYY') == moment().format('MM/YYYY');

    this.blockUI.start('Cargando membresías de stripe...');

    await this.loadStripeHistory();

    this.blockUI.stop();

    this.filterHistoryByDate();
    this.filterUserByDate();
    this.filterTotalAmountByDate();
    this.filterGraphicMembershipsByDate();
    this.totalTransfers = this.filterHistory.filter(transfer => transfer.paymentType == PaymentType.PAYPAL || transfer.paymentType == PaymentType.INTERBANK).length;
  }

  filterHistoryByDate() {
    this.filterHistory = this.history.filter(user =>
      user.createdAt >= moment(ObjectService.disablePointer(this.startDate)).zone('utc-7').startOf('day').toDate().getTime()
      && user.createdAt <= moment(ObjectService.disablePointer(this.endDate)).zone('utc-7').endOf('day').toDate().getTime());
  }

  filterUserByDate() {
    this.filterUsers = this.users.filter(user =>
      user.createdAt >= moment(ObjectService.disablePointer(this.startDate)).zone('utc-7').startOf('day').toDate().getTime()
      && user.createdAt <= moment(ObjectService.disablePointer(this.endDate)).zone('utc-7').endOf('day').toDate().getTime());
  }

  filterTotalAmountByDate() {
    let membershipsSells = [];
    this.totalAmount = 0;

    for (let i = 0; i < this.filterHistory.length; i++) {
      if (this.filterHistory[i].price > 0) {
        this.totalAmount = this.totalAmount + this.filterHistory[i].price;
      }

      let membership = membershipsSells.find(membership => membership.name === this.filterHistory[i].name);

      if (!membership) {
        membershipsSells.push({ name: this.filterHistory[i].name, quantity: 1 });
      } else {
        membership.quantity = membership.quantity + 1;
      }
    }

    this.topMembership = _.orderBy(membershipsSells, 'quantity', 'desc')[0];
  }

  filterGraphicMembershipsByDate() {
    this.graphicMembership = null;
    let stripeHistory = [...this.stripeHistory];

    const graphicMembership = this.memberships.map((membershipItem) => {
      const history = this.filterHistory
        .filter(payment => payment.name == membershipItem.name
          && payment.paymentType != PaymentType.STRIPE
          && payment.paymentType != PaymentType.STRIPE_ONCE
          && payment.status == 1 // PAID
        );

      const historyTotal = history.reduce((total, payment) => total + payment.price, 0);

      const stripeResult = stripeHistory.filter(payment => (payment.amount / 100) == membershipItem.price);
      stripeHistory = _.differenceBy(stripeHistory, stripeResult, 'id');

      const totalStripe = stripeResult.reduce((total, payment) => total + (payment.amount / 100), 0);


      return {
        'name': membershipItem.name,
        'value': historyTotal + totalStripe
      };
    }).filter(membership => membership.value > 0);

    this.graphicMembership = graphicMembership;
    this.totalSales = this.graphicMembership.reduce((acc, curr) => acc + curr.value, 0);
  }
}
