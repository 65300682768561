import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {VideoService} from '../../services/video.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Membership} from '../../interfaces/membership';
import {MembershipService} from '../../services/membership.service';
import {MembershipModalComponent} from '../../modals/membership-modal/membership-modal.component';
import {PeriodOfTimeLabel} from '../../../shared/labels/period-of-time.label';
import {PeriodTypeLabel} from '../../../shared/labels/period-type.label';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.css']
})
export class MembershipsComponent implements OnInit {
  memberships$: Observable<Membership[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron membresías registradas',
    title: 'Lista de membresías'
  };
  periodTypeLabel = PeriodTypeLabel;
  periodOfTimeLabel = PeriodOfTimeLabel;

  constructor(private _membership: MembershipService,
              private _modal: BsModalService,
              private _video: VideoService) {
  }

  ngOnInit(): void {
    this.memberships$ = this._membership.getAll();
  }

  openAddMembershipModal() {
    this._modal.show(MembershipModalComponent, {});
  }

  editMembership(membership: Membership) {
    this._modal.show(MembershipModalComponent, {
      initialState: {
        membership: {...membership}
      }
    });
  }

  async deleteCategory(membership: Membership) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar esta categoría?', '')) {
      this._membership.delete(membership.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }
}
