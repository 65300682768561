import { Component, OnInit } from '@angular/core';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {User} from "../../../admin/interfaces/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {UserService} from "../../../admin/services/user.service";
import {MembershipService} from "../../../admin/services/membership.service";
import {AlertService} from "../../../shared/template-services/alert.service";

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  user: User = {key: ''} as User;
  userKey: string;
  userForm: FormGroup;
  formSubmitted: boolean = false;
  update: boolean = false;
  birthDateDate: any;
  birthDateNumber: number;
  picture: any;
  path;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _user: UserService,
              private _membership: MembershipService) {
    this.createForm();
  }

  async ngOnInit() {
    this.path = this.user.photoUrl;
    this.birthDateDate = new Date(this.user.birthDate);
    this.userForm.patchValue(this.user);
    this.birthDateNumber = this.birthDateDate.getTime();
  }

  get formControls() {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      birthDate: ['', Validators.required],
      photoUrl: [''],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;
    if (this.userForm.valid) {
      this.blockui.start('Actualizando cuenta...');

      if (!!this.picture) {
        this.userForm.patchValue({photoUrl: await this._user.uploadPicture(this.picture, this.user.key, 'userPhoto')});
      }

      this.userForm.patchValue({birthDate: this.birthDateNumber});
      await this._user.update(this.user.key, this.userForm.value);
      this.update = true;
      this.blockui.stop();
      AlertService.toastSuccess('Se actualizó correctamente');
      this.modal.hide();
    }
  }

  birthDateChange() {
    this.birthDateNumber = this.birthDateDate.toDate().getTime();
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }
}
