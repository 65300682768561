import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {Membership} from "../interfaces/membership";

@Injectable({
  providedIn: 'root'
})
export class UserMembershipsService {

  constructor(private afs: AngularFirestore) {
  }

  getAllByUser(userKey: string): Observable<Membership[]> {
    return this.afs.collection<Membership>(`users/${userKey}/memberships`, ref => ref
        .where('trash', '==', false)).valueChanges();
  }

  get(userKey: string, membershipKey: string) {
    return this.afs.doc(`users/${userKey}/memberships/${membershipKey}`).valueChanges();
  }

  async add(userKey: any, membership: any): Promise<any> {
    return await this.afs.collection<Membership>(`users/${userKey}/memberships`).add(membership);
  }

  async delete(userKey: string, membershipKey: string): Promise<void> {
    return await this.afs.doc<Membership>(`users/${userKey}/memberships/${membershipKey}`).update({trash: true});
  }

  update(userKey: any, membershipKey: any, membership: any) {
    this.afs.doc(`users/${userKey}/memberships/${membershipKey}`).update(membership);
  }
}
