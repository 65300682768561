import {Component, OnInit} from '@angular/core';
import {MembershipService} from '../../services/membership.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-cancellation-opinion',
  templateUrl: './cancellation-opinion.component.html',
  styleUrls: ['./cancellation-opinion.component.css']
})
export class CancellationOpinionComponent implements OnInit {
  options = [
    'Me cuesta hacer ejercicio en mi casa.',
    'Las clases no son lo que pensaba.',
    'Volveré después.',
    'Otra',
  ];
  optionSelected = null;
  customOption = null;

  constructor(private _membership: MembershipService,
              public modal: BsModalRef,
              private _user: UserService) {
  }

  ngOnInit(): void {
  }

  submit() {
    if (!this.optionSelected) return AlertService.toastError('Favor de seleccionar una opción');
    if (!!this.customOption) this.customOption = this.customOption.trim();
    if (this.optionSelected == 'Otra' && !this.customOption) return AlertService.toastError('Favor de escribir el motivo de tu cancelación');

    this._membership.addCancellationOpinion(this.optionSelected, this.customOption, this._user.user.key);
    this.modal.hide();
  }

}
