<div class="wrapper-list p-4">
  <h3 class="mb-2">Ordenar secciones</h3>

  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let section of sections" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      {{section.name}}
    </div>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <button class="btn btn-primary mr-2"
            (click)="close()">Guardar
    </button>
  </div>
</div>

<div class="backdrop"></div>
