import {Routes} from '@angular/router';
import {AuthComponent} from "./auth.component";
import {LoginComponent} from "./pages/login.component";
import {LogOutComponent} from "./pages/log-out/log-out.component";

export const AuthRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: []
      },
      {
        path: 'logout',
        component: LogOutComponent,
        canActivate: []
      },
      {
        path: '**',
        redirectTo: 'login'
      }
    ]
  }
];
