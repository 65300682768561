<div class="form-group">
  <label>Categorías
  </label>
  <ng-select [items]="categoriesArray" bindLabel="name" [(ngModel)]="categorySelected"
             (ngModelChange)="filterByCategory()"></ng-select>
</div>

<app-datatable [rows$]="videosFiltered$"
               [config]="dataTableConfig">

  <app-datatable-column title="Título">
    <ng-template let-row #column>
      {{ row?.title }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha" [isClickable]="false">
    <ng-template let-row #column>
      <span (click)="changeDate(row.key)">{{row.createdAt | date : 'dd/MM/yyyy'}}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Categorías">
    <ng-template let-row #column>
      <div class=""
           *ngFor="let category of row.categories">{{!!categories[category.id] ? categories[category.id].name : ''}} </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-end">
        <i class="feather ft-edit fa-lg mr-2" (click)="editVideo(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteVideo(row)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddVideoModal()"></app-add-float-button>
