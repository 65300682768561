<div class="row vh-100">
  <div class="col-12 p-2">
    <ng-container *ngIf="!!vimeoUrlStreaming">
      <iframe
        [src]="vimeoUrlStreaming | safe"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="width:100%; height: 100%;"
      ></iframe>
    </ng-container>
  </div>
</div>
