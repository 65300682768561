import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/template-services/auth.service";
import {FirebaseError} from "firebase";
import {AlertService} from "../../../shared/template-services/alert.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {User} from "../../../admin/interfaces/user";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  formSubmitted: boolean = false;
  user: User;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _auth: AuthService) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  get formControls() {
    return this.changePasswordForm.controls;
  }

  createForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordRepeat: ['', Validators.required],
    });
  }

  async changePassword() {
    this.formSubmitted = true;
    if (this.changePasswordForm.value.newPassword != this.changePasswordForm.value.newPasswordRepeat) return AlertService.error('Las contraseñas no coinciden');
    if (this.changePasswordForm.valid) {
      const userCredential = await this._auth.loginWithPassword(this.user.email, this.changePasswordForm.value.currentPassword)
        .catch((err: FirebaseError) => {
          AlertService.error('Error al insertar su contraseña actual', AuthService.getMessageError(err.code));
        });
      if (userCredential == undefined) {
        return;
      }
      if (userCredential) {
        userCredential.user.updatePassword(this.changePasswordForm.value.newPassword).then(() => {
          AlertService.success('Se ha cambiado la contraseña con éxito', '');
          this.modal.hide();
        }).catch((err: FirebaseError) => {
          AlertService.error('Error al insertar su nueva contraseña', AuthService.getMessageError(err.code));
        });
      }
    }
  }
}
