import { Component, OnInit } from '@angular/core';
import {Notification} from "../../interfaces/notification";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AlertService} from "../../../shared/template-services/alert.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.css']
})
export class NotificationModalComponent implements OnInit {
  notification: Notification = {key: ''} as Notification;
  notificationForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  picture: any;
  path;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _notification: NotificationService,) {
    this.createForm();
  }

  ngOnInit(): void {
    if (!!this.notification.message) {
      this.isEdit = true;
      this.notificationForm.patchValue(this.notification);
      this.path = this.notification.image;
    }
  }

  get formControls() {
    return this.notificationForm.controls;
  }

  createForm(): void {
    this.notificationForm = this.formBuilder.group({
      message: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required],
      trash: [false]
    });
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  async submit() {
    this.formSubmitted = true;
    if (this.notificationForm.valid) {
      if (!this.notification.message) this.notification.key = this._notification.createId();
      if (!!this.picture) this.notificationForm.patchValue({image: await this._notification.uploadPicture(this.picture, this.notification.key, 'notificationImage')});

      this._notification.set(this.notification.key, this.notificationForm.value);

      AlertService.toastSuccess(this.isEdit ? 'Se ha actualizado correctamente' : 'Se ha guardado correctamente');
      this.modal.hide();
    }
  }
}
