<block-ui>
  <div class="content-body">
    <section id="simple-user-cards" class="row">
      <div class="col-12 mb-2 d-flex flex-row">
        <h1 class="text-uppercase">Lista de membresías</h1>
        <button class="btn btn-danger btn-sm ml-2"
                *ngIf="hasActiveMembership()"
                (click)="cancelSubscription()">Cancelar membresía
        </button>
        <button class="btn btn-danger btn-sm ml-2"
                *ngIf="hasActiveMembership()"
                (click)="changeCard()">Cambiar tarjeta
        </button>
        <button class="btn btn-primary btn-sm ml-2"
                *ngIf="!!verifyWelcomeFileInMembership(currentMembership)"
                (click)="sendMailPDF(currentMembership)">Enviarme el plan de alimentación
        </button>
      </div>
      <ng-container *ngFor="let membership of memberships; let index = index">
        <ng-container *ngIf="getIfMembershipItsOver(membership)">
          <div class="col-xl-3 col-md-6 col-12">
            <div class="card">
              <div [ngClass]="(membership?.name == currentMembership?.name) ? 'background-color border-style' : ''">
                <div class="card-body d-flex justify-content-center flex-column">
                  <h2>{{membership.name}}</h2>
                  <div class="badge badge-success" *ngIf="membership?.name == currentMembership?.name && user?.membership?.endTime > currentTime">Membresía
                    actual
                  </div>
                  <div class="badge badge-danger" *ngIf="membership?.name == currentMembership?.name && user?.membership?.endTime <= currentTime">Membresía
                    vencida ({{user?.membership?.endTime | date :'dd/MM/yyyy'}})
                  </div>

                  <h1 class="mt-1">{{!membership.isFree ? (membership.price | currency) : 'gratis'}}</h1>
                  <h1 *ngIf="!!membership?.oldPrice"
                      class="strike mt-1">{{!membership.isFree ? (membership.oldPrice | currency) : 'gratis'}}</h1>

                  <ng-container *ngIf="membership.periodOfTime == periodOfTime.RANGE">
                    <h4 class="mt-1">Esta membresía dura del</h4>
                    <h4>{{membership.rangeStart | date : 'd/MM/yyyy'}}
                      al {{membership.rangeEnd | date : 'd/MM/yyyy'}}</h4>
                  </ng-container>

                  <ng-container
                    *ngIf="membership.periodOfTime == periodOfTime.LIMITED && membership?.name == currentMembership?.name">
                    <h4 class="mt-1">{{getMembershipText(membership)}}</h4>
                  </ng-container>

                  <ng-container
                    *ngIf="membership.periodOfTime == periodOfTime.LIMITED && membership?.name != currentMembership?.name">
                    <h4 *ngIf="membership.periodType == periodType.DAILY" class="mt-1">Esta membresía
                      dura {{membership.quantity}} {{membership.quantity == 1 ? 'día' : 'días'}}</h4>
                    <h4 *ngIf="membership.periodType == periodType.WEEKLY" class="mt-1">Esta membresía
                      dura {{membership.quantity}} {{membership.quantity == 1 ? 'semana' : 'semanas'}}</h4>
                    <h4 *ngIf="membership.periodType == periodType.MONTHLY" class="mt-1">Esta membresía
                      dura {{membership.quantity}} {{membership.quantity == 1 ? 'mes' : 'meses'}}</h4>
                    <h4 *ngIf="membership.periodType == periodType.ANNUAL" class="mt-1">Esta membresía
                      dura {{membership.quantity}} {{membership.quantity == 1 ? 'año' : 'años'}}</h4>
                  </ng-container>

                  <ng-container *ngIf="membership.periodOfTime == periodOfTime.FOREVER">
                    <h4 class="mt-1">Esta membresía dura de por vida</h4>
                  </ng-container>

                  <h5 class="mt-1">{{membership.description}}</h5>
                  <div class="text-center">
                    <button
                      *ngIf="(!membership.isFree && user?.membership?.endTime < currentTime) || isNewUser()"
                      class="btn btn-facebook btn-sm mt-2" (click)="applyCoupon(membership, index)">Cupón
                    </button>

                    <button *ngIf="(!membership.isFree && user?.membership?.endTime < currentTime) || isNewUser()"
                            class="btn btn-facebook btn-sm mt-2" (click)="choosePaymentMethod(membership)">Contratar
                    </button>
                    <button *ngIf="membership?.name != currentMembership?.name && membership.isFree"
                            class="btn btn-facebook btn-sm mt-2" (click)="freeMembership(membership)">Contratar
                    </button>
                    <button
                      *ngIf="membership?.name == currentMembership?.name && hasActiveMembership()"
                      class="btn btn-danger btn-sm mt-2"
                      (click)="cancelSubscription()">Cancelar membresía
                    </button>
                    <button
                      *ngIf="membership?.name == currentMembership?.name && hasActiveMembership()"
                      class="btn btn-danger btn-sm mt-2"
                      (click)="changeCard()">Cambiar tarjeta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </section>
  </div>
</block-ui>
