import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {UserService} from '../../admin/services/user.service';
import {AuthService} from '../../shared/template-services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private router: Router,
              private _auth: AuthService,
              private _user: UserService) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();
    if (!this._auth.user) return false;

    const idToken = await this._auth.user.getIdTokenResult(true);
    if (!!idToken.claims['isAdmin']) return this.router.navigateByUrl('admin');

    await this._user.loadUser(this._auth.user.uid);

    return true;
  }

}
