import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { RecipesV2Service } from '../../services/recipes-v2.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../shared/template-services/alert.service';
import { MealPlanComponent } from '../../modals/meal-plan/meal-plan.component';
import { MealPlanService } from '../../services/meal-plan.service';

@Component({
  selector: 'app-meal-plans',
  templateUrl: './meal-plans.component.html',
  styleUrls: ['./meal-plans.component.css']
})
export class MealPlansComponent implements OnInit {
  mealPlans$: Observable<any[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Planes de alimentación'
  };

  constructor(private _mealPlanService: MealPlanService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.mealPlans$ = this._mealPlanService.getAll();
  }

  openAddMealPlanModal() {
    this.modal.show(MealPlanComponent);
  }

  openEditMealPlanModal(mealPlan: any) {
    this.modal.show(MealPlanComponent, {
      initialState: {
        mealPlan
      }
    });
  }

  async handleDelete(mealPlanId: string) {
    if (await AlertService.confirm('¿Está seguro que desea eliminar este elemento?')) {
      await this._mealPlanService.update({
        data: { trash: true },
        id: mealPlanId
      });

      AlertService.toastSuccess('Receta eliminada correctamente');
    }
  }
}
