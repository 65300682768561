<div class="form-group">
    <label>Elegir {{label}}</label>
    <div class="custom-file">
        <input type="file" class="custom-file-input"
               (change)="onFileChange($event)"
               [accept]="accept"
               [ngClass]="{ 'is-invalid': submitted && !isInputValid }"
               [disabled]="disabled">
        <label class="custom-file-label" #labelImport>Elegir {{label}}</label>
        <small class="form-text text-muted danger" *ngIf="!isInputValid && submitted">
            <div>
                {{errorLabel}}
            </div>
        </small>
    </div>
</div>

<img [src]="path" alt="" class="w-100" *ngIf="showPreview && !!path && typeFile == typeFileEnum.IMAGE">
<video width="100%" [src]="path" type="video/mp4" controls
       *ngIf="showPreview && !!path && typeFile == typeFileEnum.VIDEO"></video>
