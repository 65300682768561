<app-datatable [rows$]="recipes$"
               [config]="dataTableConfig"
               (rowSelected)="openEditRecipeModal($event)">

  <app-datatable-column title="Título">
    <ng-template let-row #column>
      <img [src]="row?.imageUrl" style="height: 30px; width: 30px; border-radius: 50%; object-fit: cover">
      {{ row?.title }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date : 'dd/MM/yyyy' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-trash-2 fa-lg" (click)="handleDelete(row.key)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddRecipeModal()"></app-add-float-button>
