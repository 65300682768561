import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SlideService} from '../../services/slide.service';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.css']
})
export class SlideComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  isEdit: boolean;
  slide: any;
  slideForm: FormGroup;
  formSubmitted: boolean = false;
  picture: any;
  file: any;
  path;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _slide: SlideService,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit() {
    if (this.isEdit) {
      this.slideForm.patchValue(this.slide);
      this.path = this.slide.imageUrl;
    } else {
      this.slide = {
        key: new Date().getTime()
      };
    }
  }

  get formControls() {
    return this.slideForm.controls;
  }

  createForm(): void {
    this.slideForm = this.formBuilder.group({
      title: ['', Validators.required],
      text: ['', Validators.required],
      position: ['', Validators.required],
      imageUrl: [''],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.slideForm.valid) {
      this.blockui.start('Guardando...');
      if (!!this.picture) {
        this.slideForm.patchValue({imageUrl: await this._slide.uploadPicture(this.picture, this.slide.key, 'imageUrl')});
      }

      if (this.isEdit) {
        this._slide.update(this.slide.key, this.slideForm.value);
      } else {
        this.slideForm.patchValue({createdAt: new Date().getTime()});
        this._slide.add(this.slideForm.value);
      }

      this.blockui.stop();

      AlertService.toastSuccess('Se guardó exitosamente');
      this.modal.hide();
    }
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader> event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }
}
