import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/internal/operators/take';
import { FirebaseDataService } from 'src/app/shared/template-services/firebase-data.service';
import { environment } from 'src/environments/environment';
import { WorkoutSerie } from '../interfaces/workout-serie';


@Injectable({
  providedIn: 'root'
})
export class WorkoutSerieService {
  workutSeriesKey: string;

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private http: HttpClient) {
  }

  getAll(): Observable<WorkoutSerie[]> {
    return this.db.colWithIds$<WorkoutSerie>(`workoutSeries`, ref => ref
    .where('trash', '==', false));
  }

  set(workutSerie: WorkoutSerie, workoutSerieKey: string) {
    return this.afs.doc(`workoutSeries/${workoutSerieKey}`).set(workutSerie);
  }

  update(workutSerieKey: string, workutSerie: WorkoutSerie) {
    workutSerie['updatedAt'] = new Date().getTime();
    return this.afs.doc(`workoutSeries/${workutSerieKey}`).update(workutSerie);
  }

  delete(workutSerieKey: string) {
    return this.afs.doc(`workoutSeries/${workutSerieKey}`).update({trash: true});
  }

  createSession(amount: number, program, email , couponId): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/createSession-workout-series`, {
      amount: amount * 100,
      program,
      email,
    }).pipe(take(1))
      .toPromise();
  }
}
