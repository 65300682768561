import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotsCurrency'
})
export class DotsCurrencyPipe implements PipeTransform {

  transform(value: number): unknown {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
  }

}
