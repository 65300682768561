import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {CalendarService} from '../../../services/calendar.service';
import {VideoService} from '../../services/video.service';
import {Video} from '../../interfaces/video';
import {Subscription} from 'rxjs';
import {RecipesV2Service} from '../../services/recipes-v2.service';
import {Recipe} from '../../../interfaces/recipe';
import moment from 'moment';
import {DocumentReference} from '@angular/fire/firestore';
import {OrderCalendarSectionsComponent} from '../order-calendar-sections/order-calendar-sections.component';
import _ from 'lodash';
import {Calendar} from '../../../interfaces/calendar';
import {RecipeModalComponent} from './components/recipe-modal/recipe-modal.component';

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.css']
})
export class CalendarDayComponent implements OnInit, OnDestroy {
  submitted = false;
  isEdit = false;
  calendar: Calendar;
  calendarForm = this.fb.group({
    date: [null, Validators.required],
    createdAt: [null],
    dayTip: [''],
    hasFeelInput: [false],
    hasFeelScore: [false],
    images: [[]],
    recipes: [[]],
    videos: [[]],
    sections: [
      [
        {
          name: 'Videos',
          position: 0,
        },
        {
          name: 'Recetas',
          position: 1,
        },
        {
          name: 'Tips',
          position: 2,
        },
        {
          name: 'Como me siento',
          position: 3,
        },
        {
          name: 'Imágenes',
          position: 4,
        }
      ]
    ]
  });
  videos: Video[] = [];
  videoSubscription: Subscription = new Subscription();
  recipes: Recipe[] = [];
  recipeSubscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder,
              private modal: BsModalRef,
              private _calendar: CalendarService,
              private _video: VideoService,
              private _recipe: RecipesV2Service,
              private modalService: BsModalService) {
  }

  async ngOnInit(): Promise<void> {
    if (this.isEdit) {
      this.calendarForm.patchValue({
        ...this.calendar,
        date: moment(this.calendar.date),
        images: []
      });
    }

    this.videoSubscription = this._video.getAll().subscribe((videos: Video[]) => {
      this.videos = videos;

      if (this.isEdit) {
        this.calendarForm.patchValue({
          videos: (this.calendar.videos as DocumentReference[])
            .map((video: DocumentReference) => this.videos.find((v: Video) => v.key === video.id))
        });
      }
    });

    this.recipeSubscription = this._recipe.getAll().subscribe((recipes: Recipe[]) => {
      this.recipes = recipes;

      if (this.isEdit) {
        this.calendarForm.patchValue({
          recipes: (this.calendar.recipes as DocumentReference[])
            .map((recipe: DocumentReference) => this.recipes.find((r: Recipe) => r.key === recipe.id))
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.videoSubscription.unsubscribe();
    this.recipeSubscription.unsubscribe();
  }

  get images() {
    return this.calendarForm.controls['images'] as FormArray;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.calendarForm.invalid) return;

    if (!await AlertService.confirm('¿Está seguro de guardar el calendario?')) return;

    if (this.calendarForm.value.images) {
      this.calendarForm.patchValue({
        images: await this.uploadImages(this.calendarForm.value.images as File[])
      });
    }

    const date = moment(this.calendarForm.value.date)
    date.set({hour: 12, minute: 0, second: 0, millisecond: 0});

    this.calendarForm.patchValue({
      images: [...this.calendar?.images || [], ...this.calendarForm.value.images] as string[],
      videos: this.calendarForm.value.videos.map((video: Video) => this._calendar.getReference(`videos/${video.key}`)),
      recipes: this.calendarForm.value.recipes.map((recipe: Recipe) => this._calendar.getReference(`recipes-v2/${recipe.key}`)),
      date: date.toDate().getTime(),
      createdAt: date.toDate().getTime()
    });

    this.isEdit
      ? await this._calendar.update({
        data: this.calendarForm.value,
        id: this.calendar.key
      })
      : await this._calendar.add({
        data: this.calendarForm.value
      });

    AlertService.toastSuccess('¡Día del calendario guardado correctamente!');
    this.modal.hide();
  }

  async uploadImages(images: File[] = []) {
    return await Promise.all(images.map(async (image: File) => {
      return await this._calendar.uploadFileV2(image, `calendarImages/${Date.now()}`, image.name);
    }));
  }

  onCancel() {
    this.modal.hide();
  }

  async removeImage(i: number) {
    if (await AlertService.confirm('¿Estás seguro de eliminar esta imagen?')) {
      this.calendar.images.splice(i, 1);
    }
  }

  openOrderSectionsModal() {
    let modal: BsModalRef = this.modalService.show(OrderCalendarSectionsComponent, {
      initialState: {
        sections: _.sortBy(this.calendarForm.value.sections, 'position')
      }
    });

    modal.onHide.subscribe(() => {
      this.calendarForm.patchValue({
        sections: modal.content.sections.map((section, i) => ({
          ...section,
          position: i
        }))
      });
    });
  }

  openRecipeModal() {
    this.modalService.show(RecipeModalComponent);
  }
}
