import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {Observable} from 'rxjs';
import {Video} from '../interfaces/video';
import {take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ImageCarousel} from '../interfaces/image-carousel';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private http: HttpClient,) {

  }

  getAll(): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt','desc')
    );
  }

  getAllVideo(): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
        .orderBy('position', 'asc'));
  }

  getAllImage(): Observable<ImageCarousel[]> {
    return this.db.colWithIds$<ImageCarousel>('videos', ref => ref
      .where('trash', '==' ,false)
      .where('isImage', '==', true)
      .orderBy('position', 'asc'))
  }

  add(video: Video): void {
    this.afs.collection(`videos`).add(video);
  }

  set(videoKey: string, video: Video): void {
    this.afs.doc(`videos/${videoKey}`).set(video, {merge: true});
  }

  update(videoKey: string, video: Video): Promise<void> {
    return this.afs.doc(`videos/${videoKey}`).update(video);
  }

  updateImage(imageCarouselKey: string, imageCarousel: ImageCarousel): Promise<void> {
    return this.afs.doc(`videos/${imageCarouselKey}`).update(imageCarousel);
  }

  get(videoKey: string): Observable<Video> {
    return this.db.doc$<Video>(`videos/${videoKey}`);
  }

  delete(videoKey: string): void {
    this.afs.doc(`videos/${videoKey}`).update({trash: true});
  }

  deleteCategory(categoryKey: string){
  }

  async uploadPicture(photo, videoKey: string, namePhoto: string) {
    const uploadRef = this.getStorageRef(videoKey, namePhoto);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey, namePhoto);

    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`videos/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`videos/${id}/${photoName}.jpeg`, data);
  }

  createId(): string {
    return this.afs.createId();
  }

  sendNotification(title: string, photoUrl: string, memberships) {
    return this.http.post(`${environment.apiBaseURL}/sendNotification`, {
      title,
      photoUrl,
      memberships
    }).pipe(take(1)).toPromise();
  }

  createAccount(email: string): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/createAccount`, {
      email
    }).pipe(take(1)).toPromise();
  }

  getReference(videoKey: string): DocumentReference {
    return this.db.doc(`videos/${videoKey}`).ref;
  }
}
