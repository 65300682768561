import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Membership} from '../../interfaces/membership';
import {MembershipService} from '../../services/membership.service';
import {PeriodType} from '../../../shared/enums/period-type.enum';
import {PeriodOfTime} from '../../../shared/enums/period-of-time.enum';
import {Observable} from 'rxjs';
import {Category} from '../../interfaces/category';
import {CategoryService} from '../../services/category.service';
import {take} from 'rxjs/operators';
import {PeriodTypeStripeLabel} from '../../../shared/labels/periodTypeStripe.label';
import {VideoService} from '../../services/video.service';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-memberships-modal',
  templateUrl: './membership-modal.component.html',
  styleUrls: ['./membership-modal.component.css']
})
export class MembershipModalComponent implements OnInit {
  membership: Membership = {key: ''} as Membership;
  membershipForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  rangeStart: any = false;
  rangeEnd: any = false;
  isNotFree: boolean = false;
  periodType = PeriodType;
  periodOfTime = PeriodOfTime;
  categories$: Observable<Category[]>;
  @BlockUI() blockui: NgBlockUI;

  periodOfTimeArray = [
    {value: PeriodOfTime.FOREVER, viewValue: 'De por vida'},
    {value: PeriodOfTime.LIMITED, viewValue: 'Limitado'},
    {value: PeriodOfTime.RANGE, viewValue: 'Rango'}
  ];

  periodTypeArray = [
    {value: PeriodType.DAILY, viewValue: 'Días'},
    {value: PeriodType.WEEKLY, viewValue: 'Semanas'},
    {value: PeriodType.MONTHLY, viewValue: 'Meses'},
    {value: PeriodType.ANNUAL, viewValue: 'Años'}
  ];

  isFreeTypeArray = [
    {value: false, viewValue: 'Paga'},
    {value: true, viewValue: 'Gratis'}
  ];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _membership: MembershipService,
              private _category: CategoryService,
              private _video: VideoService,
              private db: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit(): Promise<void> {
    this.categories$ = this._category.getAll();

    if (!!this.membership.name) {
      let categories = await this.categories$.pipe(take(1)).toPromise();

      this.membership.categories = this.membership.categories.map(categoryRef => {
        return categories.find(category => category.key === categoryRef.id);
      });

      this.membership.categories = this.membership.categories.filter(category => !!category);

      this.isEdit = true;
      this.membershipForm.patchValue(this.membership);
      this.rangeStart = new Date(this.membership.rangeStart);
      this.rangeEnd = new Date(this.membership.rangeEnd);
    }
  }

  get formControls() {
    return this.membershipForm.controls;
  }

  createForm(): void {
    this.membershipForm = this.formBuilder.group({
      name: ['', Validators.required],
      periodOfTime: [null, Validators.required],
      quantity: [''],
      periodType: [''],
      position: [''],
      rangeStart: [''],
      rangeEnd: [''],
      isFree: [null, Validators.required],
      trial: [''],
      description: [''],
      price: [''],
      trash: [false],
      isOncePayment: [false],
      planId: [''],
      productId: [''],
      IsStandOut: [false],
      isActive: [true],
      categories: [[], Validators.required],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.isValidByPeriodTime() && this.membershipForm.valid) {
      this.blockui.start('Guardando...');
      this.saveCategoriesReference();
      if (this.isEdit) {
        this._membership.update(this.membership.key, this.membershipForm.value);
      } else {
        if (this.membershipForm.value.periodOfTime == PeriodOfTime.LIMITED) {
          await this.createPlan();
        }
        const doc: any = await this._membership.add(this.membershipForm.value);
        this.membership['key'] = doc.id;
      }
      let allVideos = await this._video.getAll().pipe(take(1)).toPromise();
      let videos = [];

      for (let category of this.membershipForm.value.categories) {
        videos = [...videos, ...allVideos.filter(video => video.categories.some(categoryItem => categoryItem.id == category.id))];
      }

      for (let video of videos) {
        await this._video.update(video.key, {
          memberships: [...video.memberships, this.db.getReference(`memberships/${this.membership.key}`)]
        } as any);
      }

      this.blockui.stop();

      AlertService.toastSuccess(this.isEdit ? 'Se ha actualizado correctamente' : 'Se ha guardado correctamente');
      this.modal.hide();
    }
  }

  isValidByPeriodTime() {
    if (this.membershipForm.value.periodOfTime == PeriodOfTime.LIMITED && !this.membershipForm.value.periodType && !this.membershipForm.value.quantity) return false;
    if (this.membershipForm.value.periodOfTime == PeriodOfTime.RANGE && (!this.rangeStart || !this.rangeEnd)) return false;
    if (!this.membershipForm.value.isFree && !this.membershipForm.value.price) return false;

    return true;
  }

  dateStartChange() {
    this.membershipForm.patchValue({rangeStart: this.rangeStart.toDate().getTime()});
  }

  dateEndChange() {
    this.membershipForm.patchValue({rangeEnd: this.rangeEnd.toDate().getTime()});
  }

  saveCategoriesReference() {
    let categoriesReference = [];
    for (let category of this.membershipForm.value['categories']) {
      categoriesReference.push(this._category.getReference(category.key));
    }
    this.membershipForm.patchValue({categories: categoriesReference});
  }

  async createCategory() {
    const category: string = await AlertService.input('Añadir categoría');
    if (!!category) {
      let categories = await this._category.getAll().pipe(take(1)).toPromise();
      this._category.add({name: category, trash: false, position: categories.length + 1});
      AlertService.toastSuccess(`Se ha creado "${category}" correctamente`);
    }
  }

  private async createPlan() {
    const resp: any = await this._membership.createPlan(this.membershipForm.value.name, this.membershipForm.value.price, PeriodTypeStripeLabel[this.membershipForm.value.periodType], this.membershipForm.value.quantity);
    this.membershipForm.patchValue({
      planId: resp.planId,
      productId: resp.productId
    });
  }
}
