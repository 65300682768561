import { Component, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../shared/template-services/alert.service';
import { User } from '../../interfaces/user';
import { UserModalComponent } from '../../modals/user-modal/user-modal.component';
import { ExportToCsv } from 'export-to-csv';
import { DatePipe } from '@angular/common';
import { MembershipService } from '../../services/membership.service';
import { take } from 'rxjs/operators';
import { UserMembershipsService } from '../../services/user-memberships.service';
import { Membership } from '../../interfaces/membership';
import { AddUserMassiveComponent } from '../../modals/add-user-massive/add-user-massive.component';
import { Status } from '../../../shared/enums/status.enum';
import { ObjectService } from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  usersFiltered$: Observable<User[]> = new Observable<User[]>();
  filteredUsers: User[] = [];
  memberships$: Observable<Membership[]>;
  users: any[];
  memberships: any[];
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron usuarios registrados',
    title: 'Lista de usuarios'
  };
  selectMultipleArray: any = [];
  selectedMultipleArray: any = [];
  isActive: boolean = true;
  isAboutToExpire: boolean = false;
  week: number = 604800000;
  currentDate: number = (new Date()).getTime();
  typeStatus = Status;
  usersSubscription: Subscription = new Subscription();
  membershipsIndexed: any = {};

  constructor(private _user: UserService,
              private datePipe: DatePipe,
              private _membership: MembershipService,
              private _userMemberships: UserMembershipsService,
              private modal: BsModalService) {
  }

  async ngOnInit() {
    await this.getMemberships();
    this.loadUsers();
  }

  private loadUsers() {
    this.usersSubscription = this._user.getAll().subscribe(users => {
      this.users = users;
      this.getUsersFilter();
    });
  }

  async getUsersFilter() {
    this.filteredUsers = this.users
      .filter(user => !this.selectedMultipleArray.length
        || (!!user.membership
          && this.selectedMultipleArray
            .some(membership => membership.key == user.membership.reference.id)))
      .filter(user => this.getIfMembershipIsActive(user?.membership).isActive == this.isActive
        && this.getIfMembershipIsActive(user?.membership).isAboutToExpire == this.isAboutToExpire
      );

    this.usersFiltered$ = of(this.filteredUsers);
  }

  getIfMembershipIsActive(membership) {
    let validators = { isActive: false, isAboutToExpire: false };
    if (!membership) return validators;

    if (membership.endTime > this.currentDate && membership?.reference?.id != 'y1pI4abvw2QWZGyM7v0F') {
      validators.isActive = true;
    }

    if ((membership.endTime - this.currentDate) < this.week && (membership.endTime - this.currentDate) > 0) {
      validators.isAboutToExpire = true;
    }

    return validators;
  }

  async getMemberships() {
    this.memberships$ = this._membership.getAllWithTrash();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();
    this.membershipsIndexed = ObjectService.indexArray(this.memberships, 'key');
    this.selectMultipleArray = this.memberships;
  }

  editUser(user: User) {
    this.modal.show(UserModalComponent, {
      initialState: {
        user: { ...user }
      }
    });
  }

  async deleteUser(user: User) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar este usuario?', '')) {
      this._user.delete(user.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }

  openAddUserModal() {
    this.modal.show(UserModalComponent);
  }

  async exportToCSV() {
    let memberships = await this._membership.getAllWithTrash().pipe(take(1)).toPromise();

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: `Lista de usuarios actual ${this.datePipe.transform(new Date(), 'dd/MM/yyy')}`,
      title: 'Usuarios',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Nombre',
        'Estatus',
        'Fecha de nacimiento',
        'Membresía',
        'Correo electrónico'
      ]
    };

    let report = [];
    for (let user of this.filteredUsers) {

      const membershipFind = memberships.find(membership => !!user.membership && membership.key == user.membership.reference.id);

      report.push({
        name: !!user.name ? user.name : '',
        status: !!user.membership
          ? user.membership.status == this.typeStatus.PENDING
            ? 'PENDIENTE' : 'PAGADO'
          : 'SIN MEMBRESÍA',
        birthDate: !!user.birthDate ? this.datePipe.transform(user.birthDate, 'dd/MM/yyy') : '',
        membership: !!membershipFind ? membershipFind.name : '',
        email: user.email
      });
    }

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(report);
  }

  openAddUsersModal() {
    const modalRef = this.modal.show(AddUserMassiveComponent, {
      class: 'modal-lg'
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
    });
  }

  async restartPassword(user: User) {
    let uid = user.key;
    let userEmail = user.email.toLowerCase();
    if (await AlertService.confirm('¿Seguro de que quieres cambiar la contraseña?', '')) {
      this._user.resetPassword(uid, userEmail);
      AlertService.toastSuccess('Se ha cambiado correctamente');
    }
  }

  async handleChangeStripeID({ key }: User) {
    const resp = await AlertService.input('Cambiar ID de Stripe', 'Ingresa el nuevo ID de Stripe');

    if (resp) {
      if (await AlertService.confirm('¿Seguro de que quieres cambiar el ID de Stripe?', '')) {
        this._user.update(key, { subscription: resp } as User);
        AlertService.toastSuccess('Se ha cambiado correctamente');
      }
    }
  }

  handleChangeActiveFilter($event: any) {
    if (!this.isActive) this.isAboutToExpire = false;
  }
}
