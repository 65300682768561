<div class="row mt-2">
  <div class="col-12">
    <div class="card shadow-none">
      <div class="card-body">
        <h5 class="card-title">Información general</h5>
        <hr>

        <div class="mt-2">
          <h5 class="card-title mb-1">Nombre</h5>
          <p class="font-small-3">{{memberships?.name}}</p>
        </div>

        <div class="mt-2">
          <h5 class="card-title mb-1">Tipo</h5>
          <p class="font-small-3">{{memberships?.isFree ? 'Gratis' : 'Paga'}}</p>
        </div>

        <div class="mt-2">
          <h5 class="card-title mb-1">Precio</h5>
          <p class="font-small-3">{{memberships?.price ? (memberships?.price | currency) : '-'}}</p>
        </div>

        <div class="mt-2">
          <h5 class="card-title mb-1">Fecha de inicio</h5>
          <p class="font-small-3">{{user?.membership?.startTime | date: 'd/MM/yyyy HH:mm'}}</p>
        </div>

        <div class="mt-2">
          <h5 class="card-title mb-1">Fecha de finalización</h5>
          <p class="font-small-3">{{user.membership.endTime | date: 'd/MM/yyyy HH:mm'}}
            <ng-container *ngIf="user?.membership?.endTime > currentDate" class="ml-2">
              <button class="btn btn-success btn-sm">Activo</button>
            </ng-container>
            <ng-container *ngIf="user?.membership?.endTime < currentDate" class="ml-2">
              <button class="btn btn-danger btn-sm">Inactivo</button>
            </ng-container>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
