import {Component, OnInit} from '@angular/core';
import {ChallengeService} from '../../services/challenge.service';
import {Challenge} from '../../interfaces/challenge';
import {AlertService} from '../../../shared/template-services/alert.service';
import * as _ from 'lodash';
import {isNaN, parseInt} from 'lodash';
import {Observable, of, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ChallengeModalComponent} from '../../modals/challenge-modal/challenge-modal.component';
import {CategoryService} from '../../services/category.service';
import moment from 'moment';
import {ProgramsService} from '../../services/programs.service';
import {ObjectService} from '../../../shared/template-services/object.service';
import {Category} from '../../interfaces/category';

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.css']
})
export class ChallengesComponent implements OnInit {
  challenges$: Observable<Challenge[]>;
  challengesFiltered$: Observable<Challenge[]>;
  monthArray = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'];
  monthSelected: any;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron challenge registradas',
    title: 'Lista de challenge'
  };
  challengeSubscription: Subscription = new Subscription();
  monthChallengeSubscription: Subscription = new Subscription();
  monthChallenge: any;
  videos: any = [];
  isShow: boolean = false;
  allChallenge;
  programs = [];
  programsIndexed = {};
  programSelected = null;

  constructor(private _challenge: ChallengeService,
              private _modal: BsModalService,
              private _category: CategoryService,
              private _program: ProgramsService) {
  }

  async ngOnInit() {
    this.programs = (await this._program.getAll().pipe(take(1)).toPromise())
      .filter((program: any) => !program.trash);

    if (!this.programs[0]) return;

    this.programsIndexed = ObjectService.indexArray(this.programs, 'code')
    this.programSelected = this.programs[0];

    this.loadCategories();
  }

  ngOnDestroy() {
    this.challengeSubscription.unsubscribe();
  }

  filterByMonth() {
    this.challengesFiltered$ = this.challenges$.pipe(map(challenge => challenge.filter(challenge => {
      return challenge.name.includes(this.monthSelected.name);
    })));
  }

  async createdCategory() {
    let monthSelect = await AlertService.select(this.monthArray, 'Meses', 'Seleccione el mes');
    monthSelect = parseInt(monthSelect);
    if (!isNaN(monthSelect)) {
      const date = new Date();
      let year = date.getFullYear();

      let monthDays = new Date(year, monthSelect + 1, 0).getDate();

      let weekenDay = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

      for (let day = 1; day <= monthDays; day++) {
        let index = new Date(year, monthSelect, day).getDay();
        if (weekenDay[index] != undefined) {
          let categoryName = `${weekenDay[index]} ${day} - ${this.monthArray[monthSelect]} - ${year}`;
          let challenge = {
            name: categoryName,
            program: this.programSelected.code.toLowerCase(),
            position: 0,
            trash: false,
            date: moment(`${year}/${monthSelect}/${day}`).toDate().getTime(),
          };
          let nameChallenge = `${this.monthArray[monthSelect]} - ${year}`;
          let monthChallenge = {
            name: nameChallenge,
            trash: false
          };
          this._challenge.add(challenge, categoryName);
          this._challenge.addMonthChallenge(monthChallenge, nameChallenge);
        }
      }
    }
  }

  editChallenge(challenge: Challenge) {
    this._modal.show(ChallengeModalComponent, {
      initialState: {
        challenge: {...challenge}
      },
      class: 'modal-lg'
    });
  }

  openMonth() {
    this.isShow = !this.isShow;
  }

  async deleteMonthChallenge(key: string) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este mes?', 'Se eliminaran todas las categorías asociadas a este mes')) {
      this.allChallenge = this.allChallenge.filter(video => video.program == this.programSelected.code.toLowerCase());
      this.allChallenge.forEach(month => {
        let x = month.key.toLowerCase().includes(key.toLowerCase());
        if (x) {
          this._challenge.deleteChallenge(month.key);
        }
        this._challenge.deleteMonthChallenge(key);
      });

      this.isShow = false;
    }
  }

  async deleteChallengeDay(key) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este día?')) {
      await this._challenge.deleteChallenge(key);
      this.loadCategories()
    }
  }

  handleChangeProgram() {
    this.loadCategories()
  }

  private loadCategories() {
    this.challengeSubscription = this._category.getAll().pipe(take(1)).subscribe(data => {
      this.videos = _.orderBy(data.filter(video => video.program == this.programSelected.code.toLowerCase()), 'date', 'asc');
      this.challenges$ = of(this.videos);
      this.allChallenge = data;

      this.challengesFiltered$ = this.challenges$;
    });
    this.monthChallengeSubscription = this._challenge.getMonthChallenge().subscribe(data => {
      this.monthChallenge = data;
    });
  }
}
