import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {ObjectService} from '../../template-services/object.service';

@Component({
  selector: 'app-fast-search',
  templateUrl: './fast-search.component.html',
  styleUrls: ['./fast-search.component.scss']
})
export class FastSearchComponent implements OnInit, OnChanges {
  @Input() array: any[] = [];
  @Input() propertyToSearch: string = '';
  @Input() isNichePrice: boolean = false;
  @Input() canCreate: boolean = false;
  @Input() isFilter: boolean = false;
  @Input() cssClass: string;
  @Input() placeholder: string;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  showArray: boolean = false;
  wordToSearch: string = '';
  arrayFiltered: any[] = [];

  constructor() {
  }

  ngOnInit() {
    this.arrayFiltered = ObjectService.disablePointer(this.array);
  }

  ngOnChanges(): void {
    this.arrayFiltered = ObjectService.disablePointer(this.array);
  }

  searchWord(): void {
    if (this.wordToSearch == '' && this.isFilter) {
      this.emmitSelected('');
      return;
    }
    this.arrayFiltered = _.filter(this.array, (item) =>
      item[this.propertyToSearch].toLowerCase().indexOf(this.wordToSearch.toLocaleLowerCase()) >= 0);
  }

  showArrayList(): void {
    this.showArray = true;
  }

  hideArrayList(): void {
    setTimeout(() => {
      this.showArray = false;
    }, 200);
  }

  emmitSelected(item: any): void {
    this.wordToSearch = item[this.propertyToSearch];
    this.itemSelected.emit(item);
  }
}
