<div class="row">
  <div class="col-4 form-group">
    <label>Seleccione el programa</label>
    <ng-select [items]="programs"
               bindLabel="name"
               [(ngModel)]="programSelected"
               (ngModelChange)="handleChangeProgram()">
    </ng-select>
  </div>

  <div class="form-group col-4">
    <label>Meses</label>
    <ng-select [items]="monthChallenge"
               bindLabel="name"
               [(ngModel)]="monthSelected"
               (ngModelChange)="filterByMonth()"></ng-select>
  </div>

  <div class="col-4">
    <button class="btn btn-primary" (click)="createdCategory()">Crear categorias</button>
    <button class="btn btn-primary mt-1" (click)="openMonth()">Ver los meses creados</button>
  </div>
</div>

<div *ngIf="isShow" class="box-month">
  <div class="d-flex justify-content-between">
    <h2>Meses</h2>
    <i class="feather ft-x fa-lg mr-1 cursor-pointer" (click)="openMonth()"></i>
  </div>
  <div class="box-item d-flex align-items-center justify-content-between" *ngFor="let month of monthChallenge">
    <span class="ml-1">{{month.name}}</span>
    <i class="feather ft-trash-2 fa-lg mr-1 cursor-pointer" (click)="deleteMonthChallenge(month.key)"></i>
  </div>
</div>

<app-datatable [rows$]="challengesFiltered$"
               [config]="dataTableConfig">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Programa">
    <ng-template let-row #column>
      {{ programsIndexed[row.program]?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-end">
        <i class="feather ft-edit fa-lg mr-2" (click)="editChallenge(row)"></i>
        <i class="feather ft-trash-2 fa-lg cursor-pointer" (click)="deleteChallengeDay(row.key)"></i>
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable>
