import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {CategoryService} from '../../services/category.service';
import {Observable, Subscription} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Category} from '../../interfaces/category';
import {CategoryModalComponent} from '../../modals/category-modal/category-modal.component';
import {VideoService} from '../../services/video.service';
import {Video} from '../../interfaces/video';

@Component({
  selector: 'app-categories',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
  tools$: Observable<any[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron instrumentos registrados',
    title: 'Lista de isntrumentos'
  };

  constructor(private _category: CategoryService) {
  }

  async ngOnInit(): Promise<void> {
    this.tools$ = this._category.getAllTools();
  }

  async editTool(tool: any) {
    let toolName = await AlertService.input('Editar instrumento', tool.name);
    if (!toolName) return AlertService.toastError('Es necesario agregar un nombre');

    this._category.updateTool(tool.key, {name: toolName});
    AlertService.toastSuccess('Se ha editado con éxito');
  }

  async openAddTool() {
    let toolName = await AlertService.input('Agregar nuevo instrumento');
    if (!toolName) return AlertService.toastError('Es necesario agregar un nombre');

    this._category.addTool({name: toolName, trash:false});
    AlertService.toastSuccess('Se ha agregado con éxito');
  }

  async deleteTool(tool: any) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar este instrumento?', '')) {
      this._category.deleteTool(tool.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }
}

