<app-datatable [rows$]="coupons$"
               [config]="dataTableConfig">

    <app-datatable-column title="Nombre">
        <ng-template let-row #column>
            {{ row.name }}
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Cantidad">
        <ng-template let-row #column>
            {{ row.available }}
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Descuento">
        <ng-template let-row #column>
            {{ row.type == discountType.PERCENTAGE ? row.quantityDiscount+'%' : row.quantityDiscount | currency }}
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de vencimiento">
        <ng-template let-row #column>
            <ng-container *ngIf="row.periodOfTime != periodOfTime.FOREVER">{{ row.endTime | date: 'd/MM/yyyy'}}
                <ng-container *ngIf="row.endTime > currentDate" class="ml-1"><button class="btn btn-success btn-sm">Activo</button></ng-container>
                <ng-container *ngIf="row.endTime < currentDate" class="ml-1"><button class="btn btn-danger btn-sm">Inactivo</button></ng-container>
            </ng-container>
            <ng-container *ngIf="row.periodOfTime == periodOfTime.FOREVER">{{'De por vida'}}
                <ng-container class="ml-1"><button class="btn btn-success btn-sm">Activo</button></ng-container>
            </ng-container>
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="" [isClickable]="false">
        <ng-template let-row #column>
            <div class="d-flex flex-row w-100 justify-content-around">
                <i class="feather ft-edit fa-lg" (click)="editCoupon(row)"></i>
                <i class="feather ft-trash-2 fa-lg" (click)="deleteCoupon(row)"></i>
            </div>
        </ng-template>
    </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddCouponModal()"></app-add-float-button>
