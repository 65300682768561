import {Component, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {ImageCarousel} from '../../interfaces/image-carousel';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ImageCarouselModalComponent} from '../../modals/image-carousel-modal/image-carousel-modal.component';
import {AlertService} from '../../../shared/template-services/alert.service';
import {VideoService} from '../../services/video.service';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {
  imagesCarousel$: Observable<ImageCarousel[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron imagenes registradas',
    title: 'Lista de imagenes del carrusel'
  };
  categoriesSubscription: Subscription = new Subscription();
  imagesCarousel: ImageCarousel[];

  constructor(private _imageCarousel: VideoService,
              private _modal: BsModalService) {
  }

  ngOnInit(): void {
    this.categoriesSubscription = this._imageCarousel.getAllImage().subscribe(data => {
      this.imagesCarousel = data;
      this.imagesCarousel$ = of(this.imagesCarousel);
    });
  }

  openImageCarouselModal() {
    this._modal.show(ImageCarouselModalComponent, {
      initialState: {
        lastPosition: this.imagesCarousel.length
      }
    });
  }

  editImageCarousel(imageCarousel: ImageCarousel) {
    this._modal.show(ImageCarouselModalComponent, {
      initialState: {
        imageCarousel: {...imageCarousel}
      }
    });
  }

  async deleteImageCarousel(imageCarousel: ImageCarousel, position: number) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar esta imagen?', '')) {
      this._imageCarousel.delete(imageCarousel.key);

      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }
}
