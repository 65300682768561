import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SettingService} from 'src/app/admin/services/setting.service';
import {VideoService} from 'src/app/admin/services/video.service';
import {AlertService} from '../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-setting-program-form',
  templateUrl: './setting-sections-form.component.html',
  styleUrls: ['./setting-sections-form.component.css']
})
export class SettingSectionsFormComponent implements OnInit {
  formSubmitted: boolean = false;
  settingSectionsForm: FormGroup = this.formBuilder.group({
    title: [''],
    text: [''],
    titleColor: [''],
    imageUrl: [''],
    imageUrlWeb: [''],
    bubbleColor: ['']
  });
  pathSection: any;
  pathSectionWeb: any;
  picture;
  pictureWeb;
  @Input() sectionName: any = '';
  @Input() sectionData;

  constructor(private formBuilder: FormBuilder,
              private _setting: SettingService,
              private _video: VideoService) {

  }

  ngOnInit(): void {
    if (this.sectionData) {
      this.settingSectionsForm.patchValue(this.sectionData);
      this.pathSection = this.sectionData.imageUrl;
      this.pathSectionWeb = this.sectionData.imageUrlWeb;
    }
  }

  get formControls() {
    return this.settingSectionsForm.controls;
  }

  choosePicture(event) {
    this.picture = event.target.files[0];

    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.pathSection = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  choosePictureWeb(event) {
    this.pictureWeb = event.target.files[0];

    if (event.target.files && this.pictureWeb) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.pathSectionWeb = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.pictureWeb);
    }
  }

  async submit() {
    this.formSubmitted = true;

    if (this.settingSectionsForm.valid) {
      if (!!this.picture) {
        this.settingSectionsForm.patchValue({imageUrl: await this._video.uploadPicture(this.picture, '', this.sectionName)});
      }
      if (!!this.pictureWeb) {
        this.settingSectionsForm.patchValue({imageUrlWeb: await this._video.uploadPicture(this.pictureWeb, '', this.sectionName + 'Web')});
      }

      this._setting.updateSection(this.sectionName, this.settingSectionsForm.value);
      AlertService.toastSuccess('Se ha actualizado correctamente');
    }
  }

}
