import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {User} from "firebase";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from '../../admin/services/user.service';
import {AuthService} from '../../shared/template-services/auth.service';
import {AlertService} from '../../shared/template-services/alert.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  user: User;
  loginForm: FormGroup
  formSubmitted: boolean = false;

  constructor(private _auth: AuthService,
              private _user: UserService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.createForm();
  }

  get formControls() {
    return this.loginForm.controls;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    })
  }

  async submit(): Promise<void> {
    this.formSubmitted = true;

    if (this.loginForm.valid) {
      const user = await this._auth.loginWithPassword(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .catch(err => {
          AlertService.error(AuthService.getMessageError(err.code))
        })

      if (!user) return;

      this.router.navigateByUrl('user');
    }
  }
}
