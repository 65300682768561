<app-datatable [rows$]="programs$"
               [config]="dataTableConfig">

  <app-datatable-column title="Programa">
    <ng-template let-row #column>
      <div class="badge badge-success" *ngIf="!row.trash">Activo</div>
      <div class="badge badge-danger" *ngIf="row.trash">Inactivo</div> {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Código">
    <ng-template let-row #column>
      {{ row.code }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Posición">
    <ng-template let-row #column>
      {{ row.position }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-edit fa-lg" (click)="openProgramModal(row)"></i>
        <i class="feather ft-trash-2 fa-lg" *ngIf="!row.trash" (click)="handleDelete(row)"></i>
        <i class="feather ft-corner-down-left fa-lg" *ngIf="row.trash" (click)="handleRecovery(row)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openProgramModal()"></app-add-float-button>
