import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryFn} from '@angular/fire/firestore/interfaces';
import {AngularFireStorage} from '@angular/fire/storage';

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

export interface ColWithIds<T> {
  ref?: CollectionPredicate<T>,
  queryFn?: QueryFn
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataV2Service {

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage,
              private collectionPath?: string) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>({ref, queryFn}: ColWithIds<T>): Observable<T[]> {
    return this.col(this.collectionPath || ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) {
          return data;
        }
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  createID() {
    return this.db.createId();
  }

  async uploadFile(path: string, file: File) {
    const snapshot = await this.storage.upload(path, file);
    return await snapshot.ref.getDownloadURL();
  }

  async uploadFileV2(file, path: string, fileName: string) {
    const uploadRef = this.getStorageRefFile(path, fileName);
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, path, fileName);

    return url;
  }

  private getStorageRefFile(path: string, fileName: string) {
    return this.storage.ref(`${path}/${fileName}`);
  }

  private uploadFileStorage(data, path: string, fileName: string) {
    return this.storage.upload(`${path}/${fileName}`, data);
  }

  update({path, id, data}: { path?: string, id: string, data: any }) {
    return this.db.doc(`${this.collectionPath || path}/${id}`).update({
      ...data,
      updatedAt: new Date()
    });
  }

  add({path, data}: { path?: string, data: any }) {
    return this.db.collection(this.collectionPath || path).add({
      trash: false,
      createdAt: Date.now(),
      ...data
    });
  }

  delete({path, id}: { path?: string, id: string }) {
    return this.update({path, id, data: {trash: true}});
  }
}
