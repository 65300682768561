import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Membership } from '../interfaces/membership';
import { first, map } from 'rxjs/operators';
import { Status } from '../../shared/enums/status.enum';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private afs: AngularFirestore,
    private db: FirebaseDataService,
    private http: HttpClient) {
  }

  getAll(): Observable<Membership[]> {
    return this.afs.collectionGroup<Membership>(`history`).valueChanges();
  }

  getLatest(): Observable<Membership[]> {
    return this.afs.collectionGroup<Membership>(`history`, ref => ref
      .orderBy('createdAt', 'desc').limit(5)).snapshotChanges().pipe(map(actions => actions.map(a => {
      return {
        ...a.payload.doc.data(),
        key: a.payload.doc.id,
        userKey: a.payload.doc.ref.parent.parent.id
      };
    })));
  }

  getAllWithId(): Observable<Membership[]> {
    return this.afs.collectionGroup<Membership>(`videoHistory`, ref => ref.limit(100))
      .snapshotChanges()
      .pipe(
        map(actions => actions
          .map(a => {
            return {
              ...a.payload.doc.data(),
              key: a.payload.doc.id,
              userKey: a.payload.doc.ref.parent.parent.id
            };
          })
        )
      );
  }

  getAllByUser(userKey: string): Observable<Membership[]> {
    return this.afs.collection<Membership>(`users/${userKey}/history`, ref => ref
      .where('trash', '==', false)).valueChanges();
  }

  get(userKey: string, membershipKey: string) {
    return this.afs.doc(`users/${userKey}/history/${membershipKey}`).valueChanges();
  }

  async add(userKey: any, membership: any): Promise<any> {
    return await this.afs.collection<Membership>(`users/${userKey}/history`).add(membership);
  }

  async delete(userKey: string, membershipKey: string): Promise<void> {
    return await this.afs.doc<Membership>(`users/${userKey}/history/${membershipKey}`).update({ trash: true });
  }

  update(userKey: any, membershipKey: any, membership: any) {
    this.afs.doc(`users/${userKey}/history/${membershipKey}`).update(membership);
  }

  getAllPayment(): Observable<Membership[]> {
    return this.afs.collectionGroup(`history`, ref => ref
      .where('status', '>=', Status.PENDING)
      .where('status', '<', Status.PAID)
      .where('trash', '==', false)
      .orderBy('status')
      .orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          let data: any = action.payload.doc.data();
          const path = action.payload.doc.ref.path;
          data['key'] = action.payload.doc.id;
          // @ts-ignore
          data['userKey'] = action.payload.doc.Df.key.path.segments[6];
          data['user'] = {
            path: path.replace(`history/${data.key}`, '')
          };
          return data;
        }))
      );
  }

  getPayment(userKey: string, paymentKey: string): Observable<Membership> {
    return this.db.docWithId$<Membership>(`users/${userKey}/history/${paymentKey}`);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getByStripe(start, end) {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/createBalanceReport`, {
      start: +start,
      end: +end
    }).pipe(first()).toPromise();

    if (!resp.result) {
      await this.delay(5000);
      return this.getByStripe(start, end);
    }

    return resp;
  }

  async getChargesByStripe(start, end, starting_after = null, data = []) {
    const resp: any = await this.http.post(`${environment.apiBaseURL}/getCharges`, {
      start,
      end,
      starting_after
    }).pipe(first()).toPromise();

    if (resp.charges.length == 100) return this.getChargesByStripe(start, end, resp.charges[99].id, data.concat(resp.charges));

    return data.concat(resp.charges);
  }
}

