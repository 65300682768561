import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TiFormComponent} from '../ti-form/ti-form.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'ti-input',
  templateUrl: './ti-input.component.html',
  styleUrls: ['./ti-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TiInputComponent,
      multi: true
    }
  ]
})
export class TiInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: 'select' | 'select-bootstrap' | 'file' | 'file-dropzone' | 'toggle' | 'date' | 'ckeditor' | string = 'text';
  @Input() name: any;
  @Input() accept: string;
  @Input() classes: string = 'mb-2';
  @Input() bindLabel: string;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() items: any[] = [];
  @Input() routeFile: string;
  @Input() dropzoneLabel: string = 'Arrastra los archivos aquí o haz clic para seleccionarlos';
  files: File[] = [];
  Editor = ClassicEditor;

  get formControl() {
    return this.form.controls[this.name];
  }

  constructor(public form: TiFormComponent) {
  }

  ngOnInit(): void {
    if (this.disabled) this.formControl.disable();
  }

  writeValue(value) {
  }

  registerOnChange(fn) {
  }

  registerOnTouched(fn) {
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.formControl.patchValue(this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);

    if (!this.files.length) return this.formControl.patchValue(null);

    this.formControl.patchValue(this.files);
  }


  getMessageError(errors) {
    if (!errors) return '';

    switch (Object.keys(errors)[0]) {
      case 'email':
        return 'Email inválido';

      default :
        return 'Campo obligatorio';
    }
  }

  compareWith(item1, item2) {
    return item1 && item2 && item1.key === item2.key;
  }
}
