import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ti-icon-button',
  templateUrl: './ti-icon-button.component.html',
  styleUrls: ['./ti-icon-button.component.scss']
})
export class TiIconButtonComponent implements OnInit {
  //https://modernadmin.evolvision.com/icons/line-awesome
  @Input() icon: string = 'plus';
  @Input() tooltip: string = '';
  @Input() disabled: boolean;
  @Input() color: string = 'primary';
  @Input() size: string = '12';
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
