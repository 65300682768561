import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {User} from "../../interfaces/user";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userKey: string;
  user: User;
  tabIndex: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private _user: UserService) {
  }

  async ngOnInit(): Promise<void> {
    this.userKey = this.activatedRoute.snapshot.params['userKey'];
    this.user = await this._user.getSpecificUser(this.userKey);
  }

}
