<app-datatable [rows$]="pendingPayment$"
               [config]="dataTableConfig">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ (row.user | doc | async)?.firstName }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Membresía">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Día de adquisición">
    <ng-template let-row #column>
      {{ row.createdAt | date: 'd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      {{ row.paymentType == paymentType.INTERBANK ? 'INTERBANCARIO' : 'TARJETA'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Precio">
    <ng-template let-row #column>
      {{ row.price ? (row.price | currency) : '-' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estado">
    <ng-template let-row #column>
      <span *ngIf="row.status == typeSatus.PENDING">PENDIENTE</span>
      <span *ngIf="row.status == typeSatus.PAID">PAGADO</span>
    </ng-template>
  </app-datatable-column>

</app-datatable>

