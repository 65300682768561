import { Component, OnInit } from '@angular/core';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { Observable } from 'rxjs';
import { AlertService } from '../../../shared/template-services/alert.service';
import { RecipesCategoriesService } from '../../services/recipes-categories.service';

@Component({
  selector: 'app-recipes-categories',
  templateUrl: './recipes-categories.component.html',
  styleUrls: ['./recipes-categories.component.css']
})
export class RecipesCategoriesComponent implements OnInit {
  categories$: Observable<any[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Recetas'
  };

  constructor(private _recipesCategories: RecipesCategoriesService) {
  }

  ngOnInit(): void {
    this.categories$ = this._recipesCategories.getAll();
  }

  async addCategory() {
    const categoryName = await AlertService.input('Ingrese el nombre de la categoría');
    if (!categoryName) return;

    await this._recipesCategories.add({
      data: {
        name: categoryName
      }
    });

    AlertService.toastSuccess('Categoría creada correctamente');
  }

  async editCategory(category: any) {
    const categoryName = await AlertService.input('Ingrese el nuevo nombre de la categoría', '', 'Guardar', 'text', category.name);
    if (!categoryName) return;

    await this._recipesCategories.update({
      data: {
        name: categoryName
      },
      id: category.key
    });

    AlertService.toastSuccess('Categoría actualizada correctamente');
  }

  async handleDelete(categoryKey: string) {
    if (await AlertService.confirm('¿Está seguro que desea eliminar esta categoría?')) {
      await this._recipesCategories.update({
        data: { trash: true },
        id: categoryKey
      });

      AlertService.toastSuccess('Categoría eliminada correctamente');
    }
  }

}
