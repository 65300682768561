import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Video} from '../../interfaces/video';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {VideoService} from '../../services/video.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Observable, of, Subscription} from 'rxjs';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../interfaces/category';
import {MembershipService} from '../../services/membership.service';
import { debounceTime, map, take } from 'rxjs/operators';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent implements OnInit {
  video: Video = {key: '', isCarousel: false} as Video;
  videoForm: FormGroup;
  formSubmitted: boolean = false;
  categories$: Observable<Category[]>;
  tools$: Observable<any[]>;
  memberships$: Observable<any[]>;
  picture: any;
  path;
  sharePicture: any;
  sharePath: any;
  lastPosition: number;
  videosWithCarousel: Video[];
  color: string = '#ffffff';
  categorySubscription: Subscription = new Subscription();
  categories: Category[] = [];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _video: VideoService,
              private _category: CategoryService,
              private _membership: MembershipService,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit() {
    this.categories$ = this._category.getAll()
    this.categories = await this._category.getAll().pipe(
      debounceTime(500),
      take(1)
    ).toPromise()
    this.memberships$ = this._membership.getAll();
    this.tools$ = this._category.getAllTools();

    if (!!this.video.title) {
      this.color = this.video.color;
      let categories = await this.categories$.pipe(take(1)).toPromise();
      let memberships = await this.memberships$.pipe(take(1)).toPromise();
      let tools = await this.tools$.pipe(take(1)).toPromise();

      this.video.categories = categories.filter(categoryRef => {
        return this.video.categories.some(category => category.id === categoryRef.key);
      });

      this.video.memberships = memberships.filter(membershipRef => {
        return this.video.memberships.some(membership => membership.id === membershipRef.key);
      });

      this.video.tools = tools.filter(toolsRef => {
        return this.video.tools.some(tools => tools.id === toolsRef.key);
      });
      this.video.createdAt = new Date(this.video.createdAt);
      this.videoForm.patchValue(this.video);
      this.path = this.video.photoUrl;
      this.sharePath = this.video.shareImageUrl;
    }
  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
  }

  get formControls() {
    return this.videoForm.controls;
  }

  createForm(): void {
    this.videoForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      videoUrl: ['', Validators.required],
      shareImageUrl: [''],
      memberships: [[], Validators.required],
      categories: [[], Validators.required],
      tools: [[], Validators.required],
      photoUrl: ['', Validators.required],
      difficulty: ['', Validators.required],
      hideTitle: [false],
      hideTime: [false],
      color: ['#ffffff'],
      createdAt: [''],
      duration: [''],
      isCarousel: [false],
      position: [null],
      isImage: [false],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.videoForm.valid) {
      if (!this.videoForm.value.duration) {
        return AlertService.error('El URL del video es inválido', 'Favor de verificarlo');
      }

      if (!this.video.title) {
        this.video.key = this._video.createId();
      }

      if (this.video.isCarousel && !this.videoForm.value.isCarousel) {
        this.videoForm.patchValue({position: ''});
      }

      if (!!this.picture) {
        this.videoForm.patchValue({photoUrl: await this._video.uploadPicture(this.picture, this.video.key, 'videoPhoto')});
      }

      if (!!this.sharePicture) {
        this.videoForm.patchValue({shareImageUrl: await this._video.uploadPicture(this.sharePicture, this.video.key, 'sharePhoto')});
      }

      if (!this.video.title) {
        this.videoForm.patchValue({createdAt: new Date().getTime()});
      } else {
        this.videoForm.patchValue({createdAt: new Date(this.videoForm.value.createdAt).getTime()});
      }

      this.saveToolsRefeference();
      this.saveCategoriesRefeference();
      this.saveMembershipsRefeference();

      this._video.set(this.video.key, {...this.videoForm.value, color: this.color} as Video);

      let membershipsKeys = this.videoForm.value.memberships.map(membership => membership.id);

      this._video.sendNotification(this.videoForm.value.title, this.videoForm.value.photoUrl, membershipsKeys);

      AlertService.toastSuccess('Se guardó el video exitosamente');
      this.modal.hide();
    }
  }

  async createCategory() {
    const category: string = await AlertService.input('Añadir categoría');
    if (!!category) {
      let categories = await this._category.getAll().pipe(take(1)).toPromise();
      this._category.add({name: category, trash: false, position: categories.length + 1});
      AlertService.toastSuccess(`Se ha creado "${category}" correctamente`);
    }
  }

  async createTools() {
    const tool: string = await AlertService.input('Añadir instrumentos');
    if (!!tool) {
      this._category.addTool({name: tool, trash: false});
      AlertService.toastSuccess(`Se ha creado "${tool}" correctamente`);
    }
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  chooseSharePicture(event) {
    this.sharePicture = event.target.files[0];
    if (event.target.files && this.sharePicture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.sharePath = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.sharePicture);
    }
  }

  saveCategoriesRefeference() {
    let categoriesReference = [];
    for (let category of this.videoForm.get('categories').value) {
      categoriesReference.push(this._category.getReference(category.key));
    }
    this.videoForm.patchValue({categories: categoriesReference});
  }

  saveToolsRefeference() {
    let toolsReference = [];
    for (let tool of this.videoForm.get('tools').value) {
      toolsReference.push(this._category.getReferenceTools(tool.key));
    }
    this.videoForm.patchValue({tools: toolsReference});
  }

  saveMembershipsRefeference() {
    let membershipsReference = [];
    for (let membership of this.videoForm.get('memberships').value) {
      membershipsReference.push(this._fds.getReference(`memberships/${membership.key}`));
    }
    this.videoForm.patchValue({memberships: membershipsReference});
  }


  saveDurationVideo(event) {
    this.videoForm.patchValue({duration: event.target.duration});
  }

  handleUrlVideo() {
    this.videoForm.patchValue({duration: null});
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  searchFn = (searchTerm: string, eachObject) => {
    return eachObject.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  }
}
