<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Total de membresías contratadas por transacción</h6>
            <h3>{{getMembershipsPercentage(paymentTypeEnum.INTERBANK).toFixed(2) + '%'}}</h3>
          </div>
          <div class="align-self-center">
            <i class="la la-credit-card font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Total de membresías contratadas por PayPal</h6>
            <h3>{{getMembershipsPercentage(paymentTypeEnum.PAYPAL).toFixed(2) + '%'}}</h3>
          </div>
          <div class="align-self-center">
            <i class="la la-cc-paypal font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Total de membresías contratadas por Stripe</h6>
            <h3>{{getMembershipsPercentage(paymentTypeEnum.STRIPE).toFixed(2) + '%'}}</h3>
          </div>
          <div class="align-self-center">
            <i class="la la-cc-paypal font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
