import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbCarouselConfig, NgbModalConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {appRoutes} from './app.routing';
import {AppComponent} from './app.component';
import {SettingsModule} from './layout/settings/settings.module';
import {ThemeSettingsConfig} from './layout/settings/theme-settings.config';
import {MenuSettingsConfig} from './layout/settings/menu-settings.config';
import {HeaderComponent} from './layout/header/header.component';
import {VerticalComponent as HeaderVerticalComponent} from './layout/header/vertical/vertical.component';
import {HorizontalComponent as HeaderHorizontalComponent} from './layout/header/horizontal/horizontal.component';
import {FullLayoutNavbarComponent} from './layout/header/full-layout-navbar/full-layout-navbar.component';
import {FooterComponent} from './layout/footer/footer.component';
import {NavigationComponent as AppNavigationComponent} from './layout/navigation/navigation.component';
import {PublicLayoutComponent} from './layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './layout/private-layout/private-layout.component';
import {VerticalnavComponent} from './layout/navigation/verticalnav/verticalnav.component';
import {HorizontalnavComponent} from './layout/navigation/horizontalnav/horizontalnav.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {RouterModule} from '@angular/router';
import {CustomizerComponent} from './layout/customizer/customizer.component';
import {ChartsModule} from 'ng2-charts';
import {BreadcrumbModule} from './layout/breadcrumb/breadcrumb.module';
import {HorizontalCustomizerComponent} from './layout/customizer/horizontal-customizer/horizontal-customizer.component';
import {BlockTemplateComponent} from './layout/blockui/block-template.component';
import {BlockUIModule} from 'ng-block-ui';
import {FullLayoutComponent} from './layout/full-layout/full-layout.component';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './shared/shared.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AngularFireStorage} from '@angular/fire/storage';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {ColorPickerModule} from 'ngx-color-picker';
import {SafePipeModule} from 'safe-pipe';
import {CDK_DRAG_CONFIG} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    RouterModule.forRoot(appRoutes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    LoadingBarRouterModule,
    BlockUIModule.forRoot(),
    SharedModule,
    ModalModule.forRoot(),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatMomentDateModule,
    CommonModule,
    SafePipeModule,
    ColorPickerModule
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent
  ],
  providers: [
    NgbCarouselConfig,
    NgbModalConfig,
    AngularFireStorage,
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN'},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: CDK_DRAG_CONFIG, useValue:  {
        dragStartThreshold: 0,
        pointerDirectionChangeThreshold: 5,
        zIndex: 10000
      } }
  ],
  bootstrap: [AppComponent],
  exports: [
    RouterModule
  ]
})
export class AppModule {
}
