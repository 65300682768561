import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CategoryService } from '../../services/category.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Category } from '../../interfaces/category';
import { ProgramsService } from '../../services/programs.service';
import { take } from 'rxjs/operators';
import { ObjectService } from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-category',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.css']
})
export class CategoryModalComponent implements OnInit {
  category: Category = { key: '' } as Category;
  categoryForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  lastPosition: number;
  programs = [];
  programsIndexed = {};
  isUmanaMethodProgram: boolean = false;
  picture: any;
  file: any;
  path;

  constructor(private formBuilder: FormBuilder,
    public modal: BsModalRef,
    private _category: CategoryService,
    private _program: ProgramsService) {
    this.createForm();
  }

  async ngOnInit(): Promise<void> {
    this.programs = (await this._program.getAll().pipe(take(1)).toPromise())
      .filter((program: any) => !program.trash);

    this.programsIndexed = ObjectService.indexArray(this.programs, 'code');

    if (!!this.category.name) {
      this.isEdit = true;
      this.categoryForm.patchValue({
        ...this.category,
        program: this.programsIndexed[this.category.program]
      });
      this.path = this.category.imageUrl;

      this.isUmanaMethodProgram = this.category.program === 'method';
    }
  }

  get formControls() {
    return this.categoryForm.controls;
  }

  createForm(): void {
    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      position: [''],
      program: [null],
      trash: [false],
      achievementQuantity: [''],
      achievementIcon: [''],
      imageUrl: ['']
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.categoryForm.valid) {
      this.categoryForm.patchValue({ program: this.categoryForm.value.program.code });

      if (!this.isEdit) {
        const doc = await this._category.add({
          ...this.categoryForm.value,
          position: this.lastPosition + 1
        });

        if (!!this.picture) {
          await this._category.update(doc.id, {
            imageUrl: await this._category.uploadPicture(this.picture, this.category.key)
          });
        }
      } else {
        let url = null;

        if (!!this.picture) {
          url = await this._category.uploadPicture(this.picture, this.category.key);
        }

        await this._category.update(this.category.key, {
          ...this.categoryForm.value,
          position: +this.categoryForm.value.position,
          imageUrl: url || this.category.imageUrl
        });
      }

      AlertService.toastSuccess(this.isEdit ? 'Se ha actualizado correctamente' : 'Se ha guardado correctamente');
      this.modal.hide();
    }
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }
}
