import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {Observable} from "rxjs";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'app-payment-history-list',
  templateUrl: './payment-history-list.component.html',
  styleUrls: ['./payment-history-list.component.css']
})
export class PaymentHistoryListComponent implements OnInit {
  payments$: Observable<any>
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron pagos registrados',
    title: 'Lista de pagos'
  };
  paymentsType = {
    0: 'INTERBANCARIO',
    1: 'PAYPAL',
    2: 'STRIPE',
    3: 'GRATIS'
  }

  constructor(private _user: UserService) {
  }

  async ngOnInit(): Promise<void> {
    let users = await this._user.getAll().pipe(take(1)).toPromise()
    this.payments$ = this._user.getPayments()
      .pipe(
        map(payments => payments.map((payment: any) => ({
          ...payment,
          user: users.find(user => user.key == payment.user).firstName ?? 'Sin nombre',
          email: users.find(user => user.key == payment.user).email ?? 'Sin correo',
        })))
      );
  }

}
