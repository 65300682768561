import { Component, Input, OnInit } from '@angular/core';
import { PaymentType } from '../../../shared/enums/payment-type.enum';

@Component({
  selector: 'app-hired-memberships-report',
  templateUrl: './hired-memberships-report.component.html',
  styleUrls: ['./hired-memberships-report.component.css']
})
export class HiredMembershipsReportComponent implements OnInit {
  @Input() filteredHistory: any = [];
  @Input() stripeChargesCount: number = 0;
  @Input() history: any;
  paymentTypeEnum = PaymentType;

  constructor() {
  }

  ngOnInit(): void {
  }

  getMembershipsPercentage(paymentTypeExpected) {
    if (!this.filteredHistory) return 0;

    const totalByTransferAndPaypal = this.filteredHistory.filter(history =>
      history.paymentType === PaymentType.PAYPAL
      || history.paymentType == PaymentType.INTERBANK
    ).length;

    if (paymentTypeExpected === PaymentType.STRIPE) {
      return (this.stripeChargesCount / (totalByTransferAndPaypal + this.stripeChargesCount)) * 100;
    }

    const total = this.filteredHistory.filter(history => history.paymentType === paymentTypeExpected).length;
    return (total / (totalByTransferAndPaypal + this.stripeChargesCount)) * 100;
  }
}
