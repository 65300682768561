<form [formGroup]="settingSectionsForm" class="mt-2">
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field class="w-100">
        <mat-label>Título</mat-label>
        <input matInput
               formControlName="title">
        <mat-error *ngIf="formSubmitted && formControls.title.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field class="w-100">
        <mat-label>Texto</mat-label>
        <input matInput
               formControlName="text">
        <mat-error *ngIf="formSubmitted && formControls.text.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field class="w-100">
        <mat-label class="bigger-font">Color del título</mat-label>
        <input matInput
               type="color"
               formControlName="titleColor">
        <mat-error *ngIf="formSubmitted && formControls.titleColor.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field class="w-100">
        <mat-label class="bigger-font">Color del círculo</mat-label>
        <input matInput
               type="color"
               formControlName="bubbleColor">
        <mat-error *ngIf="formSubmitted && formControls.bubbleColor.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field class="w-100">
        <mat-label>Foto</mat-label>
        <ngx-mat-file-input formControlName="imageUrl"
                            (change)="choosePicture($event)"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
        <mat-error *ngIf="formSubmitted && formControls.imageUrl.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-sm-6">
      <mat-form-field class="w-100">
        <mat-label>Foto Web</mat-label>
        <ngx-mat-file-input formControlName="imageUrlWeb"
                            (change)="choosePictureWeb($event)"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
        <mat-error *ngIf="formSubmitted && formControls.imageUrlWeb.errors">
          Es obligatorio
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <button class="btn btn-primary" (click)="submit()">Guardar</button>
  <div class="d-flex justify-content-center">
    <img class="preview-image mt-1"
         [src]="pathSection || settingSectionsForm.value.imageUrl">
    <img class="preview-image-web mt-1"
         [src]="pathSectionWeb || settingSectionsForm.value.imageUrlWeb">
  </div>
</form>
