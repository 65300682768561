<div class="p-2">
    <h4 class="mb-0">Cupón</h4>

    <form [formGroup]="couponForm" (ngSubmit)="submit()" class="mt-2">

        <div class="row">
            <div class="col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput
                           formControlName="name">
                    <mat-error *ngIf="formSubmitted && formControls.name.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Código de canje</mat-label>
                    <input matInput
                           formControlName="code">
                    <mat-error *ngIf="formSubmitted && formControls.code.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>Periodo de tiempo</mat-label>
                    <mat-select formControlName="periodOfTime">
                        <mat-option *ngFor="let periodOfTime of periodOfTimeArray" [value]="periodOfTime.value">
                            {{ periodOfTime.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formSubmitted && formControls.periodOfTime.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="couponForm.value.periodOfTime == periodOfTime.LIMITED">
                <mat-form-field class="w-100">
                    <mat-label>Cantidad</mat-label>
                    <input matInput
                           type="number"
                           formControlName="quantity">
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="couponForm.value.periodOfTime == periodOfTime.LIMITED">
                <mat-form-field class="w-100">
                    <mat-label>Tipo de periodo</mat-label>
                    <mat-select formControlName="periodType">
                        <mat-option *ngFor="let periodOfTime of periodTypeArray" [value]="periodOfTime.value">
                            {{ periodOfTime.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formSubmitted && formControls.periodType.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="couponForm.value.periodOfTime == periodOfTime.RANGE">
                <mat-form-field class="w-100">
                    <mat-label>Inicio</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="rangeStart" [ngModelOptions]="{standalone: true}" (dateChange)="dateStartChange()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="couponForm.value.periodOfTime == periodOfTime.RANGE">
                <mat-form-field class="w-100">
                    <mat-label>Final</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="rangeEnd" [ngModelOptions]="{standalone: true}" (dateChange)="dateEndChange()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Cantidad de cupones</mat-label>
                    <input matInput
                           type="number"
                           formControlName="available">
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Descuento</mat-label>
                    <input matInput
                           type="number"
                           formControlName="quantityDiscount">
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Tipo de periodo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of typeArray" [value]="type.value">
                            {{ type.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formSubmitted && formControls.type.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12" *ngIf="memberships$ | async as memberships">
                <div class="form-group">
                    <label>Suscripciones
                        <div class="btn btn-success btn-sm" (click)="createMembership()">Crear suscripción</div>
                    </label>
                    <ng-select formControlName="memberships" [items]="memberships" [multiple]="true" bindLabel="name"
                               placeholder="">
                    </ng-select>
                    <mat-error *ngIf="formSubmitted && formControls.memberships.errors">
                        Es obligatorio
                    </mat-error>
                </div>
            </div>

            <div class="col-sm-12">
                <mat-form-field class="w-100">
                    <mat-label>Descripción</mat-label>
                    <textarea rows="6"
                              matInput
                              formControlName="description"
                              type="text"
                              class="form-control"></textarea>
                    <mat-error *ngIf="formSubmitted && formControls.description.errors">
                        Es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="d-flex justify-content-end mt-3">
            <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
            <button class="btn btn-primary">Guardar</button>
        </div>
    </form>
</div>
