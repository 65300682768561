<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Países</h6>
            <h4 *ngFor="let membership of countriesGraphic">
              {{membership.name}}: {{membership.value}}
            </h4>
          </div>
          <div class="align-self-center">
            <i class="feather ft-clipboard font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Estados</h6>
            <h4 *ngFor="let membership of statesGraphic">
              {{membership.name}}: {{membership.value}}
            </h4>
          </div>
          <div class="align-self-center">
            <i class="feather ft-clipboard font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Ciudades</h6>
            <h4 *ngFor="let membership of citiesGraphic">
              {{membership.name}}: {{membership.value}}
            </h4>
          </div>
          <div class="align-self-center">
            <i class="feather ft-clipboard font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Géneros</h6>
            <h4 *ngFor="let membership of genderGraphic">
              {{membership.name}}: {{membership.value}}
            </h4>
          </div>
          <div class="align-self-center">
            <i class="feather ft-clipboard font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-4 col-12">
  <div class="card pull-up">
    <div class="card-content">
      <div class="card-body">
        <div class="media d-flex">
          <div class="media-body text-left">
            <h6 class="text-muted">Edades</h6>
            <h4 *ngFor="let membership of ageGraphic">
              {{membership.name}}: {{membership.value}}
            </h4>
          </div>
          <div class="align-self-center">
            <i class="feather ft-clipboard font-large-2 float-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
