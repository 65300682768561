<app-datatable [rows$]="workoutSeries$"
               [config]="dataTableConfig">

  <app-datatable-column title="Encabezado">
    <ng-template let-row #column>
      {{ row.header }} 
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Título">
    <ng-template let-row #column>
      {{ row.title }} 
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Descripción">
    <ng-template let-row #column>
      {{ row.description }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Membresías">
    <ng-template let-row #column>
      {{ row.memberships.length }}
    </ng-template>
  </app-datatable-column>  
  

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-edit fa-lg" (click)="openEditAchievementsModal(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteAchievement(row)" *ngIf="!row.trash"></i>     
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAchievementsModal()"></app-add-float-button>