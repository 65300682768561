// Default menu settings configurations
export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: []
  },
  vertical_menu: {
    items: [
      {
        title: 'Usuarios',
        icon: 'la-users',
        page: '/admin/users'
      },
      {
        title: 'Postulantes',
        icon: 'la-clipboard',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Postulantes',
              page: '/admin/applicants'
            }
          ]
        }
      },
    ]
  }
};
