import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MealPlanService } from '../../services/meal-plan.service';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-meal-plan',
  templateUrl: './meal-plan.component.html',
  styleUrls: ['./meal-plan.component.css']
})
export class MealPlanComponent implements OnInit {
  mealPlan;
  mealPlanForm = this.fb.group({
    title: [''],
    description: [''],
    imageUrl: [''],
    fileUrl: ['']
  });
  submitted = false;

  constructor(private fb: FormBuilder,
              public modal: BsModalRef,
              private _mealPlan: MealPlanService
  ) {
  }

  ngOnInit(): void {
    if (this.mealPlan) {
      this.mealPlanForm.patchValue(this.mealPlan);
    }
  }

  async uploadPdf(file, type: string = 'file') {
    return await this._mealPlan.uploadFileV2(file, `meal-plans/${this.mealPlanForm.value.title}-${type}`, this.mealPlanForm.value.title + type);
  }

  async submit() {
    this.submitted = true;

    if (this.mealPlanForm.invalid) return AlertService.toastError('Por favor, rellena todos los campos');

    this.mealPlan
      ? await this._mealPlan.update({
        data: {
          ...this.mealPlanForm.value,
          fileUrl: this.mealPlan.fileUrl != this.mealPlanForm.value.fileUrl
            ? await this.uploadPdf(this.mealPlanForm.value.fileUrl)
            : this.mealPlan.fileUrl,
          imageUrl: this.mealPlan.imageUrl != this.mealPlanForm.value.imageUrl
            ? await this.uploadPdf(this.mealPlanForm.value.imageUrl, 'image')
            : this.mealPlan.imageUrl
        },
        id: this.mealPlan.key
      })
      : await this._mealPlan.add({
        data: {
          ...this.mealPlanForm.value,
          fileUrl: await this.uploadPdf(this.mealPlanForm.value.fileUrl),
          imageUrl: await this.uploadPdf(this.mealPlanForm.value.imageUrl, 'image')
        }
      });

    AlertService.toastSuccess('Plan de alimentación guardada correctamente');
    this.modal.hide();
  }

}
