import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user.service";
import { HistoryService } from "../../services/history.service";
import { ProgramsService } from "../../services/programs.service";
import { CalendarService } from 'src/app/services/calendar.service';
import { CategoryService } from '../../services/category.service';
import { VideoService } from "../../services/video.service";
import moment from 'moment';
import _ from 'lodash';
import { debounceTime, take } from "rxjs/operators";

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  days: {
    day: Date,
    isCurrentDay: boolean,
    hasActivity: boolean,
    isAfterFromCurrentDay: boolean,
    isCurrentMonth: boolean
  }[] = [];
  weeks: any[] = [];
  selectedMonth: Date = new Date();
  videoHistory: any;
  moment = moment;
  dayChecked: any;
  loaded: boolean = false;
  finishDays: boolean[] = [];
  challenges: boolean[] = [];
  totalCalendars: number = 0;
  finishDayPercentage: number = 0;
  challengesPercentage: number = 0;
  programs;
  history;
  videosCategories;
  categories;
  allVideoHistory = [];
  allVideos = [];
  videosIndexed = {};

  constructor(
    private _user: UserService,
    private _videoHistory: HistoryService,
    private _program: ProgramsService,
    private _calendar: CalendarService,
    private _category: CategoryService,
    private _video: VideoService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.allVideos = await this._video.getAll().pipe(take(1)).toPromise();
    this.videosIndexed = _.keyBy(this.allVideos, 'key');
    this.allVideoHistory = (await this._videoHistory.getAllWithId()
      .pipe(
        debounceTime(3000),
        take(1))
      .toPromise())
      .map((item: any) => ({
        ...item,
        lastView: moment(item.lastView).format('YYYY-MM-DD'),
        video: this.videosIndexed[item.reference.id]
      }));

    const users = _.groupBy(this.allVideoHistory, 'userKey');
    await this.loadPrograms();
  }


  async loadPrograms() {
    this.programs = await this._program.getAll().pipe(take(1)).toPromise();
  }

  onProgramChange(program: any) {
   const videos = this.allVideoHistory
  }
}
