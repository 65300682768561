<div class="row mt-2">
  <div class="col-xl-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div (change)="changeStatusFilter()">
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-success" [(ngModel)]="myRadio"
                     [value]="false" name="colorRadio" id="colorRadio1">
              <label class="custom-control-label" for="colorRadio1">Sin carrusel</label>
            </div>
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-primary" [(ngModel)]="myRadio"
                     [value]="true" name="colorRadio" id="colorRadio2">
              <label class="custom-control-label" for="colorRadio2">Con carrusel</label>
            </div>
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-warning" [(ngModel)]="myRadio"
                     [value]="99" name="colorRadio" id="all">
              <label class="custom-control-label" for="all">TODOS</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group">
  <label>Categorías
  </label>
  <ng-select [items]="categoriesArray" bindLabel="name" [(ngModel)]="categorySelected"
             (ngModelChange)="filterByCategory()"></ng-select>
</div>

<app-datatable [rows$]="videosFiltered$"
               [config]="dataTableConfig">

  <app-datatable-column title="Título">
    <ng-template let-row #column>
      {{ row?.title }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha" [isClickable]="false">
    <ng-template let-row #column>
      <span (click)="changeDate(row.key)">{{row.createdAt | date : 'dd/MM/yyyy'}}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Categorías">
    <ng-template let-row #column>
      <div class=""
           *ngFor="let category of row.categories">{{!!categories[category.id] ? categories[category.id].name : ''}} </div>
    </ng-template>
  </app-datatable-column>

  <ng-container>
    <app-datatable-column title="Posición" [isClickable]="false">
      <ng-template let-row #column>
        <label class="ml-1 mr-1">{{row?.position}}</label>
      </ng-template>
    </app-datatable-column>
  </ng-container>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-end">
        <i class="feather ft-edit fa-lg mr-2" (click)="editVideo(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteVideo(row)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddVideoModal()"></app-add-float-button>
