import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() {
  }

  static print(printContent): void {
    const WindowPrt = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(`
    <html>
      <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
        <link rel="stylesheet" href="src/assets/sass/main.scss">
        <script src="https://kit.fontawesome.com/cef61353db.js" crossorigin="anonymous"></script>
        <style>
        .imageSize {
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.add-inventory-btn {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.imageSize {
    object-fit: cover;
    height: 50px;
    width: 50px;
}

.floatRight {
            float: right;
        }

        .buttonSize {
            margin-top: 28px;
            height: 40px;
            width: 50px;
        }

        .logoSize {
            height: 120px;
            width: 120px;
            object-fit: contain;
        }


        </style>
      </head>
      <body style="padding: 35px;">
        ${printContent.innerHTML}
      </body>
    </html>`);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
    }, 1000)
  }
}
