import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Membership} from "../../../admin/interfaces/membership";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {HistoryService} from "../../../admin/services/history.service";

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  @Input() userKey: string;
  history$: Observable<Membership[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron pagos pendientes',
    title: 'Historial de Pagos'
  };

  constructor(private _history: HistoryService) {
  }

  ngOnInit(): void {
    this.history$ = this._history.getAllByUser(this.userKey);
  }

}
