import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ti-form',
  templateUrl: './ti-form.component.html',
  styleUrls: ['./ti-form.component.scss']
})
export class TiFormComponent implements OnInit {
  @Input() submitted: boolean;
  @Input() form: any;
  @Input() hideSaveButton: boolean = false;
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  get controls() {
    return this.form.controls;
  }

  constructor() {
  }

  ngOnInit(): void {
  }
}
