import {Component, OnInit} from '@angular/core';
import {CalendarService} from '../../../services/calendar.service';
import {Observable} from 'rxjs';
import {Calendar} from '../../../interfaces/calendar';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../shared/template-services/alert.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CalendarDayComponent} from '../../modals/calendar-day/calendar-day.component';
import _ from 'lodash';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  dataTableConfig: DataTableConfig = {
    hasSearch: false,
    notFoundText: 'No se encontraron calendarios',
    title: 'Calendarios'
  };
  calendars$: Observable<Calendar[]>;

  constructor(private _calendar: CalendarService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.calendars$ = this._calendar.getAll();
  }

  async remove(calendar: Calendar) {
    if (await AlertService.confirm('¿Está seguro de eliminar el calendario?')) {
      await this._calendar.delete({id: calendar.key});

      AlertService.toastSuccess('Calendario eliminado correctamente');
    }
  }

  openAddDayModal() {
    this.modal.show(CalendarDayComponent, {
      class: 'modal-lg',
    });
  }

  openDayModal(calendar: Calendar) {
    this.modal.show(CalendarDayComponent, {
      initialState: {
        isEdit: true,
        calendar: _.cloneDeep(calendar)
      },
      class: 'modal-lg',
    });
  }
}
