import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UploadFileStorageComponent} from 'src/app/shared/template-components/upload-file-storage/upload-file-storage.component';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ProgramsService} from '../../services/programs.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})
export class ProgramComponent implements OnInit {
  @ViewChild('uploadFileCoverImage') uploadFileCoverImage: UploadFileStorageComponent;
  @ViewChild('uploadFileCoverImageWeb') uploadFileCoverImageWeb: UploadFileStorageComponent;
  @ViewChild('uploadFileWelcomeFile') uploadFileWelcomeFile: UploadFileStorageComponent;
  program;
  isEdit: boolean = false;
  programForm: FormGroup;
  formSubmitted: boolean = false;
  programKey: string;
  routeFile: string;
  routeFileWeb: string;
  isThereImage: boolean = false;
  isThereImageWeb: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public modal: BsModalRef,
    private _program: ProgramsService,
    private db: FirebaseDataService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.programForm.patchValue(this.program);
      this.programKey = this.program.key;
    } else this.programKey = this.db.createID();
    this.routeFile = `ProgramsImages/${this.programKey}/image`;
    this.routeFileWeb = `ProgramsImages/${this.programKey}/imageWeb`;
  }

  get formControls() {
    return this.programForm.controls;
  }

  createForm(): void {
    this.programForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      position: ['', Validators.required],
      isActive: [false],
      color: ['', Validators.required],
      coverImageUrl: [''],
      webImageUrl: [''],
      welcomeFile: [''],
      text: ['', Validators.required],
      shortText: [''],
      trash: [false]
    });
  }

  async submit() {
    this.formSubmitted = true;
    if (!this.validateCoverImage()) return;

    if (this.programForm.valid) {
      if (this.isThereImage) await this.uploadCoverImage();
      if (this.isThereImageWeb) await this.uploadCoverImageWeb();

      await this.uploadWelcomeFile();

      this.isEdit
        ? this._program.update(this.programKey, this.programForm.value)
        : this._program.add(this.programKey, this.programForm.value);

      AlertService.toastSuccess('Guardado exitosamente');
      this.modal.hide();
    }
  }

  validateCoverImage() {
    return (
      (this.isEdit && (!!this.program.coverImageUrl || !!this.isThereImage)) ||
      (!this.isEdit && this.isThereImage)
    );
  }

  validateCoverImageWeb() {
    return (
      (this.isEdit && (!!this.program.webImageUrl || !!this.isThereImageWeb)) ||
      (!this.isEdit && this.isThereImageWeb)
    );
  }

  async uploadCoverImage() {
    const url = await this.uploadFileCoverImage.uploadDocument();
    if (!!url) {
      this.programForm.patchValue({['coverImageUrl']: url});
    } else {
      if (!this.programForm.value.coverImageUrl) {
        return this.uploadFileCoverImage.assignError();
      }
    }
  }

  async uploadCoverImageWeb() {
    const url = await this.uploadFileCoverImageWeb.uploadDocument();
    if (!!url) {
      this.programForm.patchValue({['webImageUrl']: url});
    } else {
      if (!this.programForm.value.webImageUrl) {
        return this.uploadFileCoverImageWeb.assignError();
      }
    }
  }

  getProgramRouteFile() {
    return `programs/${this.programKey}`;
  }

  private async uploadWelcomeFile() {
    if (!!this.uploadFileWelcomeFile && this.uploadFileWelcomeFile.isInputValid) {
      this.programForm.patchValue({
        welcomeFile: await this.uploadFileWelcomeFile.uploadDocument()
      });
    }
  }

  handleRemoveWelcomeFile() {
    this.programForm.patchValue({welcomeFile: ''});
  }

  assignIsThereImage() {
    this.isThereImage = true;
  }

  assignIsThereImageWeb() {
    this.isThereImageWeb = true;
  }
}
