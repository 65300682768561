<block-ui>
  <div class="p-1">
    <div class="modal-header p-1">
      <h4 class="pt-1">
        Agregar usuarios
      </h4>
      <div>
        <a href="assets/templates/article_template.xlsx" class="btn btn-success mr-2">Descargar template</a>
        <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mt-4">
        <div class="offset-3 col-6">
          <div class="custom-file">
            <input type="file"
                   class="upload-drop-zone upload-drop-zone.drop"
                   id="customFile"
                   accept=".csv, .xlsx, .xls"
                   [(ngModel)]="file"
                   (change)="extractDataFromExcelFile($event)">
          </div>
        </div>
      </div>
      <h1 class="text-center">Haz clic para agregar o arrastra el archivo en el recuadro</h1>
      <div *ngIf="users.length > 0">
        <h4 class="mt-4">Lista de usuarios cargados (total: {{users.length}})</h4>
        <table class="table table-hover table-striped table-responsive mt-4 overflow-auto">
          <thead>
          <tr>
            <th>Usuario</th>
            <th>Correo electrónico</th>
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>Membresía</th>
            <th>Fecha de inicio</th>
            <th>Fecha de expiración</th>
            <th>Fecha de ingreso</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of users">
            <td>{{user.name}}</td>
            <td>{{user.email}}</td>
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.membership}}</td>
            <td>{{user.startTime | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>{{user.endTime | date: 'dd/MM/yyyy HH:mm'}}</td>
            <td>{{user.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row" *ngIf="users.length > 0">
        <div class="col d-flex justify-content-end">
          <button class="btn btn-primary" (click)="saveArticles()">Registrar usuarios</button>
        </div>
      </div>
    </div>
  </div>
</block-ui>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> {{messageLoad}} </p></ngx-spinner>
