<div class="recipe-wrapper p-2 pb-4">
  <h4 class="mb-2">Nuevo plan de alimentación</h4>

  <ti-form [form]="mealPlanForm"
           (submit)="submit()"
           (onCancel)="modal.hide()"
           [submitted]="submitted">
    <ti-input name="title"
              label="Título del plan de alimentación">
    </ti-input>
    <ti-input name="description"
              label="Descripción">
    </ti-input>

    <ti-input type="file"
              name="imageUrl"
              label="Imagen"
              dropzoneLabel="Arrastra una imagen"
              accept="image/*">
    </ti-input>

    <img
      *ngIf="mealPlan?.imageUrl && mealPlan?.imageUrl == mealPlanForm.get('imageUrl').value"
      class="mt-2"
      [src]="mealPlan.imageUrl"
      height="200"
    />

    <ti-input type="file"
              name="fileUrl"
              label="Archivo pdf"
              dropzoneLabel="Arrastra un archivo pdf"
              accept="application/pdf">
    </ti-input>

    <iframe
      *ngIf="mealPlan?.fileUrl && mealPlan?.fileUrl == mealPlanForm.get('fileUrl').value"
      class="mt-2"
      [src]="mealPlan.fileUrl | safe"
      width="100%"
      height="200"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </ti-form>
</div>

<div class="backdrop"></div>
