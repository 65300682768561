<app-datatable [rows$]="memberships$"
               [config]="dataTableConfig">

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{ row.name }}

      <span class="text-black-50 font-small-1">{{ row?.planId }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Status">
    <ng-template let-row #column>
      {{
      row?.isActive
        ? 'Activo'
        : 'Inactivo'
      }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Posición">
    <ng-template let-row #column>
      {{ row?.position }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Periodo">
    <ng-template let-row #column>
      {{ periodOfTimeLabel[row.periodOfTime] }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      {{ row.isFree ? 'Gratis' : 'Paga'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Precio">
    <ng-template let-row #column>
      {{ row.price ? (row.price | currency) : '-' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-edit fa-lg" (click)="editMembership(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteCategory(row)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddMembershipModal()"></app-add-float-button>
