import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Notification} from "../../interfaces/notification";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {BsModalService} from "ngx-bootstrap/modal";
import {AlertService} from "../../../shared/template-services/alert.service";
import {NotificationService} from "../../services/notification.service";
import {NotificationModalComponent} from "../../modals/notification-modal/notification-modal.component";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notifications$: Observable<Notification[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron notificaciones',
    title: 'Lista de notificaciones'
  };

  constructor(private _notification: NotificationService,
              private _modal: BsModalService) {
  }

  ngOnInit(): void {
    this.notifications$ = this._notification.getAll();
  }

  openAddNotificationModal() {
    this._modal.show(NotificationModalComponent);
  }

  editNotification(notification: Notification) {
    this._modal.show(NotificationModalComponent, {
      initialState: {
        notification: {...notification}
      }
    });
  }

  async deleteNotification(category: Notification) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar esta notificatión?', '')) {
      this._notification.delete(category.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }
}
