<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
         (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <ul class="nav navbar-nav flex-row">
        <!-- Remove position relative in responsive -->
        <li class="nav-item mobile-menu d-lg-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)">
            <i class="feather ft-menu font-large-1"></i>
          </a>
        </li>
        <li class="nav-item mr-auto">
          <a [routerLink]="['/admin/home']" class="navbar-brand" *ngIf="isMobile">
            <img class="brand-logo" style="filter: brightness(0) invert(1);" alt="Umana"
                 [src]="_themeSettingsConfig.brand.logo.value">
            <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
          </a>
          <img [src]="_themeSettingsConfig.brand.logo.value"
               id="brand"
               [ngClass]="{
               'brand-large': _themeSettingsConfig.menu == 'expand',
               'brand-sm' : _themeSettingsConfig.menu == 'collapse'}"
               alt="" *ngIf="!isMobile">
        </li>
        <li class="nav-item d-none d-md-block nav-toggle">
          <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
             (click)="toggleFixMenu($event)">
            <i class="feather toggle-icon font-medium-3 white"
               [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}">
            </i>
          </a>
        </li>
        <li class="nav-item d-lg-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
                                          data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i
          class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <!-- New-->
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
        </ul>
<!--        <ul class="nav navbar-nav float-right" *ngIf="notifications$ | async as notifications">-->
<!--          <li class="dropdown-notification nav-item dropdown" ngbDropdown *ngIf="notification === 'on'">-->
<!--            <a class="nav-link nav-link-label" ngbDropdownToggle>-->
<!--              <i class="ficon icon-bell"></i>-->
<!--              <span class="badge badge-pill badge-danger badge-up badge-glow">{{notifications.length}}</span>-->
<!--            </a>-->
<!--            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>-->
<!--              <li class="dropdown-menu-header">-->
<!--                <h6 class="dropdown-header m-0"><span class="grey darken-2">Notificaciones</span></h6><span-->
<!--                class="notification-tag badge badge-default badge-danger float-right m-0">{{notifications.length}}-->
<!--                {{notifications.length > 1 ? 'Nuevas' : notifications.length == 1 ? 'Nueva' : ''}}</span>-->
<!--              </li>-->
<!--              <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y" fxFlex="auto"-->
<!--                  [perfectScrollbar]="config">-->
<!--                <div class="mt-2" *ngIf="notifications.length == 0">-->
<!--                  <app-not-found size="small" title="No hay notificaciones"></app-not-found>-->
<!--                </div>-->
<!--                <a class="cursor-pointer" (click)="redirectTo(notification.redirectUrl)"-->
<!--                   *ngFor="let notification of notifications">-->
<!--                  <div class="media">-->
<!--                    <div class="media-left align-self-center"><i-->
<!--                      class="feather icon-bg-circle"-->
<!--                      [ngClass]="{-->
<!--                      'ft-minus-square bg-danger' : notification.type == notificationTypeEnum.MIN_SOCK,-->
<!--                      'ft-plus-square bg-warning' : notification.type == notificationTypeEnum.TICKET_COLLECTED || notificationTypeEnum.ARTICLE_REPORT-->
<!--                      }"></i>-->
<!--                    </div>-->
<!--                    <div class="media-body">-->
<!--                      <h6 class="media-heading">{{notificationTypeLabel[notification.type]}}</h6>-->
<!--                      <p class="notification-text font-small-3 text-muted">{{notification.description}}</p><small>-->
<!--                      <time class="media-meta text-muted"-->
<!--                            datetime="2015-06-11T18:29:20+08:00">{{notification.createdDate | date : 'dd/MM/yy HH:mm'}}</time>-->
<!--                    </small>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </li>-->
<!--              &lt;!&ndash;              TODO: AGREGAR SECCIÓN PARA VER TODAS LAS NOTIFICACIONES&ndash;&gt;-->
<!--              &lt;!&ndash;              <li class="dropdown-menu-footer">&ndash;&gt;-->
<!--              &lt;!&ndash;                <a class="dropdown-item text-muted text-center"&ndash;&gt;-->
<!--              &lt;!&ndash;                   href="javascript:void(0)">Leer todas las notificaciones</a>&ndash;&gt;-->
<!--              &lt;!&ndash;              </li>&ndash;&gt;-->
<!--            </ul>-->
<!--          </li>-->

<!--          <li class="dropdown-user nav-item" ngbDropdown>-->
<!--            <a class="nav-link dropdown-user-link" ngbDropdownToggle>-->
<!--              <span class="mr-1 user-name text-bold-700">{{_user.user.name}}</span>-->
<!--              <span class="avatar avatar-online">-->
<!--                <img [src]="!!_user.user.imageUrl ? _user.user.imageUrl : 'assets/images/default.png'"-->
<!--                     alt="avatar">-->
<!--                <i></i>-->
<!--              </span>-->
<!--            </a>-->
<!--            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">-->
<!--              <a class="dropdown-item" (click)="goToUserDetails()"><i class="feather ft-user"></i> Editar perfil </a>-->
<!--              <div class="dropdown-divider"></div>-->
<!--              <a class="dropdown-item" (click)="_auth.signOut()"><i class="feather ft-power"></i> Cerrar sesión</a>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
      </div>
    </div>
    <!-- New-->
  </div>
</nav>
