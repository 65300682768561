import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {RecipeService} from '../../services/recipe.service';
import {RecipeType} from '../../enums/recipe-type.enum';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.css']
})
export class RecipeComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  isEdit: boolean;
  recipe: any;
  recipeForm: FormGroup;
  formSubmitted: boolean = false;
  picture: any;
  file: any;
  path;
  pathFile;
  recipeTypes: any[] = [
    {
      name: 'Receta',
      id: RecipeType.RECIPE
    },
    {
      name: 'Guía',
      id: RecipeType.GUIDES
    },
    {
      name: 'Link de compra',
      id: RecipeType.PURCHASE_LINK
    },
  ];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _recipe: RecipeService,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit() {
    if (this.isEdit) {
      this.recipe.type = this.recipeTypes.find(type => type.id == this.recipe.type);
      this.recipeForm.patchValue(this.recipe);
      this.path = this.recipe.imageUrl;
      this.pathFile = this.recipe.fileUrl;
    } else {
      this.recipe = {
        key: new Date().getTime()
      };
    }
  }

  get formControls() {
    return this.recipeForm.controls;
  }

  createForm(): void {
    this.recipeForm = this.formBuilder.group({
      title: ['', Validators.required],
      imageUrl: [''],
      fileUrl: [''],
      type: [this.recipeTypes[0], Validators.required],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.recipeForm.valid) {
      this.blockui.start('Guardando...');
      if (!!this.picture) {
        this.recipeForm.patchValue({imageUrl: await this._recipe.uploadPicture(this.picture, this.recipe.key, 'imageUrl')});
      }
      if (!!this.file) {
        this.recipeForm.patchValue({fileUrl: await this._recipe.uploadPicture(this.file, this.recipe.key, 'fileUrl')});
      }

      this.recipeForm.patchValue({
        type: this.recipeForm.value.type.id
      });

      if (this.isEdit) {
        this._recipe.update(this.recipe.key, this.recipeForm.value);
      } else {
        this.recipeForm.patchValue({createdAt: new Date().getTime()});
        this._recipe.add(this.recipeForm.value);
      }

      this.blockui.stop();

      AlertService.toastSuccess('Se guardó exitosamente');
      this.modal.hide();
    }
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader> event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  chooseFile(event) {
    this.file = event.target.files[0];
    if (event.target.files && this.file) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.pathFile = (<FileReader> event.target).result;
      };
      reader.readAsDataURL(this.file);
    }
  }
}
