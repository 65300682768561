<div class="row">
  <div class="col-sm-6">
    <app-datatable
      [rows$]="categories$"
      (rowSelected)="handleCategorySelected($event)"
      [config]="dataTableConfig">
      <app-datatable-column title="Título">
        <ng-template let-row #column>
          {{ row?.name }}
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </div>

  <div class="col-sm-6">
    <app-datatable
      [rows$]="programs$"
      (rowSelected)="handleProgramSelected($event)"
      [config]="programDataTableConfig">
      <app-datatable-column title="Título">
        <ng-template let-row #column>
          <div class="badge"
               [ngClass]="row.trash ? 'badge-danger' : 'badge-success'">{{row.trash ? 'inactivo' : 'activo'}}</div>
          {{ row?.name }}
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </div>

  <div class="col-sm-6">
    <app-datatable
      [rows$]="workoutSeries$"
      (rowSelected)="handleWorkoutSeriesSelected($event)"
      [config]="workoutSeriesDataTableConfig">
      <app-datatable-column title="Título">
        <ng-template let-row #column>
          {{ row?.title }}
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </div>
</div>
