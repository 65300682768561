import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SettingService } from '../../services/setting.service';
import { PurchasedMembershipEmailSettings } from '../../interfaces/purchased-membership-email-settings';
import { UploadFileStorageComponent } from '../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-purchased-membership-email-settings',
  templateUrl: './purchased-membership-email-settings.component.html',
  styleUrls: ['./purchased-membership-email-settings.component.css']
})
export class PurchasedMembershipEmailSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('uploadPhoto') photo: UploadFileStorageComponent;
  @ViewChild('uploadFile') file: UploadFileStorageComponent;
  purchasedMembershipEmailSettingsSubscription: Subscription = new Subscription();
  purchasedMembershipEmailSettingsForm: FormGroup;
  purchasedMembershipEmailSettings: PurchasedMembershipEmailSettings;
  routeFile: string;
  imageLoaded: boolean;
  fileLoaded: boolean;
  loading: boolean;

  constructor(private _settings: SettingService,
              private formBuilder: FormBuilder) {
    this.createPurchasedMembershipEmailSettingsForm();
    this.routeFile = `Settings/PurchasedMembershipEmailImage/image`;
  }

  ngOnInit() {
    this.purchasedMembershipEmailSettingsSubscription = this._settings.getPurchasedMembershipEmailSettings().subscribe(data => {
      if (!!data) this.purchasedMembershipEmailSettingsForm.patchValue(data);
    });
  }

  ngOnDestroy(): void {
    this.purchasedMembershipEmailSettingsSubscription.unsubscribe();
  }

  createPurchasedMembershipEmailSettingsForm() {
    this.purchasedMembershipEmailSettingsForm = this.formBuilder.group({
      subject: ['Bienvenido/a', Validators.required],
      message: ['Nos alegra tenerte con nosotros', Validators.required],
      link: [''],
      linkText: [''],
      photoUrl: [''],
      fileUrl: [''],
      trash: [false]
    })
  }

  get formControls() {
    return this.purchasedMembershipEmailSettingsForm.controls;
  }

  assignImageLoaded() {
    this.imageLoaded = true;
  }

  assignFileLoaded() {
    this.fileLoaded = true;
  }

  getProgramRouteFile() {
    return 'Settings/PurchasedMembershipEmailFile/file';
  }

  async submit() {
    if (!this.purchasedMembershipEmailSettingsForm.valid) return;

    this.loading = true;

    if (this.imageLoaded) {
      const photoUrl = await this.getUploadedImageUrl();
      if (!this.photo.isInputValid) {
        this.loading = false;
        return AlertService.error('La imagen no es válida');
      }
      this.purchasedMembershipEmailSettingsForm.patchValue({photoUrl});
    }

    if (this.fileLoaded) {
      const fileUrl = await this.getUploadedFileUrl();
      if (!this.file.isInputValid) {
        this.loading = false;
        return AlertService.error('El archivo no es válido');
      }
      this.purchasedMembershipEmailSettingsForm.patchValue({fileUrl});
    }

    await this._settings.updatePurchasedMembershipEmailSettings(this.purchasedMembershipEmailSettingsForm.value);
    AlertService.toastSuccess('Datos actualizados correctamente');
    this.loading = false;
    this.imageLoaded = false;
    this.fileLoaded = false;
  }

  async getUploadedImageUrl() {
    const url = await this.photo.uploadDocument();
    if (!url) return this.photo.assignError();
    return url;
  }

  async getUploadedFileUrl() {
    const url = await this.file.uploadDocument();
    if (!url) return this.file.assignError();
    return url;
  }
}
