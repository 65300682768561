<div class="p-2">
  <h4 class="mb-0">Notificación</h4>

  <form [formGroup]="notificationForm" (ngSubmit)="submit()" class="mt-2">

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Título</mat-label>
          <input matInput
                 formControlName="message">
          <mat-error *ngIf="formSubmitted && formControls.message.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Descripción</mat-label>
          <input matInput
                 formControlName="description">
          <mat-error *ngIf="formSubmitted && formControls.description.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 d-flex flex-column">
        <mat-form-field class="w-100">
          <mat-label>Foto</mat-label>
          <ngx-mat-file-input formControlName="image"
                              (change)="choosePicture($event)"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formSubmitted && formControls.image.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center" *ngIf="path">
          <img class="preview-image mt-1"
               [src]="path">
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary">Guardar</button>
    </div>
  </form>
</div>
