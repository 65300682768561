<form action=""
      *ngIf="!!form"
      [formGroup]="form">
  <ng-content></ng-content>

<div class="d-flex justify-content-end" *ngIf="!hideSaveButton">
  <button class="btn btn-danger mb-2 mr-2"
          type="button"
          (click)="onCancel.emit()">
    Cancelar
  </button>

  <button class="btn btn-primary mb-2"
          type="button"
          (click)="submit.emit()">
    Guardar
  </button>
</div>
</form>
