import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {Subscription} from 'rxjs';
import _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.css']
})
export class GraphsComponent implements OnInit, OnChanges, OnDestroy {
  view: any[] = ['100%', 400];
  @Input() graphicMembership = [];
  @Output() onActiveUsers = new EventEmitter<number>();
  gradient: boolean = true;
  isShowGraph: boolean = false;
  users: any[];
  genderGraphic: any[];
  ageGraphic: any[];
  countriesGraphic: any[];
  statesGraphic: any[];
  citiesGraphic: any[];
  userSubscription: Subscription = new Subscription();
  activeUsers: number = 0;
  totalSales: number = 0;

  constructor(private _user: UserService) {
  }

  ngOnInit(): void {
    this.isShowGraph = false;

    setTimeout(() => {
      this.isShowGraph = true;
      this.totalSales = this.graphicMembership.reduce((acc, curr) => acc + curr.value, 0);
    }, 1000);

    this.userSubscription = this._user.getAll().subscribe((users: any) => {
      this.users = users;

      this.activeUsers = this.users.filter(user => user?.membership && user.membership.endTime > Date.now() && user.membership?.reference?.id != 'y1pI4abvw2QWZGyM7v0F').length;
      this.onActiveUsers.emit(this.activeUsers);

      this.genderGraphic = [
        {
          name: 'Masculino',
          value: this.users.filter(user => user.gender === 'm').length
        },
        {
          name: 'Femenino',
          value: this.users.filter(user => user.gender === 'f').length
        }
      ];

      const agesGroup: Object = _.groupBy(this.users.filter(user => user.birthDate), (user) => {
        return moment().diff(user.birthDate, 'years');
      });

      this.ageGraphic = Object.keys(agesGroup).map((key) => {
        return {
          name: `${key} años`,
          value: agesGroup[key].length
        };
      });

      const countriesGroup = _.groupBy(this.users.filter(user => user.country), (user) => {
        return user.country;
      });

      this.countriesGraphic = Object.keys(countriesGroup).map((key) => {
        return {
          name: key,
          value: countriesGroup[key].length
        };
      });

      const statesGroup = _.groupBy(this.users.filter(user => user.state), (user) => {
        return user.state;
      });

      this.statesGraphic = Object.keys(statesGroup).map((key) => {
        return {
          name: key,
          value: statesGroup[key].length
        };
      });

      const citiesGroup = _.groupBy(this.users.filter(user => user.city), (user) => {
        return user.city;
      });

      this.citiesGraphic = Object.keys(citiesGroup).map((key) => {
        return {
          name: key,
          value: citiesGroup[key].length
        };
      });
    });
  }

  ngOnChanges(): void {
    this.isShowGraph = false;

    setTimeout(() => {
      this.isShowGraph = true;
      this.totalSales = this.graphicMembership.reduce((acc, curr) => acc + curr.value, 0);
    }, 1000);
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}
