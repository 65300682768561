import {Component, OnInit} from '@angular/core';
import {User} from '../../interfaces/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {MembershipService} from '../../services/membership.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../../shared/template-services/auth.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Status} from '../../../shared/enums/status.enum';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  user: User = {key: ''} as User;
  userForm: FormGroup;
  formSubmitted: boolean = false;
  memberships: any[] = [];
  birthDate: number;
  membershipEndTime: number;
  typeStatus = Status;
  paid = Status.PAID;
  pending = Status.PENDING;
  membershipsSubscription: Subscription = new Subscription();


  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _user: UserService,
              private _membership: MembershipService) {
    this.createForm();
  }

  async ngOnInit() {
    this.membershipsSubscription = this._membership.getAll().subscribe(data => {
      this.memberships = data;
      if (!!this.user.firstName) {
        this.userForm.patchValue({
          birthDate: new Date(this.user.birthDate),
          membershipEndTime: this.user.membership ? new Date(this.user.membership.endTime) : '',
          membership: this.user.membership ? this.memberships.find(membership => membership.key == this.user.membership.reference.id) : '',
          status: this.user.membership && this.user.membership.status ? this.user.membership.status.toString() : '',
          firstName: this.user.firstName,
          email: this.user.email,
          photoUrl: this.user.photoUrl,
          createdAt: this.user.createdAt,
          pendingMembership: this.user.pendingMembership
        });

        this.birthDate = this.userForm.value.birthDate;
        this.membershipEndTime = this.userForm.value.membershipEndTime;
      }
    });
  }

  ngOnDestroy() {
    this.membershipsSubscription.unsubscribe();
  }

  get formControls() {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      membership: ['', Validators.required],
      membershipEndTime: ['', Validators.required],
      birthDate: ['', Validators.required],
      photoUrl: [null],
      createdAt: [new Date()],
      trash: [false],
      pendingMembership: [null],
      status: ['', Validators.required]
    });
  }

  async submit() {
    this.formSubmitted = true;
    if (this.userForm.valid) {
      this.blockui.start('Creando cuenta...');

      if (this.user.key == '') {
        await this.createAccount();
        this.userForm.patchValue({birthDate: this.userForm.get('birthDate').value.toDate().getTime()});
        this.userForm.patchValue({membershipEndTime: this.userForm.get('membershipEndTime').value.toDate().getTime()});

      } else {
        this.birthDate == this.userForm.value.birthDate ? this.userForm.patchValue({birthDate: this.userForm.get('birthDate').value.getTime()}) :
          this.userForm.patchValue({birthDate: this.userForm.get('birthDate').value.toDate().getTime()});

        this.membershipEndTime == this.userForm.value.membershipEndTime ? this.userForm.patchValue({membershipEndTime: this.userForm.get('membershipEndTime').value.getTime()}) :
          this.userForm.patchValue({membershipEndTime: this.userForm.get('membershipEndTime').value.toDate().getTime()});
      }

      this.userForm.patchValue({
        membership: {
          reference: this._membership.getReference(this.userForm.get('membership').value.key),
          endTime: this.userForm.value.membershipEndTime,
          startTime: new Date().getTime(),
          status: parseInt(this.userForm.value.status),
          paymentType: 0
        }
      });

      this.userForm.patchValue({createdAt: new Date(this.userForm.get('createdAt').value).getTime()});

      this.userForm.removeControl('membershipEndTime');
      this.userForm.removeControl('status');

      if (this.user.key == '') {
        this._user.add(this.userForm.value);
        this.blockui.stop();
        AlertService.toastSuccess('Se generó el usuario exitosamente');
      } else {
        this._user.set(this.user.key, this.userForm.value);
        this.blockui.stop();
        AlertService.toastSuccess('Se actualizó el usuario exitosamente');
      }
      this.modal.hide();
    }
  }

  private async createAccount() {
    const resp: any = await this._user.createAccount(this.userForm.get('email').value, this.userForm.get('email').value);
    if (!resp.ok) {
      await AlertService.error('Error', AuthService.getMessageError(resp.err.code));
      throw new Error();
    }

    this.user.key = resp.user.uid;
  }
}
