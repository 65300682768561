<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Desafío</h4>

    <form [formGroup]="workoutSerieForm" (ngSubmit)="submit()" class="mt-2">
      <div class="row">

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Encabezado</mat-label>
            <input matInput
                   formControlName="header">
            <mat-error *ngIf="formSubmitted && formControls.header.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Título</mat-label>
            <input matInput
                   formControlName="title">
            <mat-error *ngIf="formSubmitted && formControls.title.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Subtítulo</mat-label>
            <input matInput
                   formControlName="subtitle">
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Descripción</mat-label>
            <textarea rows="2"
                      formControlName="description"
                      matInput></textarea>
            <mat-error *ngIf="formSubmitted && formControls.description.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label>Videos</label>
            <ng-select formControlName="videos" [items]="videos" [multiple]="true" bindLabel="title"></ng-select>
            <mat-error *ngIf="formSubmitted && formControls.videos.errors">
              Es obligatorio
            </mat-error>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label>Membresías</label>
            <ng-select formControlName="memberships" [items]="memberships" [multiple]="true" bindLabel="name"
                       placeholder="">
            </ng-select>
            <mat-error *ngIf="formSubmitted && formControls.memberships.errors">
              Es obligatorio
            </mat-error>
          </div>
        </div>

        <div class="col-sm-6 d-flex flex-column">
          <mat-form-field class="w-100">
            <mat-label>Foto</mat-label>
            <ngx-mat-file-input formControlName="photoUrl"
                                (change)="choosePicture($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.photoUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div *ngIf="!showPreview" class="d-flex justify-content-center">
            <img class="preview-image mt-1"
                 [src]="path">
          </div>

          <div *ngIf="isEdit && !!showPreview" class="d-flex justify-content-center">
            <img class="preview-image mt-1"
                 [src]="workoutSerieForm.value.photoUrl">
          </div>
        </div>

        <div class="col-sm-6 d-flex flex-column">
          <mat-form-field class="w-100">
            <mat-label>Foto Web</mat-label>
            <ngx-mat-file-input formControlName="photoUrlWeb"
                                (change)="choosePictureWeb($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.photoUrlWeb.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div *ngIf="!showPreviewWeb" class="d-flex justify-content-center">
            <img class="preview-image mt-1"
                 [src]="pathWeb">
          </div>

          <div *ngIf="isEdit && !!showPreviewWeb" class="d-flex justify-content-center">
            <img class="preview-image mt-1"
                 [src]="workoutSerieForm.value.photoUrlWeb">
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
