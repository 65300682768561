<app-datatable [rows$]="tools$"
               [config]="dataTableConfig">

    <app-datatable-column title="Categoría">
        <ng-template let-row #column>
            {{ row.name }}
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="" [isClickable]="false">
        <ng-template let-row #column>
            <div class="d-flex flex-row w-100 justify-content-around">
                <i class="feather ft-edit fa-lg" (click)="editTool(row)"></i>
                <i class="feather ft-trash-2 fa-lg" (click)="deleteTool(row)"></i>
            </div>
        </ng-template>
    </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddTool()"></app-add-float-button>
