<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Cambio de contraseña</h4>

    <form [formGroup]="changePasswordForm" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Contraseña actual</mat-label>
            <input matInput
                   type="password"
                   formControlName="currentPassword">
            <mat-error *ngIf="formSubmitted && formControls.currentPassword.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Nueva contraseña</mat-label>
            <input matInput
                   type="password"
                   formControlName="newPassword">
            <mat-error *ngIf="formSubmitted && formControls.newPassword.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Repetir nueva contraseña</mat-label>
            <input matInput
                   type="password"
                   formControlName="newPasswordRepeat">
            <mat-error *ngIf="formSubmitted && formControls.newPasswordRepeat.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary" (click)="changePassword()">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
