<div class="p-2">
  <h4 class="mb-0">Imagen de carrusel</h4>

  <form [formGroup]="imageCarouselForm" (ngSubmit)="submit()" class="mt-2">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Posición</mat-label>
          <input matInput
                 type="number"
                 formControlName="position">
          <mat-error *ngIf="formSubmitted && formControls.position.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Url</mat-label>
          <input matInput
                 type="text"
                 formControlName="url">
          <mat-error *ngIf="formSubmitted && formControls.url.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <label>Sección dentro de la app</label>
          <ng-select formControlName="insideUrl"
                     [items]="sections"
                     (change)="onInsideUrl()"
                     bindValue="value"
                     bindLabel="label"></ng-select>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="formControls['insideUrl'].value == 'video'">
        <div class="form-group">
          <label>Seleccione el video a redirigir</label>
          <ng-select formControlName="redirectVideo"
                     [items]="videos"
                     bindValue="key"
                     bindLabel="title"></ng-select>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="formControls['insideUrl'].value == 'program'">
        <div class="form-group">
          <label>Seleccione el programa a redirigir</label>
          <ng-select formControlName="redirectProgram"
                     [items]="programs"
                     bindValue="key"
                     bindLabel="name"></ng-select>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <mat-checkbox (click)="changeIsLive()">¿Es en vivo?</mat-checkbox>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="isLive">
        <mat-form-field class="w-100">
          <mat-label>Link vimeo streaming</mat-label>
          <input matInput
                 type="text"
                 formControlName="streaming">
          <mat-error *ngIf="formSubmitted && formControls.streaming.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <div class="p-1">
          <app-upload-file-storage
            [routeFile]="routeFile"
            [showPreview]="true"
            label="Imagen"
            (isThereImage)="assignIsThereImage($event)">
          </app-upload-file-storage>

          <img [src]="imageCarousel.photoUrl" class="w-100" *ngIf="isEdit && !isThereImage" alt="">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary">Guardar</button>
    </div>
  </form>
</div>

