import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Membership} from "../../interfaces/membership";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";
import {UserMembershipsService} from "../../services/user-memberships.service";
import {take} from "rxjs/operators";
import {MembershipService} from "../../services/membership.service";

@Component({
  selector: 'app-user-memberships',
  templateUrl: './user-memberships.component.html',
  styleUrls: ['./user-memberships.component.css']
})
export class UserMembershipsComponent implements OnInit {
  @Input() user: any;
  memberships$: Observable<Membership>;
  memberships: Membership;
  currentDate: number = (new Date()).getTime();
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron membresías registradas',
    title: 'Historial de membresías'
  };

  constructor(private _userMemberships: UserMembershipsService,
              private _membership: MembershipService) {
  }

  async ngOnInit() {
    this.memberships$ = this._membership.get(this.user.membership.reference.path.replace('memberships/', ''));
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();
  }
}
