import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Video} from '../../interfaces/video';
import {SettingService} from '../../services/setting.service';
import {VideoService} from '../../services/video.service';
import {ObjectService} from '../../../shared/template-services/object.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  video: Video = {key: ''} as Video;
  settingForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  setting: any;
  settingWorkoutSeries: any;
  settingCalendar: any;
  settingChallenge: any;
  settingWellness: any;
  settingVideos: any;
  settingProgram: any;
  settingMealPlans: any;
  settingRider: any;
  tabIndex: number = 0;
  picture: any;
  showPreview: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private _setting: SettingService,
              private _video: VideoService) {
    this.createForm();
  }

  async ngOnInit(): Promise<void> {
    this._setting.get().subscribe(data => {
      this.setting = data;
      this.settingForm.patchValue(this.setting);
    });

    const data = await this._setting.getSettingSections().pipe(take(1)).toPromise();
    const settings = ObjectService.indexArray(data, 'key');

    this.settingWorkoutSeries = settings['workoutSeries'];
    this.settingCalendar = settings['calendar'];
    this.settingChallenge = settings['challenge'];
    this.settingWellness = settings['wellness'];
    this.settingVideos = settings['videos'];
    this.settingProgram = settings['program'];
    this.settingMealPlans = settings['meal-plans'];
    this.settingRider = settings['rider'];
  }

  get formControls() {
    return this.settingForm.controls;
  }

  createForm(): void {
    this.settingForm = this.formBuilder.group({
      clabe: [''],
      accountNumber: [''],
      email: ['']
    });
  }

  async submit() {
    this.formSubmitted = true;
    if (this.settingForm.valid) {
      this._setting.update(this.settingForm.value);
      AlertService.success('Se ha actualizado correctamente', '');
    }
  }
}
