import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AdminRoutes} from './admin-routing.module';
import {VideoListComponent} from './pages/video-list/video-list.component';
import {VideoModalComponent} from './modals/video-modal/video-modal.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatInputModule} from '@angular/material/input';
import {NgSelectModule} from '@ng-select/ng-select';
import {CategoriesComponent} from './pages/categories/categories.component';
import {CategoryModalComponent} from './modals/category-modal/category-modal.component';
import {MembershipsComponent} from './pages/memberships/memberships.component';
import {MembershipModalComponent} from './modals/membership-modal/membership-modal.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {UserListComponent} from './pages/user-list/user-list.component';
import {UserModalComponent} from './modals/user-modal/user-modal.component';
import {UserDetailsComponent} from './pages/user-details/user-details.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PaymentHistoryComponent} from './pages/payment-history/payment-history.component';
import {UserMembershipsComponent} from './pages/user-memberships/user-memberships.component';
import {CouponsComponent} from './pages/coupons/coupons.component';
import {CouponModalComponent} from './modals/coupon-modal/coupon-modal.component';
import {GraphicsComponent} from './pages/graphics/graphics.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {AddUserMassiveComponent} from './modals/add-user-massive/add-user-massive.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {NotificationModalComponent} from './modals/notification-modal/notification-modal.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ColorPickerModule} from 'ngx-color-picker';
import {ImageCarouselComponent} from './pages/image-carousel/image-carousel.component';
import {ImageCarouselModalComponent} from './modals/image-carousel-modal/image-carousel-modal.component';
import {PaymentsComponent} from './pages/payments/payments.component';
import {PaymentDetailComponent} from './modals/payment-detail/payment-detail.component';
import {PreviewTicketComponent} from './modals/preview-ticket/preview-ticket.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {MatRadioModule} from '@angular/material/radio';
import {ToolsComponent} from './pages/tools/tools.component';
import {EnvivoComponent} from './pages/envivo/envivo.component';
import {PaymentHistoryListComponent} from './pages/payment-history-list/payment-history-list.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ChallengesComponent} from './pages/challenges/challenges.component';
import {ChallengeModalComponent} from './modals/challenge-modal/challenge-modal.component';
import {ProgramsComponent} from './pages/programs/programs.component';
import {ProgramComponent} from './modals/program/program.component';
import {FilesComponent} from './pages/files/files.component';
import {FileModalComponent} from './modals/file-modal/file-modal.component';
import {RecipesComponent} from './pages/recipes/recipes.component';
import {RecipeComponent} from './modals/recipe/recipe.component';
import {RecipeTypeLabelPipe} from './pipes/recipe-type-label.pipe';
import {SlidesComponent} from './pages/slides/slides.component';
import {SlideComponent} from './modals/slide/slide.component';
import {IncomeReportComponent} from './components/income-report/income-report.component';
import {CancellationOpinionComponent} from './modals/cancellation-opinion/cancellation-opinion.component';
import {CancellationOpinionsComponent} from './pages/cancellation-opinions/cancellation-opinions.component';
import {WorkoutSeriesComponent} from './pages/workout-series/workout-series.component';
import {WorkoutSerieModalComponent} from './modals/workout-serie-modal/workout-serie-modal.component';
import {
  PurchasedMembershipEmailSettingsComponent
} from './pages/purchased-membership-email-settings/purchased-membership-email-settings.component';
import {AchievementModalComponent} from './modals/achievement-modal/achievement-modal.component';
import {CalendarComponent} from './pages/calendar/calendar.component';
import {CalendarDayComponent} from './modals/calendar-day/calendar-day.component';
import localeMx from '@angular/common/locales/es-MX';
import { OrderCalendarSectionsComponent } from './modals/order-calendar-sections/order-calendar-sections.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AchievementsComponent} from './pages/achievements/achievements.component';
import {SettingSectionsFormComponent} from './pages/settings/setting-program-form/setting-sections-form.component';
import { RecipeModalComponent } from './modals/calendar-day/components/recipe-modal/recipe-modal.component';
import { GraphsComponent } from './pages/graphics/components/graphs/graphs.component';
import {ActiveUsersByTransfersComponent} from './components/active-users-by-purchases/active-users-by-transfers.component';
import {HiredMembershipsReportComponent} from './components/hired-memberships-report/hired-memberships-report.component';
import { RecipesV2Component } from './pages/recipes-v2/recipes-v2.component';
import { RecipesCategoriesComponent } from './pages/recipes-categories/recipes-categories.component';
import { MealPlansComponent } from './pages/meal-plans/meal-plans.component';
import { MealPlanComponent } from './modals/meal-plan/meal-plan.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import {MatExpansionModule} from '@angular/material/expansion';

registerLocaleData(localeMx, 'esMx');


@NgModule({
  declarations: [
    VideoListComponent,
    VideoModalComponent,
    CategoriesComponent,
    CategoryModalComponent,
    MembershipsComponent,
    MembershipModalComponent,
    UserListComponent,
    UserModalComponent,
    UserDetailsComponent,
    PaymentHistoryComponent,
    UserMembershipsComponent,
    CouponsComponent,
    CouponModalComponent,
    GraphicsComponent,
    AddUserMassiveComponent,
    NotificationsComponent,
    NotificationModalComponent,
    ImageCarouselComponent,
    ImageCarouselModalComponent,
    PaymentsComponent,
    PaymentDetailComponent,
    PreviewTicketComponent,
    SettingsComponent,
    ToolsComponent,
    EnvivoComponent,
    PaymentHistoryListComponent,
    ChallengesComponent,
    ChallengeModalComponent,
    ProgramsComponent,
    ProgramComponent,
    FilesComponent,
    FileModalComponent,
    RecipesComponent,
    RecipeComponent,
    RecipeTypeLabelPipe,
    SlidesComponent,
    SlideComponent,
    CancellationOpinionComponent,
    CancellationOpinionsComponent,
    WorkoutSeriesComponent,
    WorkoutSerieModalComponent,
    IncomeReportComponent,
    PurchasedMembershipEmailSettingsComponent,
    AchievementsComponent,
    AchievementModalComponent,
    SettingSectionsFormComponent,
    CalendarComponent,
    CalendarDayComponent,
    OrderCalendarSectionsComponent,
    RecipeModalComponent,
    GraphsComponent,
    ActiveUsersByTransfersComponent,
    HiredMembershipsReportComponent,
    RecipesV2Component,
    RecipesCategoriesComponent,
    MealPlansComponent,
    MealPlanComponent,
    ActivitiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    SharedModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MaterialFileInputModule,
    MatInputModule,
    NgSelectModule,
    MatTabsModule,
    NgxChartsModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    ColorPickerModule,
    MatRadioModule,
    TooltipModule,
    DragDropModule,
    MatExpansionModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'esMx'},
  ]
})
export class AdminModule {
}
