<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Usuario</h4>

    <form [formGroup]="userForm" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Nombre</mat-label>
            <input matInput
                   formControlName="name">
            <mat-error *ngIf="formSubmitted && formControls.name.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="birthDateDate" [ngModelOptions]="{standalone: true}" (dateChange)="birthDateChange()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="formSubmitted && formControls.birthDate.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Foto</mat-label>
          <ngx-mat-file-input formControlName="photoUrl"
                              (change)="choosePicture($event)"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
          <mat-error *ngIf="formSubmitted && formControls.photoUrl.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center" *ngIf="path">
          <img class="preview-image mt-1"
               [src]="path">
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary" (click)="submit()">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
