<app-datatable [rows$]="notifications$"
               [config]="dataTableConfig">

  <app-datatable-column title="Notificación">
    <ng-template let-row #column>
      {{ row.message }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-edit fa-lg" (click)="editNotification(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteNotification(row)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddNotificationModal()"></app-add-float-button>
