<div class="p-4">
  <ti-form
    [form]="achievementForm"
    [submitted]="submitted"
    (onCancel)="onCancel()"
    (submit)="onSubmit()">

    <ti-input name="name"
              label="Nombre del logro">
    </ti-input>

    <ti-input name="goals"
              label="Metas de logros (ej. 1, 10, 15...)">
    </ti-input>

    <ti-input type="toggle"
              name="visible"
              label="Visible">
    </ti-input>

    <ng-container *ngFor="let goal of goalsArray">
      <ti-file
        [label]="'Medalla de meta ' + goal"
        [url]="achievementForm.value.icons[goal]"
        (onChangeFile)="changeGoal($event, goal)"></ti-file>
    </ng-container>

  </ti-form>
</div>
