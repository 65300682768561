import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs/internal/Observable';
import {take} from 'rxjs/internal/operators/take';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {WorkoutSerieService} from '../../services/workout.service';
import {VideoService} from '../../services/video.service';
import {NgBlockUI} from 'ng-block-ui/models/block-ui.model';
import {BlockUI} from 'ng-block-ui';
import {MembershipService} from '../../services/membership.service';

@Component({
  selector: 'app-workout-serie-modal',
  templateUrl: './workout-serie-modal.component.html',
  styleUrls: ['./workout-serie-modal.component.css']
})
export class WorkoutSerieModalComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  isEdit: boolean;
  workoutSerieForm: FormGroup;
  workoutSerie;
  workoutSerieKey;
  formSubmitted: boolean = false;
  picture: any;
  pictureWeb: any;
  path;
  pathWeb;
  videos: any[] = [];
  showPreview: boolean = false;
  showPreviewWeb: boolean = false;
  memberships$: Observable<any[]>;
  memberships: any[] = [];

  constructor(private formBuilder: FormBuilder,
              private db: FirebaseDataService,
              public modal: BsModalRef,
              private _workoutSeries: WorkoutSerieService,
              private _video: VideoService,
              private _membership: MembershipService) {
    this.workoutSerieKey = this.db.createID();
    this.createForm();
  }

  async ngOnInit() {
    this.videos = (await this._video.getAll().pipe(take(1)).toPromise());
    this.memberships = (await this._membership.getAll().pipe(take(1)).toPromise());
    this.memberships$ = this._membership.getAll();

    if (!this.isEdit) return this.workoutSerieKey = this.db.createID();

    this.workoutSerieForm.patchValue({
      ...this.workoutSerie,
      videos: this.workoutSerie.videos.map(video => this.videos.find(v => v.key === video.id)).filter(item => !!item),
      memberships: this.workoutSerie.memberships.map(membership => this.memberships.find(m => m.key === membership.id)).filter(item => !!item)
    });

    this.workoutSerieKey = this.workoutSerie.key;
    this.showPreview = true;
    this.showPreviewWeb = true;
  }

  get formControls() {
    return this.workoutSerieForm.controls;
  }

  createForm(): void {
    this.workoutSerieForm = this.formBuilder.group({
      header: ['', Validators.required],
      title: ['', Validators.required],
      subtitle: [''],
      description: ['', Validators.required],
      videos: [[], Validators.required],
      photoUrl: ['', Validators.required],
      photoUrlWeb: ['', Validators.required],
      createdAt: [''],
      updatedAt: [''],
      memberships: [[], Validators.required],
      trash: [false]
    });
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
        this.showPreview = false;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  choosePictureWeb(event) {
    this.pictureWeb = event.target.files[0];
    if (event.target.files && this.pictureWeb) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.pathWeb = (<FileReader>event.target).result;
        this.showPreviewWeb = false;
      };
      reader.readAsDataURL(this.pictureWeb);
    }
  }

  async submit() {
    this.formSubmitted = true;
    if (!this.workoutSerieForm.valid) return AlertService.toastError('Favor de ingresar correctamente todos los datos');

    this.blockui.start('Guardando...');

    if (!!this.picture) {
      this.workoutSerieForm.patchValue({photoUrl: await this._video.uploadPicture(this.picture, this.workoutSerieKey, 'videoPhoto')});
    }

    if (!!this.pictureWeb) {
      this.workoutSerieForm.patchValue({photoUrlWeb: await this._video.uploadPicture(this.pictureWeb, this.workoutSerieKey, 'videoPhotoWeb')});
    }

    this.workoutSerieForm.patchValue({
      videos: this.workoutSerieForm.value.videos.map(video => this._video.getReference(video.key)),
      memberships: this.workoutSerieForm.value.memberships.map(membership => this._membership.getReference(membership.key))
    });


    if (!this.isEdit) {
      this.workoutSerieForm.value['key'] = this.workoutSerieKey;
    }

    await this._workoutSeries.set(this.workoutSerieForm.value, this.workoutSerieKey);

    AlertService.toastSuccess('Guardado exitosamente');
    this.blockui.stop();
    this.modal.hide();
  }
}
