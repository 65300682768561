import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import {Calendar} from '../interfaces/calendar';
import {FirebaseDataV2Service} from '../shared/template-services/firebase-data-v2.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends FirebaseDataV2Service {

  constructor(private _db: AngularFirestore, private _storage: AngularFireStorage) {
    super(_db, _storage, 'calendars');
  }

  getAll() {
    return this.colWithIds$<Calendar>({
      queryFn: ref => ref
        .where('trash', '==', false)
        .orderBy('date', 'desc')
    });
  }
}
