<app-datatable [rows$]="categories$"
               [config]="dataTableConfig"
               (rowSelected)="editCategory($event)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ row?.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date : 'dd/MM/yyyy' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-trash-2 fa-lg" (click)="handleDelete(row.key)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="addCategory()"></app-add-float-button>
