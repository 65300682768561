<div class="p-2">
  <h4 class="mb-0">Categoría</h4>

  <form [formGroup]="categoryForm" (ngSubmit)="submit()" class="mt-2">

    <div class="row">
      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Categoría</mat-label>
          <input matInput
                 formControlName="name">
          <mat-error *ngIf="formSubmitted && formControls.name.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12" *ngIf="this.isEdit">
        <mat-form-field class="w-100">
          <mat-label>Posición</mat-label>
          <input matInput
                 formControlName="position">
          <mat-error *ngIf="formSubmitted && formControls.position.errors">
            Es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Programa</label>
          <ng-select formControlName="program"
                     [items]="programs"
                     bindLabel="name"></ng-select>
          <mat-error *ngIf="formSubmitted && formControls.program.errors">
            Es obligatorio
          </mat-error>
        </div>
      </div>

      <ng-container *ngIf="isUmanaMethodProgram">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Metas para obtener un logro (separar por comas, ej: 1, 10, 15...)</mat-label>
            <input matInput
                   formControlName="achievementQuantity">
            <mat-error *ngIf="formSubmitted && formControls.position.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Imagen del logro</mat-label>
            <input matInput
                   formControlName="achievementIcon">
            <mat-error *ngIf="formSubmitted && formControls.position.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Imagen de la categoría</mat-label>
            <ngx-mat-file-input formControlName="imageUrl"
                                (change)="choosePicture($event)"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
            <mat-error *ngIf="formSubmitted && formControls.imageUrl.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>

          <div class="d-flex justify-content-center" *ngIf="path">
            <img class="preview-image mt-1"
                 [src]="path">
          </div>
        </div>
      </ng-container>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
      <button class="btn btn-primary">Guardar</button>
    </div>
  </form>
</div>
