import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {UserRoutes, UserRoutingModule} from './user-routing.module';
import { MembershipsComponent } from './pages/memberships/memberships.component';
import {RouterModule} from "@angular/router";
import { ProfileComponent } from './pages/profile/profile.component';
import {SharedModule} from "../shared/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MaterialFileInputModule} from "ngx-material-file-input";
import {MatInputModule} from "@angular/material/input";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatTabsModule} from "@angular/material/tabs";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { PaymentHistoryComponent } from './pages/payment-history/payment-history.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { UpdateProfileComponent } from './modals/update-profile/update-profile.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { ChangeEmailComponent } from './modals/change-email/change-email.component';
import { PendingPaymentsComponent } from './pages/pending-payments/pending-payments.component';

@NgModule({
  declarations: [
    MembershipsComponent,
    ProfileComponent,
    PaymentHistoryComponent,
    MembershipComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    PendingPaymentsComponent
  ],

  imports: [
    CommonModule,
    RouterModule.forChild(UserRoutes),
    UserRoutingModule,
    SharedModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MaterialFileInputModule,
    MatInputModule,
    NgSelectModule,
    MatTabsModule,
    NgxChartsModule
  ]
})
export class UserModule { }
