<div class="row mt-2">
  <div class="col-md-12 col-sm-12 d-flex flex-row align-items-end ">
    <div class="form-group">
      <label>Membresía</label>
      <ng-select [clearable]="false"
                 [multiple]="true"
                 [items]="selectMultipleArray"
                 groupBy="type"
                 [(ngModel)]="selectedMultipleArray"
                 class="select-width mr-1"
                 bindLabel="name"></ng-select>
    </div>

    <div class="custom-control custom-checkbox mb-1">
      <input type="checkbox"
             class="custom-control-input"
             [(ngModel)]="isActive"
             (ngModelChange)="handleChangeActiveFilter($event)"
             id="actives">
      <label class="custom-control-label" for="actives">Activos</label>
    </div>

    <div class="custom-control custom-checkbox mb-1 ml-1">
      <input type="checkbox"
             class="custom-control-input"
             id="about_to_expire"
             [(ngModel)]="isAboutToExpire"
             [disabled]="!isActive">
      <label class="custom-control-label" for="about_to_expire">A punto de expirar</label>
    </div>

    <div class="form-group mr-1 ml-2">
      <label></label>
      <button class="btn btn-success" (click)="getUsersFilter()">Filtrar</button>
    </div>
  </div>

  <div class="col-md-12 col-sm-12">
    <app-datatable [rows$]="usersFiltered$"
                   [config]="dataTableConfig">

      <app-datatable-column title="Nombre" [isClickable]="false">
        <ng-template let-row #column>
          {{ row?.name }} {{row?.firstName}}

          <span class="text-black-50 font-small-1">{{ row?.subscription }}</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Membresía">
        <ng-template let-row #column>
          {{row.membership ? membershipsIndexed[row?.membership?.reference.id]?.name : 'Sin membresía'}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Estatus">
        <ng-template let-row #column>
          {{row.membership.status == typeStatus.PENDING ? 'PENDIENTE' : 'PAGADO'}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Correo electrónico">
        <ng-template let-row #column>
          {{ row.email }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="" [isClickable]="false">
        <ng-template let-row #column>
          <div class="d-flex flex-row w-100 justify-content-around">
            <i class="feather ft-edit fa-lg" tooltip="Editar" (click)="editUser(row)"></i>
            <i class="feather ft-trash-2 fa-lg" tooltip="Eliminar" (click)="deleteUser(row)"></i>
            <i class="feather ft-lock fa-lg" tooltip="restaurar contraseña" (click)="restartPassword(row)"></i>
            <i class="feather ft-hash fa-lg" tooltip="ID Stripe" (click)="handleChangeStripeID(row)"></i>
          </div>
        </ng-template>
      </app-datatable-column>

    </app-datatable>
  </div>
</div>

<div class="col d-flex justify-content-end">
  <button class="btn btn-success import-csv" app-tooltip="Agregar por excel" (click)="openAddUsersModal()"><i
    class="la la-sign-in"></i>
  </button>
</div>

<div class="col d-flex justify-content-end">
  <button class="btn btn-success export-csv" (click)="exportToCSV()"><i class="la la-file-excel-o xls-icon"></i>
  </button>
</div>

<app-add-float-button (clickEmmiter)="openAddUserModal()"></app-add-float-button>


