import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {ValidationService} from '../../template-services/validation.service';
import {AlertService} from '../../template-services/alert.service';
import {take} from 'rxjs/operators';

export enum TypeFile {
  IMAGE = 'image',
  VIDEO = 'video'
}

@Component({
  selector: 'app-upload-file-storage',
  templateUrl: './upload-file-storage.component.html',
  styleUrls: ['./upload-file-storage.component.css']
})
export class UploadFileStorageComponent implements OnInit {
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  @Input() routeFile: string = 'images';
  @Input() extension: string = 'png';
  @Input() showPreview: boolean = false;
  @Input() label: string = 'Imagen';
  @Input() errorLabel: string = 'Es obligatorio';
  @Input() controlName: string;
  @Input() accept: string = '**';
  @Input() disabled: boolean = false;
  @Input() path: any;
  @Input() submitted: boolean = false;
  @Input() typeFile: TypeFile | 'video' | 'image' = TypeFile.IMAGE;
  @Output() imageLoaded: EventEmitter<void> = new EventEmitter<void>()
  fileToUpload: any;
  isInputValid: boolean = false;
  touched: boolean = false;
  typeFileEnum = TypeFile;

  constructor(private storage: AngularFireStorage) {
  }

  ngOnInit(): void {
    this.isInputValid = !!this.path;
  }

  onFileChange(event) {
    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB');

    this.isInputValid = true;
    this.touched = true;

    this.labelImport.nativeElement.innerText = Array.from(event.target.files as FileList)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = event.target.files.item(0);

    this.choosePicture(event);
  }

  choosePicture(event) {
    if (event.target.files && this.fileToUpload) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.fileToUpload);
      this.imageLoaded.emit();
    }
  }

  public async uploadDocument() {
    this.submitted = true;
    let url = '';

    if (!!this.fileToUpload) {
      const uploadRef = this.getStorageRefDocument();
      await uploadRef.put(this.fileToUpload);
      url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
      await this.uploadDocumentStorage();
    }
    return url;
  }

  uploadDocumentStorage(): Promise<void> {
    return new Promise(resolve => {
      this.storage.upload(`${this.routeFile}.${this.getFileExtension()}`, this.fileToUpload).then(() => {
        resolve();
      });
    });
  }

  getStorageRefDocument() {
    return this.storage.ref(`${this.routeFile}.${this.getFileExtension()}`);
  }

  assignError() {
    this.submitted = true;
    this.isInputValid = false;
  }

  private getFileExtension() {
    let extensions = this.fileToUpload.name.split('.');
    return extensions[extensions.length - 1];
  }
}

/* TODO: README
*
*  MULTIPLE FILES
  @ViewChildren(UploadFileStorageComponent) filesStorage;

  this.filesStorage.forEach(async component => {
    const url = await component.uploadDocument();
    if (!!url) form.patchValue({[component.controlName]: url});
  });

* --------------------------------------
*
*  ONE FILE
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;

  const url = await this.filesStorage.uploadDocument();
  if (!!url) form.patchValue({[this.filesStorage.controlName]: url});

* */
