<div class="p-4">
  <h3 class="mb-2">Día de calendario</h3>

  <ti-form
    [form]="calendarForm"
    [submitted]="submitted"
    (onCancel)="onCancel()"
    (submit)="onSubmit()">

    <div class="d-flex">
      <ti-input name="date"
                type="date"
                label="Día de la semana">
      </ti-input>

      <ti-icon-button
        icon="sort-numeric-asc"
        tooltip="Ordenar secciones"
        color="primary"
        (click)="openOrderSectionsModal()"
        class="ml-2">
      </ti-icon-button>
    </div>

    <ti-input name="dayTip"
              type="textarea"
              label="Consejo del día"></ti-input>

    <ti-input name="videos"
              *ngIf="videos.length"
              type="select-bootstrap"
              [multiple]="true"
              label="Seleccione los videos"
              [items]="videos"
              bindLabel="title"></ti-input>

    <div class="d-flex align-items-center">
      <div class="w-100">
        <ti-input name="recipes"
                  *ngIf="recipes.length"
                  class="w-100"
                  type="select-bootstrap"
                  [multiple]="true"
                  label="Seleccione las recetas"
                  [items]="recipes"
                  bindLabel="title"></ti-input>
      </div>

      <ti-icon-button size="sm"
                      class="ml-1"
                      tooltip="Agregar nueva receta"
                      icon="plus"
                      color="success"
                      (click)="openRecipeModal()"></ti-icon-button>
    </div>

    <ti-input name="hasFeelScore"
              type="checkbox"
              label="Mostrar caritas"></ti-input>

    <ti-input name="hasFeelInput"
              type="checkbox"
              label="Mostrar retroalimentación de sentimiento"></ti-input>

    <ti-input type="file-dropzone"
              name="images"
              label="Imágenes"
              [multiple]="true"
              accept="image/*"></ti-input>

    <ng-container *ngIf="calendar && calendar.images.length">
      <div class="d-flex flex-wrap">
        <img *ngFor="let image of calendar.images; let i = index"
             [src]="image"
             tooltip="Clic para eliminar"
             (click)="removeImage(i)"
             class="calendar-image cursor-pointer ml-2">
      </div>
    </ng-container>
  </ti-form>
</div>
