// imports
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotFoundComponent} from './template-components/not-found/not-found.component';
import {BlockUIModule} from 'ng-block-ui';
import {HttpClientModule} from '@angular/common/http';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DocPipe} from './pipes/doc.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {FastSearchComponent} from './template-components/fast-search/fast-search.component';
import {NavbarService} from './template-services/navbar.service';
import {AddFloatButtonComponent} from './components/add-float-button/add-float-button.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {FilterPipe} from './pipes/filter.pipe';
import {DataTableColumnComponent} from './components/data-table-column/data-table-column.component';
import {SafePipe} from './pipes/safe.pipe';
import {UploadFileStorageComponent} from './template-components/upload-file-storage/upload-file-storage.component';
import {DotsCurrencyPipe} from './pipes/dots-currency.pipe';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TiFormComponent} from './components/ti-form/ti-form.component';
import {TiIconButtonComponent} from './components/ti-icon-button/ti-icon-button.component';
import {TiInputComponent} from './components/ti-input/ti-input.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TiFileComponent} from './components/ti-file/ti-file.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    NotFoundComponent,
    DocPipe,
    FastSearchComponent,
    AddFloatButtonComponent,
    DataTableComponent,
    FilterPipe,
    DataTableColumnComponent,
    SafePipe,
    UploadFileStorageComponent,
    DotsCurrencyPipe,
    TiFormComponent,
    TiIconButtonComponent,
    TiInputComponent,
    TiFileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BlockUIModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    ScrollingModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxDropzoneModule,
    MatCheckboxModule,
    CKEditorModule
  ],
  exports: [
    // COMPONENTS
    NotFoundComponent,
    FastSearchComponent,
    TiFormComponent,
    TiIconButtonComponent,
    TiInputComponent,
    TiFileComponent,

    // PIPES
    DocPipe,
    FilterPipe,

    // MODULES
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    AddFloatButtonComponent,
    DataTableComponent,
    DataTableColumnComponent,
    SafePipe,
    UploadFileStorageComponent,
    DotsCurrencyPipe
  ],
  providers: [
    NavbarService,
    DatePipe
  ],
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
