import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class SlideService {

  constructor(private db: FirebaseDataService,
              private storage: AngularFireStorage) {
  }

  getAll() {
    return this.db.colWithIds$('welcomeSlides', ref => ref
      .where('trash', '==', false)
      .orderBy('position', 'asc')
    );
  }

  update(key: string, recipe: any) {
    this.db.doc(`welcomeSlides/${key}`).update(recipe);
  }

  async uploadPicture(photo, videoKey: string, namePhoto: string) {
    const uploadRef = this.getStorageRef(videoKey, namePhoto);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey, namePhoto);

    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`welcomeSlides/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`welcomeSlides/${id}/${photoName}.jpeg`, data);
  }

  add(recipe: any) {
    this.db.col('welcomeSlides').add(recipe);
  }
}
