<app-datatable [rows$]="cancellationOpinions$"
               [config]="dataTableConfig">

  <app-datatable-column title="Usuario">
    <ng-template let-row #column>
      {{ (row.user | doc | async)?.email }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Motivo">
    <ng-template let-row #column>
      {{ row.optionSelected == 'Otra' ? row.customOption : row.optionSelected }}
    </ng-template>
  </app-datatable-column>

</app-datatable>
