<div [class]="classes">
  <ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'select'">
      <mat-form-field style="width: 100%">
        <mat-label>{{label}}</mat-label>

        <mat-select [formControl]="formControl"
                    [multiple]="multiple"
                    [compareWith]="compareWith"
                    [disabled]="disabled">
          <mat-option *ngFor="let item of items"
                      [value]="item">
            {{item[bindLabel]}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.submitted || formControl.errors">
          {{getMessageError(formControl.errors)}}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'select-bootstrap'">
      <label>
        {{label}}
      </label>
      <ng-select [formControl]="formControl"
                 [items]="items"
                 [multiple]="multiple"
                 notFoundText="No se encontraron resultados"
                 [bindLabel]="bindLabel"></ng-select>
    </ng-container>

    <ng-container *ngSwitchCase="'file'">
      <ti-file
        [routeFile]="routeFile"
        [showPreview]="true"
        [label]="label"
        [formControl]="formControl">
      </ti-file>
    </ng-container>

    <ng-container *ngSwitchCase="'file-dropzone'">
      <label class="form-label font-W">{{label}}</label>
      <ngx-dropzone (change)="onSelect($event)"
                    [multiple]="multiple"
                    [accept]="accept"
                    [ngClass]="{'dropzone-error': form.submitted && formControl.errors}">
        <ngx-dropzone-label>{{dropzoneLabel}}</ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of files"
                                    [file]="f"
                                    removable
                                    (removed)="onRemove(f)">
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
      <small class="form-text text-muted danger"
             *ngIf="form.submitted && formControl.errors">
        {{getMessageError(formControl.errors)}}
      </small>
    </ng-container>

    <ng-container *ngSwitchCase="'ckeditor'">
      <label class="form-label font-W">{{label}}</label>
      <ckeditor [editor]="Editor"
                [config]=""
                data=""
                [formControl]="formControl"></ckeditor>
      <small class="form-text text-muted danger"
             *ngIf="form.submitted && formControl.errors">
        {{getMessageError(formControl.errors)}}
      </small>
    </ng-container>

    <ng-container *ngSwitchCase="'toggle'">
      <mat-slide-toggle [formControl]="formControl">{{label}}</mat-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <mat-form-field class="w-100">
        <mat-label>{{label}}</mat-label>
        <input matInput
               [matDatepicker]="picker"
               [formControl]="formControl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <mat-checkbox [formControl]="formControl">{{label}}</mat-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <label class="form-label font-W">{{label}}</label>

      <textarea class="form-control"
                rows="5"
                [formControl]="formControl"
                [ngClass]="{ 'is-invalid': form.submitted && formControl.errors }">
      </textarea>

      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="form.submitted && formControl.errors">
        {{getMessageError(formControl.errors)}}
      </small>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <label
        *ngIf="label"
        class="form-label font-W">{{label}}</label>

      <input [type]="type"
             [placeholder]="placeholder"
             class="form-control"
             [formControl]="formControl"
             [ngClass]="{ 'is-invalid': form.submitted && formControl.errors }">

      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="form.submitted && formControl.errors">
        {{getMessageError(formControl.errors)}}
      </small>
    </ng-container>
  </ng-container>
</div>
