<app-datatable [rows$]="calendars$"
               [config]="dataTableConfig"
               (rowSelected)="openDayModal($event)">

  <app-datatable-column title="Día">
    <ng-template let-row #column>
      {{row.date | date: 'dd MMMM' : 'es' : 'esMx'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title=""
                        [isClickable]="false">
    <ng-template let-row #column>
      <ti-icon-button tooltip="Eliminar día"
                      color="danger"
                      icon="trash"
                      (onClick)="remove(row)"></ti-icon-button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button (click)="openAddDayModal()"></app-add-float-button>
