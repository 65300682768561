import {Injectable} from '@angular/core';
import Swal, {SweetAlertResult} from 'sweetalert2';

declare const $;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() {
  }

  static error(title: string, text: string = ''): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
  }

  static async info(title: string, text: string = '', html?: string): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      html,
      icon: 'info',
      confirmButtonText: 'Aceptar'
    }).then(result => !!result.value);
  }

  static async success(title: string, text: string = '', accept?: string, html?: string): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      html,
      icon: 'success',
      confirmButtonText: accept == 'Ir a la app' ? 'Ir a la app' : 'Aceptar'
    }).then(result => !!result.value);
  }

  static async confirm(title: string,
                       text: string = '',
                       confirmButtonText: string = 'Aceptar',
                       cancelButtonText: string = 'Cancelar'): Promise<boolean> {
    return await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
      cancelButtonText
    }).then((result) => {
      return !!result.value;
    });
  }

  static toastError(title: string, text: string = '', html?: string): void {
    Swal.fire({
      title,
      text,
      html,
      toast: true,
      icon: 'error',
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 4500
    });
  }

  static toastSuccess(title: string, text: string = '', html?: string): void {
    Swal.fire({
      title,
      text,
      html,
      toast: true,
      icon: 'success',
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 4500
    });
  }

  static async deleteWithAdmin(title: string, text: string, code: string): Promise<string> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        input: 'text',
        inputPlaceholder: code,
        showCancelButton: true,
        inputValidator: (result) => {
          if (result != code) {
            return (result || !result) && 'El código es incorrecto';
          }
        },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        preConfirm: (inputValue => {
          resolve(inputValue);
        })
      });
    });
  }

  static input(title: string, text: string = '', confirmButtonText = 'Agregar', input: any = 'text', inputValue?): Promise<string> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        icon: 'info',
        input,
        inputValue: inputValue || '',
        confirmButtonText,
        preConfirm: (input) => {
          resolve(input);
        }
      });
    });
  }

  static inputValidator(title: string, text: string = '', confirmButtonText = 'Agregar'): Promise<string> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text,
        icon: 'info',
        input: 'text',
        confirmButtonText,
        preConfirm: (input) => {
          resolve(input);
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Necesitas agregar algo!';
          }
        }
      });
    });
  }

  static async quantity(title: string, text: string = ''): Promise<any> {
    return await Swal.fire({
      title,
      text,
      input: 'number',
      inputValue: '',
      inputPlaceholder: 'Cantidad',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Necesitas escribir la cantidad a devolver';
        }
      }
    }).then((result) => {
      return result.value;
    });
  }

  static async message(title: string, text: string = '', inputValue: string = ''): Promise<any> {
    return await Swal.fire({
      title,
      text,
      input: 'textarea',
      inputValue,
      inputPlaceholder: 'Escriba un mensaje',
      showCancelButton: true,
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Necesitas escribir un mensaje';
        }
      }
    }).then((result) => {
      return result.value;
    });
  }

  static async inputs(title): Promise<any> {
    return await Swal.fire({
      title,
      html:
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="Cantidad">' +
        '<textarea id="swal-input2" class="swal2-input" rows="10" placeholder="Observaciones">',
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve([
            $('#swal-input1').val(),
            $('#swal-input2').val()
          ]);
        });
      },
      showCancelButton: true,
    }).then((result) => {
      return result.value;
    });
  }

  static async inputDate(title): Promise<any> {
    return await Swal.fire({
      title,
      html:
        '<input id="swal-input1" class="swal2-input" type="date" placeholder="Fecha">',
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve([
            $('#swal-input1').val(),
          ]);
        });
      },
      showCancelButton: true,
    }).then((result) => {
      return result.value;
    });
  }

  static async select(meses, title, placeholder) {
    return await Swal.fire({
      title: title,
      input: 'select',
      inputOptions: {
        meses
      },
      inputPlaceholder: placeholder,
      showCancelButton: true,
      inputValidator: (value) => {
        if(!value){
          return 'Necesito seleccionar un elemento'
        }
      }
    }).then((result) => {
      return result.value;
    });
  }
}
