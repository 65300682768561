<div class="w-50 mb-2">
  <label>Seleccione el programa</label>
  <ng-select [items]="programs"
             bindLabel="name"
             [(ngModel)]="programSelected"
             (ngModelChange)="handleChangeProgram()">
  </ng-select>
</div>

<app-datatable [rows$]="categories$"
               [config]="dataTableConfig">

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Programa">
    <ng-template let-row #column>
      {{row.program}}
      {{ programsIndexed[row.program]?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Videos Totales">
    <ng-template let-row #column>
      {{ getTotal(row.key) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Posición" [isClickable]="false">
    <ng-template let-row #column>
      <label class="ml-1 mr-1" (click)="handleChangePosition(row)">{{row.position}}</label>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex flex-row w-100 justify-content-around">
        <i class="feather ft-edit fa-lg" (click)="editCategory(row)"></i>
        <i class="feather ft-trash-2 fa-lg" (click)="deleteCategory(row, row.position)"></i>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddCategoryModal()"></app-add-float-button>
