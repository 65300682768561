<block-ui>
  <div class="p-2">
    <h4 class="mb-0">Membresía</h4>

    <form [formGroup]="membershipForm" (ngSubmit)="submit()" class="mt-2">

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Posición</mat-label>
            <input matInput
                   [type]="'number'"
                   formControlName="position">
            <mat-error *ngIf="formSubmitted && formControls.productId.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Membresía</mat-label>
            <input matInput *ngIf="!isEdit"
                   formControlName="name">

            <input matInput *ngIf="isEdit" disabled
                   type="text"
                   [value]="membershipForm.value.name">

            <mat-error *ngIf="formSubmitted && formControls.name.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <mat-checkbox formControlName="isOncePayment">¿La membresía es de un solo pago?</mat-checkbox>
          </div>
          <div class="form-group">
            <mat-checkbox formControlName="isActive">¿Mostrar membresía?</mat-checkbox>
          </div>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="legacy" class="w-100">
            <mat-label>Periodo de tiempo</mat-label>
            <mat-select formControlName="periodOfTime" [disabled]="isEdit">
              <mat-option *ngFor="let periodOfTime of periodOfTimeArray" [value]="periodOfTime.value">
                {{ periodOfTime.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formSubmitted && formControls.periodOfTime.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="membershipForm.value.periodOfTime == periodOfTime.LIMITED">
          <mat-form-field class="w-100">
            <mat-label>Cantidad</mat-label>
            <input matInput *ngIf="!isEdit"
                   type="number"
                   formControlName="quantity">

            <input matInput *ngIf="isEdit" disabled
                   type="number"
                   [value]="membershipForm.value.quantity">
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="membershipForm.value.periodOfTime == periodOfTime.LIMITED">
          <mat-form-field class="w-100">
            <mat-label>Tipo de periodo</mat-label>
            <mat-select formControlName="periodType" [disabled]="isEdit">
              <mat-option *ngFor="let periodOfTime of periodTypeArray" [value]="periodOfTime.value">
                {{ periodOfTime.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formSubmitted && formControls.periodType.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="membershipForm.value.periodOfTime == periodOfTime.RANGE">
          <mat-form-field class="w-100">
            <mat-label>Inicio</mat-label>
            <input matInput [matDatepicker]="picker" [disabled]="isEdit" [(ngModel)]="rangeStart"
                   [ngModelOptions]="{standalone: true}" (dateChange)="dateStartChange()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="membershipForm.value.periodOfTime == periodOfTime.RANGE">
          <mat-form-field class="w-100">
            <mat-label>Final</mat-label>
            <input matInput [matDatepicker]="picker" [disabled]="isEdit" [(ngModel)]="rangeEnd"
                   [ngModelOptions]="{standalone: true}" (dateChange)="dateEndChange()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="isFree" [disabled]="isEdit">
              <mat-option *ngFor="let isFreeType of isFreeTypeArray" [value]="isFreeType.value">
                {{ isFreeType.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formSubmitted && formControls.periodOfTime.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12" *ngIf="categories$ | async as categories">
          <div class="form-group">
            <label>Categorías
              <div class="btn btn-success btn-sm" (click)="createCategory()">Crear categoría</div>
            </label>
            <ng-select formControlName="categories" [items]="categories" [multiple]="true" bindLabel="name"></ng-select>
            <mat-error *ngIf="formSubmitted && formControls.categories.errors">
              Es obligatorio
            </mat-error>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <mat-checkbox formControlName="IsStandOut">¿La membresía tiene prioridad?</mat-checkbox>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="membershipForm.value.isFree == false">
          <mat-form-field class="w-100">
            <mat-label>Costo</mat-label>
            <input matInput *ngIf="!isEdit"
                   formControlName="price"
                   type="number">

            <input matInput *ngIf="isEdit" disabled
                   type="number"
                   [value]="membershipForm.value.price">
            <mat-error *ngIf="formSubmitted && formControls.price.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field class="w-100">
            <mat-label>Descripción</mat-label>
            <textarea rows="6"
                      formControlName="description"
                      matInput></textarea>
            <mat-error *ngIf="formSubmitted && formControls.description.errors">
              Es obligatorio
            </mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="d-flex justify-content-end mt-3">
        <div class="btn btn-danger mr-2 cursor-pointer" (click)="modal.hide()">Cerrar</div>
        <button class="btn btn-primary">Guardar</button>
      </div>
    </form>
  </div>
</block-ui>
