import {Component, OnInit} from '@angular/core';
import {first, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Membership} from '../../../admin/interfaces/membership';
import {MembershipService} from '../../../admin/services/membership.service';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserService} from '../../../admin/services/user.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {HistoryService} from '../../../admin/services/history.service';
import {PeriodType} from '../../../shared/enums/period-type.enum';
import {PeriodOfTime} from '../../../shared/enums/period-of-time.enum';
import {CouponService} from '../../../admin/services/coupon.service';
import {Coupon} from '../../../admin/interfaces/coupon';
import {DiscountType} from '../../../shared/enums/discount-type.enum';
import {User} from '../../../admin/interfaces/user';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import Swal from 'sweetalert2';
import {Status} from '../../../shared/enums/status.enum';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import moment from 'moment';
import {PeriodTypeStripeLabel} from '../../../shared/labels/periodTypeStripe.label';
import {PeriodTypeLabel} from '../../../shared/labels/period-type.label';
import {ProgramsService} from 'src/app/admin/services/programs.service';
import {CancellationService} from '../../../admin/services/cancellation.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CancellationOpinionComponent} from '../../../admin/modals/cancellation-opinion/cancellation-opinion.component';

declare const stripe;
declare const paypal;

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.css']
})
export class MembershipsComponent implements OnInit {
  memberships$: Observable<Membership[]>;
  membership$: Observable<Membership>;
  coupons$: Observable<Coupon[]>;
  memberships: Membership[];
  coupons: Coupon[];
  currentDate: number = (new Date()).getTime();
  currentUser: any;
  currentMembership: Membership;
  user: any;
  periodOfTime = PeriodOfTime;
  periodType = PeriodType;
  @BlockUI() blockui: NgBlockUI;
  couponUsed = {indexCoupon: null, indexMembership: null, use: false, membershipKey: null};
  currentTime = new Date().getTime();
  programs = [];

  constructor(private _membership: MembershipService,
              private _auth: AuthService,
              public _user: UserService,
              private _coupons: CouponService,
              private _history: HistoryService,
              private _program: ProgramsService,
              private modal: BsModalService,
              private _cancellation: CancellationService) {
  }

  async ngOnInit() {
    await this.getMemberships();
    await this.getCurrentUser();
    await this.getCoupons();
    if (!!this._user.user && !!this._user.user.pendingMembership && !this._user.user.pendingMembership.isPaypal) {
      this.loadSession();
    }
  }

  async getMemberships() {
    this.memberships$ = this._membership.getAllActives();
    this.memberships = await this.memberships$
      .pipe(
        map(membership => membership.filter(item => {
            if (item.key == 'y1pI4abvw2QWZGyM7v0F') {
              return false;
            }

            return !((item.key == '5gTBi9dlOszgfGLjsji3' || item.key == 'GkocmIhtAIzjCdAyrLtO') && this._user.user.isFreeUsed);
          })
        ),
        take(1))
      .toPromise();

    this.programs = (await this._program.getAll().pipe(take(1)).toPromise()).filter((program: any) => !program.trash);
  }

  async getCurrentUser() {
    this.currentUser = await this._auth.loadFirebaseUser();
    this._user.getCurrentUser().subscribe(user => {
      this.user = user;
      this.getCurrentMembership();
    });
  }

  async getCurrentMembership() {
    this.membership$ = this._membership.get(this.user.membership.reference.id);
    this.currentMembership = await this.membership$.pipe(take(1)).toPromise();
  }

  async getCoupons() {
    this.coupons$ = this._coupons.getAll();
    this.coupons = await this.coupons$.pipe(take(1)).toPromise();
  }

  public async contractMembership(membership, paymentType) {
    this.blockui.start('Cargando...');

    let membershipReference = this._membership.getReference(membership.key);
    let endTime = 95646466800000;

    if (paymentType != PaymentType.STRIPE || !!membership.isOncePayment) {
      endTime = moment(this.currentDate).add(membership.quantity, PeriodTypeStripeLabel[membership.periodType]).toDate().getTime();
    }

    await this._user.updateMembership(this.currentUser.uid, {
      endTime,
      reference: membershipReference,
      startTime: this.currentDate,
      status: paymentType == PaymentType.INTERBANK ? Status.PENDING : Status.PAID,
      paymentType: !!membership.isOncePayment ? PaymentType.INTERBANK : paymentType
    });

    let usedCoupon = false;
    if (!!this._user.user.pendingMembership && this._user.user.pendingMembership.couponUsed.membershipKey == membership.key) {
      usedCoupon = true;
    }

    await this.checkIsCouponUsed(usedCoupon);

    let membershipHistory = {
      ...membership,
      paymentType,
      status: paymentType == PaymentType.INTERBANK ? Status.PENDING : Status.PAID,
      createdAt: this.currentDate,
      endTime
    };

    await this._history.add(this.currentUser.uid, membershipHistory);
    this.currentMembership = membership;
    this._user.update(this._user.user.key, {pendingMembership: null} as User);

    this.blockui.stop();

    if (paymentType == PaymentType.INTERBANK) {
      await AlertService.success(`El plan "${membership.name}" ha sido contratado con status PENDIENTE`, 'Para ver información de pago Interbancario puede ir a la sección "Historial de Pagos" y seleccionar el pago pendiente', 'Ir a la app');
      window.location.href = 'http://app.umana.com.mx/';
    } else {
      await this.sendMailPDF(membership);
      await AlertService.success(`El plan "${membership.name}" ha sido contratado exitosamente`, '');
    }
  }

  async checkIsCouponUsed(usedCoupon: boolean) {
    if (usedCoupon) {
      this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].used++;
      await this._coupons.update(this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].key, this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon]);
    }

    if (this.couponUsed.indexMembership) {
      this.memberships[this.couponUsed.indexMembership].price = this.memberships[this.couponUsed.indexMembership].oldPrice;
      this.memberships[this.couponUsed.indexMembership].oldPrice = null;
    }

    this.couponUsed.indexMembership = null;
    this.couponUsed.indexCoupon = null;
    this.couponUsed.use = false;
    this.couponUsed.membershipKey = null;
  }

  async applyCoupon(membership, membershipIndex: number) {
    const code = await AlertService.input(`Aplicar cupón para la membresía de ${membership.name}`, '', 'Aplicar');
    if (!!code) {

      let indexCoupon = this.coupons.findIndex(coupon => {
        return coupon.code == code;
      });
      if (indexCoupon == -1) {
        return AlertService.toastError(`No se encuentra el código`);
      }
      let membershipReference = this.coupons[indexCoupon].memberships.find(m => m.id == membership.key);
      if (this.coupons[indexCoupon].endTime < this.currentDate) {
        return AlertService.toastError(`Este código ya caduco`);
      }
      if (!membershipReference) {
        return AlertService.toastError(`Este código no funciona en esta membresía`);
      }
      if (this.coupons[indexCoupon].used >= this.coupons[indexCoupon].available) {
        return AlertService.toastError(`Este código ya alcanzo su límite de usos`);
      }
      if (this.couponUsed.membershipKey == membership.key) {
        return AlertService.toastError(`Ya se utilizó un cupón para esta membresía`);
      }
      let discount;
      if (this.coupons[indexCoupon].type == DiscountType.PERCENTAGE) {
        discount = membership.price - (membership.price * (this.coupons[indexCoupon].quantityDiscount / 100));
      }
      if (this.coupons[indexCoupon].type == DiscountType.DIRECT) {
        discount = membership.price - this.coupons[indexCoupon].quantityDiscount;
      }

      let membershipWithNewPrice = {...membership};
      membershipWithNewPrice.price = discount;

      await this.checkIsCouponUsed(false);

      this.memberships[membershipIndex].oldPrice = membership.price;
      this.memberships[membershipIndex].price = discount;

      this.couponUsed.indexMembership = membershipIndex;
      this.couponUsed.indexCoupon = indexCoupon;
      this.couponUsed.use = true;
      this.couponUsed.membershipKey = membership.key;

      AlertService.toastSuccess('Se aplicó el cupón correctamente');
    }
  }

  getIfMembershipItsOver(membership) {
    if (membership.periodOfTime != PeriodOfTime.RANGE) {
      return true;
    }

    return membership.rangeEnd > this.currentDate;
  }

  async openSession(membership: Membership) {
    if (await AlertService.confirm(`Estás seguro que quieres contratar la membresía "${membership.name}"`, '')) {
      this.blockui.start('Cargando...');

      const resp: any = await this._membership.createSession(this.isNewUser(), this.user.email, membership.price, membership.name, membership.planId, membership.isOncePayment, !!this.coupons[this.couponUsed.indexCoupon] ? this.coupons[this.couponUsed.indexCoupon].couponId : null);
      await this._user.update(this._user.user.key, {
        pendingMembership: {
          membership,
          couponUsed: this.couponUsed,
          sessionId: resp.id
        }
      } as User);
      stripe.redirectToCheckout({sessionId: resp.id});
    }
  }

  private async loadSession() {
    this.blockui.start('Cargando...');
    const session = (await this._membership.getSession(this._user.user.pendingMembership.sessionId)).session;
    this.blockui.stop();

    if (session.payment_status == 'paid') {
      this._user.update(this._user.user.key, {subscription: session.subscription} as User);
      await this.contractMembership(this._user.user.pendingMembership.membership, PaymentType.STRIPE);
    } else {
      if (await AlertService.confirm(`Tienes un proceso de compra pendiente para la membresía "${this._user.user.pendingMembership.membership.name}"`, '¿Deseas continuar?', 'Continuar', 'Cancelar')) {
        return stripe.redirectToCheckout({sessionId: this._user.user.pendingMembership.sessionId});
      }
    }

    this._user.update(this._user.user.key, {
      pendingMembership: null
    } as User);
  }

  paypal(membership) {
    paypal.Buttons({
      createOrder: (data, actions) => {
        this._user.update(this._user.user.key, {
          pendingMembership: {
            couponUsed: this.couponUsed,
            membership,
            isPaypal: true
          }
        } as User);

        return actions.order.create({
          purchase_units: [{
            amount: {
              value: membership.price
            },
            description: membership.name
          }]
        });
      },
      onApprove: (data, actions) => {
        return actions.order.capture().then(async (details) => {
          Swal.close();
          await this.contractMembership(this._user.user.pendingMembership.membership, PaymentType.PAYPAL);
          this._user.update(this._user.user.key, {
            pendingMembership: null
          } as User);
        });
      },
      style: {
        color: 'blue',
        fundingicons: false,
        layout: 'horizontal',
        tagline: false,
        shape: 'rect',
        size: 'medium',
        height: 38
      },
      locale: 'es_MX'
    }).render('#paypal-button-container');
  }

  async choosePaymentMethod(membership) {
    if (this.hasActiveMembership()) {
      if (await AlertService.confirm('Tiene una membresía activa', '¿Desea cancelar la membresía actual?')) {
        this.cancelSubscription();
      }

      return;
    }
    Swal.fire({
      title: 'Seleccione el método de pago',
      icon: 'info',
      showConfirmButton: false,
      html: `<div class="mt-2 d-flex flex-row align-items-center justify-content-center">
                <div id="paypal-button-container"></div>
                <button class="btn btn-primary ml-1" id="card">Tarjeta</button>
             </div>`,
      onBeforeOpen: () => {
        const content = Swal.getContent();
        const $ = content.querySelector.bind(content);

        const card = $('#card');
        const interbank = $('#interbank');

        card.addEventListener('click', () => {
          this.openSession(membership);
        });
      },
    });
    this.paypal(membership);
  }

  async freeMembership(membership) {
    if (await AlertService.confirm(`Estás seguro la membresía ${membership.name}`)) {
      await this.contractMembership(membership, PaymentType.FREE);
      this._user.update(this._user.user.key, {isFreeUsed: true} as User);
    }
  }

  async cancelSubscription() {
    if (await AlertService.confirm('¿Estás seguro que deseas cancelar esta membresía?', 'Podrás seguir disfrutando de los beneficios la fecha de vencimiento')) {
      const modalRef: BsModalRef = this.modal.show(CancellationOpinionComponent, {
        ignoreBackdropClick: true,
        keyboard: false
      });

      await modalRef.onHide.pipe(first()).toPromise();

      if (!modalRef.content.optionSelected || modalRef.content.optionSelected == 'Otra' && !modalRef.content.customOption) return AlertService.error('Favor de seleccionar un motivo para cancelar tu membresía');

      this.blockui.start('Cargando...');

      let resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription);

      if (!resp.ok) {
        await AlertService.error('No se pudo cancelar la membresía');
        return window.location.reload();
      }

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime
        }
      } as User);

      AlertService.success('Se ha cancelado los pagos recurrentes a esta membresía');

      this.blockui.stop();
    }
  }

  async changeCard() {
    if (await AlertService.confirm(
      '¿Estás seguro que deseas cambiar de tarjeta?',
      'Cancelaremos tu membresía y la podrás renovar con una nueva tarjeta una vez que se termine el tiempo restante de tu membresía actual')) {
      const modalRef: BsModalRef = this.modal.show(CancellationOpinionComponent, {
        ignoreBackdropClick: true,
        keyboard: false
      });

      await modalRef.onHide.pipe(first()).toPromise();

      if (!modalRef.content.optionSelected || modalRef.content.optionSelected == 'Otra' && !modalRef.content.customOption) return AlertService.error('Favor de seleccionar un motivo para cancelar tu membresía');

      this.blockui.start('Cargando...');

      let resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription);

      if (!resp.ok) {
        await AlertService.error('No se pudo cancelar la membresía');
        return window.location.reload();
      }

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime
        }
      } as User);

      AlertService.success('Se ha cancelado los pagos recurrentes a esta membresía');

      this.blockui.stop();
    }
  }

  getMembershipText(membership) {
    if (this._user.user.membership.paymentType == PaymentType.STRIPE) {
      return `Esta membresía se renueva cada ${membership.quantity} ${PeriodTypeLabel[membership.periodType]}`;
    }
    return `Esta membresía caduca el ${moment(this._user.user.membership.endTime).format('DD/MM/yyyy')}`;
  }

  async sendMailPDF(membership) {
    const programFound: any = this.verifyWelcomeFileInMembership(membership);
    if (!!programFound
      && !!programFound.welcomeFile
      && await AlertService.confirm(
        `¿Desea que le enviemos el plan de alimentación a su correo ${this.user.email}?`,
        // 'Siempre puede volverlo a solicitar desde su perfil en la app'
      )) {
      this.blockui.start('Enviando plan de alimentación');

      await this._membership.sendMail(this.user.email, membership.name, this.user.firstName, this.user.lastName, programFound.welcomeFile);
      this.blockui.stop();

      AlertService.toastSuccess('Se ha enviado el plan de alimentación');
    }
  }

  verifyWelcomeFileInMembership(membership) {
    if (!membership) return null;
    return this.programs.find(program => membership.name
      .toLowerCase()
      .includes(program.name.toLowerCase()));
  }

  hasActiveMembership() {
    return !!this.user
      && this.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (this.user.membership.endTime == 95646466800000
        || this.user.membership.endTime == 32503705200000
        || this.user.membership.endTime == 1831060208000);
  }

  isNewUser() {
    return !!this.user && this.user.membership.reference.id == 'y1pI4abvw2QWZGyM7v0F' && (this.user.membership.endTime == 32503705200000 || this.user.membership.endTime == 32504223600000);
  }
}
