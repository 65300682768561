<div class="col-sm-12">
  <div class="form-group">
    <label>Programa</label>
    <ng-select
      [items]="programs"
      bindLabel="name"
      (selectionchange)="onProgramChange($event)"
    ></ng-select>
  </div>
</div>
