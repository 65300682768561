<div class="p-2">
  <h2 class="mb-2">¿Nos podrías dar el motivo de tu cancelación?</h2>

  <div *ngFor="let option of options">
    <label class="form-check d-inline-block m-l-10 ">
      <input name="cancel" type="radio" class="form-check-input" [value]="option" [(ngModel)]="optionSelected">
      <span>{{option}}</span>
    </label>
  </div>

  <ng-container *ngIf="optionSelected == 'Otra'">
    <div class="form-group">
      <input type="text" class="form-control form-control-line" [(ngModel)]="customOption">
    </div>
  </ng-container>

  <button class="btn btn-primary mt-2" (click)="submit()">Cancelar membresía</button>
</div>
