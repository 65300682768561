import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order-calendar-sections',
  templateUrl: './order-calendar-sections.component.html',
  styleUrls: ['./order-calendar-sections.component.css']
})
export class OrderCalendarSectionsComponent implements OnInit {
  sections: any[] = [];

  constructor(private modal: BsModalRef) {
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
  }

  close() {
    this.modal.hide();
  }

}
