import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() {
  }

  static isValid(field: any): boolean {
    return !(field === null || field === '' || field == undefined);
  }

  static isObjectValid(object: any): boolean {
    // @ts-ignore
    for (let property in object) {
      if (object[property] === null || object[property] === '') {
        return false;
      }
    }
    return true;
  }

  static isArrayValid(array: any[]): boolean {
    for (let object of array) {
      // @ts-ignore
      for (let property in object) {
        if (object[property] === null || object[property] === '') {
          return false;
        }
      }
    }
    return true;
  }

  static isArrayEmpty(array: any[]): boolean {
    return array.length !== 0;
  }

  restrictNumeric(e: any, field?: any): boolean {
    let input: any;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which === 45) {
      return false;
    }
    if (e.which === 46) {
      return !field.includes('.');
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  restrictNumericPhone(e: any, field?: any): boolean {
    let input: any;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which === 45) {
      return false;
    }
    if (e.which === 46) {
      return false;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  static validateFileSize(event, kbLimit: number): boolean {
    return event.target.files[0].size / 1000 <= kbLimit;
  }
}

