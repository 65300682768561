import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService} from '../../../shared/template-services/alert.service';
import {UserService} from '../../services/user.service';
import {take} from 'rxjs/operators';
import {MembershipService} from '../../services/membership.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-user-massive',
  templateUrl: './add-user-massive.component.html',
  styleUrls: ['./add-user-massive.component.css']
})
export class AddUserMassiveComponent implements OnInit, OnDestroy {
  data: any[];
  file: any;
  headers: string[] = [
    'name',
    'email',
    'firstName',
    'lastName',
    'membership',
    'startTime',
    'endTime',
    'createdAt'
  ];
  excelExtension = '.xlsx';
  users: any[] = [];
  unitMeasure: any[];
  userBd: any[] = [];
  memberships: any[] = [];
  isYesAll: boolean = false;
  messageLoad: string;
  membershipSubscription: Subscription = new Subscription();

  constructor(public modal: BsModalRef,
              private SpinnerService: NgxSpinnerService,
              private db: FirebaseDataService,
              private _user: UserService,
              private _membership: MembershipService) {
  }

  async ngOnInit(): Promise<void> {
    this.userBd = await this._user.getAll().pipe(take(1)).toPromise();

    this.membershipSubscription = this._membership.getAll().subscribe(data => {
      this.memberships = data;
    });
  }

  ngOnDestroy(): void {
    this.membershipSubscription.unsubscribe();
  }

  extractDataFromExcelFile(event) {
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) return AlertService.error('No se pueden cargar multiples archivos');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */

      // const bstr: string = e.target.result;
      // const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
      //
      // /* grab first sheet */
      // const wsname: string = wb.SheetNames[0];
      // const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = e.target.result.split('\n');
      this.data = this.data.map(item => item.replaceAll('\"', '').split(','));
      this.deleteUnnecessaryRows();
      this.formatXmlRows();
    };

    reader.readAsBinaryString(target.files[0]);
  }

  deleteUnnecessaryRows(): void {
    for (let i = 0; i < this.data.length; i++) {
      for (let e = 0; e < 17; e++) {
        this.data[i].splice(4, 1);
      }
      this.data[i].splice(7, 1);
    }
  }

  array = []
  count = 0

  async formatXmlRows() {
    this.users = [];
    for (let i = 1; i < this.data.length; i++) {
      if (this.data[i].length == 0) continue;
      let item = {} as any;

      for (let j = 0; j < this.data[i].length; j++) {
        if (this.data[i][j] == undefined) {
          this.data[i][j] = '';
        }
        item[this.headers[j]] = this.data[i][j];
      }

      item.startTime = new Date(item.startTime).getTime();
      item.endTime = new Date(item.endTime).getTime();
      item.createdAt = new Date(item.createdAt).getTime();

      if (item.endTime >= new Date().getTime()) {
        this.array.push(item)
      }

      if (this.userBd.some(user => user.email == item.email)) {
        continue
      }

      if (item.endTime <= new Date().getTime()) {
        continue
      }

      if (item.membership == '0') {
        // this.array.push(item)
        continue
      }

      if (item.membership == 'Clase de prueba ') {
        continue;
      }

      if (item.name == '') {
        continue;
      }

      if (item.membership == '-') {
        continue;
      }

      if (item.membership == 'Reto caro fit') continue

      if (!item.endTime) {
        continue;
      }

      // console.log(item);

      item.membership = {
        reference: this.getReferenceMembership('Mensual'),
        endTime: new Date(item.endTime).getTime(),
        startTime: new Date().getTime()
      };

      this.users.push(item);
    }
    console.log(this.array);
    console.log(this.count);
  }

  async saveArticles() {
    this.messageLoad = 'Cargando usuarios...';
    this.SpinnerService.show();
    for (let i = 0; i < this.users.length; i++) {

      let user: any = {
        name: this.users[i].name,
        email: this.users[i].email,
        firstName: this.users[i].firstName,
        lastName: this.users[i].lastName,
        birthDate: null,
        createdAt: this.users[i].createdAt,
        membership: {
          ...this.users[i].membership,
          paymentType: 0,
          status: 1
        },
        membershipKey: this.users[i].membership.reference.id,
        pendingMembership: null,
        photoUrl: null,
        trash: false,
        token: null,
        subscription: null,
        isFreeUsed: true,
      };
      this.messageLoad = `Cargando ${i + 1} de ${this.users.length} usuarios`;

      const resp: any = await this._user.createAccount(user.email, user.email);

      await this._user.addUserByKey(resp.uid, user);
    }
    await AlertService.success(`Se cargaron ${this.users.length} usuarios`);
    this.SpinnerService.hide();
    this.modal.hide();
  }

  getReferenceMembership(membershipName: string) {
    let membershipIndex = this.memberships.findIndex(membership => membership.name.trim() == membershipName.trim());
    return this._membership.getReference(this.memberships[membershipIndex].key);
  }
}
