import {Injectable} from '@angular/core';
import {FirebaseDataV2Service} from '../../shared/template-services/firebase-data-v2.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class RecipesV2Service extends FirebaseDataV2Service {

  constructor(private _db: AngularFirestore,
              private _storage: AngularFireStorage) {
    super(_db, _storage, 'recipes-v2');
  }

  getAll() {
    return this.colWithIds$({
      queryFn: ref => ref.where('trash', '==', false)
    });
  }
}
