import { Component, OnInit } from '@angular/core';
import {Coupon} from "../../interfaces/coupon";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Observable} from "rxjs";
import {Membership} from "../../interfaces/membership";
import {MembershipService} from "../../services/membership.service";
import {MembershipModalComponent} from "../membership-modal/membership-modal.component";
import {AlertService} from "../../../shared/template-services/alert.service";
import {CouponService} from "../../services/coupon.service";
import {FirebaseDataService} from "../../../shared/template-services/firebase-data.service";
import {take} from "rxjs/operators";
import {PeriodOfTime} from "../../../shared/enums/period-of-time.enum";
import {PeriodType} from "../../../shared/enums/period-type.enum";
import {DiscountType} from "../../../shared/enums/discount-type.enum";

@Component({
  selector: 'app-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.css']
})
export class CouponModalComponent implements OnInit {
  memberships$: Observable<Membership[]>;
  coupon: Coupon = {key: ''} as Coupon;
  couponForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  rangeStart: any = false;
  rangeEnd: any = false;
  periodType = PeriodType;
  periodOfTime = PeriodOfTime;
  currentDate: number = (new Date()).getTime();

  periodOfTimeArray = [
    { value: PeriodOfTime.FOREVER, viewValue: 'De por vida' },
    { value: PeriodOfTime.LIMITED, viewValue: 'Limitado' },
    { value: PeriodOfTime.RANGE, viewValue: 'Rango' }
  ];

  periodTypeArray = [
    { value: PeriodType.DAILY, viewValue: 'Días' },
    { value: PeriodType.WEEKLY, viewValue: 'Semanas' },
    { value: PeriodType.MONTHLY, viewValue: 'Meses' },
    { value: PeriodType.ANNUAL, viewValue: 'Años' }
  ];

  typeArray = [
    { value: DiscountType.PERCENTAGE, viewValue: 'Porcentual' },
    { value: DiscountType.DIRECT, viewValue: 'Directo' },
  ];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _modal: BsModalService,
              private _membership: MembershipService,
              private _coupon: CouponService,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit() {
    this.memberships$ = await this._membership.getAll();

    if (!!this.coupon.name) {
      this.isEdit = true;

      let memberships = await this.memberships$.pipe(take(1)).toPromise();
      this.coupon.memberships = this.coupon.memberships.map(membershipRef => {
        return memberships.find(membership => membership.key === membershipRef.id);
      });

      this.couponForm.patchValue(this.coupon);
      this.rangeStart = new Date(this.coupon.rangeStart);
      this.rangeEnd = new Date(this.coupon.rangeEnd);
    }
  }

  get formControls() {
    return this.couponForm.controls;
  }

  createForm(): void {
    this.couponForm = this.formBuilder.group({
      lastUpdate: [''],
      name: ['', Validators.required],
      code: ['', Validators.required],
      periodOfTime: [null, Validators.required],
      available: ['', Validators.required],
      periodType: [null],
      rangeStart: [''],
      rangeEnd: [''],
      memberships: [[], Validators.required],
      type: [null, Validators.required],
      quantity: [''],
      quantityDiscount: [''],
      description: [''],
      endTime: [''],
      used: [0],
      trash: [false]
    });
  }

  dateStartChange() {
    this.couponForm.patchValue({rangeStart: this.rangeStart.toDate().getTime()});
  }

  dateEndChange() {
    this.couponForm.patchValue({rangeEnd: this.rangeEnd.toDate().getTime()});
  }

  async createMembership() {
    this._modal.show(MembershipModalComponent, {});
  }

  async submit() {
    this.formSubmitted = true;
    if (!this.coupon.name) this.couponForm.patchValue({lastUpdate: (new Date()).getTime()});
    if (this.couponForm.value.periodOfTime == PeriodOfTime.LIMITED && !this.couponForm.value.periodType && !this.couponForm.value.quantity) return;
    if (this.couponForm.value.periodOfTime == PeriodOfTime.RANGE && (!this.rangeStart || !this.rangeEnd)) return;
    if (this.couponForm.valid) {
      this.saveMembershipsReference();

      let endTime: number;
      if (this.couponForm.value.periodOfTime == PeriodOfTime.LIMITED) {
        if (this.couponForm.value.periodType == PeriodType.DAILY) {
          endTime = this.currentDate + (86400000 * this.couponForm.value.quantity);
        }
        if (this.couponForm.value.periodType == PeriodType.WEEKLY) {
          endTime = this.currentDate + (604800000 * this.couponForm.value.quantity);
        }
        if (this.couponForm.value.periodType == PeriodType.MONTHLY) {
          endTime = this.currentDate + (2592000000 * this.couponForm.value.quantity);
        }
        if (this.couponForm.value.periodType == PeriodType.ANNUAL) {
          endTime = this.currentDate + (31536000000 * this.couponForm.value.quantity);
        }
      }

      if (this.couponForm.value.periodOfTime == PeriodOfTime.RANGE) endTime = this.couponForm.value.rangeEnd;
      if (this.couponForm.value.periodOfTime == PeriodOfTime.FOREVER) endTime = 95646466800000;

      this.couponForm.patchValue({endTime: endTime})

      if (!this.isEdit) {
        this._coupon.add(this.couponForm.value);
      } else {
        this._coupon.update(this.coupon.key, this.couponForm.value);
      }

      AlertService.toastSuccess(this.isEdit ? 'Se ha actualizado correctamente' : 'Se ha guardado correctamente');
      this.modal.hide();
    }
  }

  saveMembershipsReference() {
    let membershipsReference = [];
    for (let membership of this.couponForm.get('memberships').value) {
      membershipsReference.push(this._fds.getReference(`memberships/${membership.key}`));
    }
    this.couponForm.patchValue({memberships: membershipsReference});
  }
}
