export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBDdYC6TcG44JlQhE7kZbozKXgQ_7kYaUM',
    authDomain: 'umana-dev.firebaseapp.com',
    databaseURL: 'https://umana-dev.firebaseio.com',
    projectId: 'umana-dev',
    storageBucket: 'umana-dev.appspot.com',
    messagingSenderId: '54329363226',
    appId: '1:54329363226:web:c33d30adb58cee4f993aa3',
    measurementId: 'G-2KBN3SMVJR'
  },
  apiBaseURL: 'https://us-central1-umana-dev.cloudfunctions.net',
  // apiBaseURL: 'http://localhost:5001/umana-dev/us-central1',
  hmr: false,
  dev: false,
  version: '2.21.1',
  isLiveStreaming: window.location.host == 'envivo.umana.com.mx'
}
