import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {HistoryService} from '../../../admin/services/history.service';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import {Status} from '../../../shared/enums/status.enum';

@Component({
  selector: 'app-pending-payments',
  templateUrl: './pending-payments.component.html',
  styleUrls: ['./pending-payments.component.css']
})
export class PendingPaymentsComponent implements OnInit {
  @Input() userKey: string;
  pendingPayment$: any;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron pagos pendientes',
    title: 'Historial de Pagos'
  };
  paymentType = PaymentType;
  typeSatus = Status;

  constructor(private _history: HistoryService) {
  }

  ngOnInit(): void {
    this.pendingPayment$ = this._history.getAllByUser(this.userKey)
  }

}
