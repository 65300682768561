import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../interfaces/user';
import {HttpClient} from '@angular/common/http';
import {map, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../shared/template-services/auth.service';
import {AngularFireStorage} from '@angular/fire/storage';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: any = null;
  $user: BehaviorSubject<any> = new BehaviorSubject<any>(this.user);

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private http: HttpClient,
              private _auth: AuthService,
              private storage: AngularFireStorage) {
  }

  getAll(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('trash', '==', false));
  }

  getAllTest(): Observable<User[]> {
    return this.db.colWithIds$<User>('usersTest', ref => ref
      .where('trash', '==', false));
  }

  getLatest(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc').limit(5));
  }

  async add(user: User) {
    await this.afs.collection(`users`).add(user);
  }

  async addUserByKey(userKey: string, user: any): Promise<void> {
    await this.db.doc(`users/${userKey}`).set(user);
  }

  async addTest(user: User) {
    await this.afs.collection(`usersTest`).add(user);
  }

  set(userKey: string, user: User): void {
    this.afs.doc(`users/${userKey}`).set(user, {merge: true});
  }

  setTest(userKey: string, user: User): void {
    this.afs.doc(`usersTest/${userKey}`).set(user, {merge: true});
  }

  async update(userKey: string, user: User) {
    await this.afs.doc(`users/${userKey}`).update(user);
  }

  async updateTest(userKey: string, user: User) {
    return this.afs.doc(`usersTest/${userKey}`).update(user);
  }

  updateMembership(userKey: string, membership): void {
    this.afs.doc(`users/${userKey}`).update({
      membership: membership,
      membershipKey: membership.reference.id
    });
  }

  get(userKey: string): Observable<User> {
    return this.db.doc$<User>(`users/${userKey}`);
  }

  delete(userKey: string): void {
    this.afs.doc(`users/${userKey}`).update({trash: true});
  }

  // createAccount(email: string): Promise<any> {
  //   return this.http.post(`${environment.apiBaseURL}/createAccount`, {
  //     email
  //   }).pipe(take(1)).toPromise();
  // }

  async createAccount(email: string, password: string) {
    return await this.http.post(`${environment.apiBaseURL}/createAccount`, {email, password}).pipe(take(1)).toPromise();
  }

  async getSpecificUser(userKey: string): Promise<User> {
    return await this.afs.doc<User>(`users/${userKey}`)
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  getCurrentUser(): Observable<User> {
    return this.db.docWithId$<User>(`users/${this._auth.user.uid}`);
  }

  async loadUser(userKey: string): Promise<any> {
    return new Promise<any>(resolve => {
      this.afs.doc<any>(`users/${userKey}`)
        .valueChanges()
        .subscribe(data => {

          if (!data) {
            resolve(null);
          }

          this.user = data;
          this.user.key = userKey;
          this.$user.next(data);
          resolve(data);
        });
    });
  }

  async uploadPicture(photo, videoKey: string, namePhoto: string) {
    const uploadRef = this.getStorageRef(videoKey, namePhoto);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey, namePhoto);

    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`videos/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`videos/${id}/${photoName}.jpeg`, data);
  }

  createId(): string {
    return this.afs.createId();
  }

  getPayments() {
    return this.afs.collectionGroup('history', ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          data['user'] = a.payload.doc.ref.parent.parent.id;
          return data;
        }))
      );
  }

  resetPassword(uid: string, newPassword: string) {
    return this.http.post(`${environment.apiBaseURL}/resetPassword`, {uid, newPassword}).pipe(take(1)).toPromise();
  }
}
