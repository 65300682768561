<div class="form-group">
  <label>{{label}}</label>
  <div class="custom-file">
    <input type="file"
           class="custom-file-input"
           (change)="onFileChange($event)"
           [ngClass]="{ 'is-invalid': submitted && !isInputValid }">
    <label class="custom-file-label"
           #labelImport>Elegir {{label | lowercase}}</label>
    <small class="form-text text-muted danger"
           *ngIf="!isInputValid && submitted">
      <div>
        {{errorLabel}}
      </div>
    </small>
  </div>
</div>

<img [src]="isImage ? path : '../../../../assets/images/icons/icon.png'"
     *ngIf="path">
