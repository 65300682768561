import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {VideoService} from '../../services/video.service';
import {Video} from '../../interfaces/video';
import {BsModalService} from 'ngx-bootstrap/modal';
import {VideoModalComponent} from '../../modals/video-modal/video-modal.component';
import {AlertService} from '../../../shared/template-services/alert.service';
import {map, take} from 'rxjs/operators';
import {CategoryService} from "../../services/category.service";
import {ObjectService} from "../../../shared/template-services/object.service";
import moment from "moment";
import {FileModalComponent} from '../../modals/file-modal/file-modal.component';

@Component({
  selector: 'app-video-list',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {
  videosFiltered$: Observable<Video[]>;
  videos$: Observable<Video[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron videos registrados',
    title: 'Lista de videos'
  };
  myRadio: any;
  filter: any;
  videosSubscription: Subscription = new Subscription();
  videos: Video[];
  videosDuration: any[] = []
  categories = {}
  categoriesArray = []
  categorySelected: any;

  constructor(private _video: VideoService,
              private _modal: BsModalService,
              private _category: CategoryService) {
  }

  async ngOnInit(): Promise<void> {
    this.categoriesArray = await this._category.getAll().pipe(take(1)).toPromise();
    this.categoriesArray = this.categoriesArray.filter(category => category.program != 'challenge')
    this.categories = ObjectService.indexArray(this.categoriesArray, 'key')

    this.videosSubscription = this._video.getAll().subscribe(data => {
      data = data
        .filter(video => !video.isImage)
        .filter(video => !!video.fileUrl)

      this.videos = data
        .filter(video => video.isCarousel)
        .filter(video => !video.isImage);
      this.videos$ = of(data);
      this.videosFiltered$ = this.videos$;
      if (this.myRadio && this.myRadio != 99) this.videosFiltered$ = of(this.videos);
    });
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
  }

  openAddVideoModal() {
    this._modal.show(FileModalComponent, {
      initialState: {
        lastPosition: this.videos.length,
        videosWithCarousel: this.videos
      },
      class: 'modal-lg'
    });
  }

  editVideo(video: Video) {
    this._modal.show(FileModalComponent, {
      initialState: {
        video: {...video},
        lastPosition: this.videos.length,
        videosWithCarousel: this.videos
      },
      class: 'modal-lg'
    });
  }

  async deleteVideo(video: Video) {
    if (await AlertService.confirm('¿Seguro de que quieres eliminar este video?', '')) {
      this._video.delete(video.key);
      AlertService.toastSuccess('Se ha eliminado correctamente');
    }
  }

  changeStatusFilter() {
    if (this.myRadio == 99) return this.videosFiltered$ = this.videos$;

    this.filter = null;

    switch (this.myRadio) {
      case true:
        this.filter = {name: 'CON CARRUSEL', isCarousel: true};
        break;

      case false:
        this.filter = {name: 'SIN CARRUSEL', isCarousel: false};
        break;
    }

    this.filterPurchases();
  }

  private filterPurchases() {
    this.dataTableConfig.notFoundText = `No se encontraron videos ${this.filter.name}`;

    this.videosFiltered$ = this.videos$.pipe(map(video => video.filter(video => {
      return video.isCarousel == this.filter.isCarousel && !video.isImage;
    })));
  }

  async raisePosition(position: number, key: string) {
    if (position == 1) return;
    let raiseIndex = this.videos.findIndex(video => video.position == position - 1);
    await this._video.update(this.videos[raiseIndex].key, {position: this.videos[raiseIndex].position + 1} as Video);
    await this._video.update(key, {position: position - 1} as Video);
  }

  filterByCategory() {
    console.log(this.categorySelected);
    this.videosFiltered$ = this.videos$.pipe(map(video => video.filter(video => {
      console.log(video.categories);
      return video.categories.some(category => category.id == this.categorySelected.key)
    })));
  }

  async changeDate(videoKey) {
    let date = await AlertService.inputDate('Fecha de creación')
    if (!!date) {
      this._video.update(videoKey, {createdAt: moment(date[0]).toDate().getTime()} as Video)
      AlertService.toastSuccess('Fecha de creación cambiada')
    }
  }
}
