import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateLayoutComponent } from "../layout/private-layout/private-layout.component";
import { MembershipsComponent } from "./pages/memberships/memberships.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { EnvivoComponent } from "../admin/pages/envivo/envivo.component";
import { environment } from "../../environments/environment";

const routes: Routes = [];

const menuItems = [
  {
    title: 'Perfil',
    icon: 'la-user',
    page: '/user/profile'
  },
  {
    title: 'Lista de membresías',
    icon: 'feather ft-star',
    page: '/user/memberships'
  },
  {
    title: 'Cerrar sesión',
    icon: 'feather ft-log-out',
    page: '/auth/logout'
  }
];

const isLiveStreaming = environment.isLiveStreaming;

export const UserRoutes: Routes = isLiveStreaming
  ? [
    {
      path: '',
      component: PrivateLayoutComponent,
      data: { menuItems },
      children: [
        {
          path: 'envivo',
          component: EnvivoComponent
        },
        {
          path: '**',
          redirectTo: 'envivo'
        }
      ]
    }
  ]
  : [
    {
      path: '',
      component: PrivateLayoutComponent,
      data: { menuItems },
      children: [
        {
          path: 'profile',
          component: ProfileComponent
        },
        {
          path: 'memberships',
          component: MembershipsComponent
        },
        {
          path: '**',
          redirectTo: 'memberships'
        }
      ]
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
