import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../interfaces/category';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AchievementModalComponent} from '../../modals/achievement-modal/achievement-modal.component';
import {ProgramsService} from '../../services/programs.service';
import {WorkoutSerie} from '../../interfaces/workout-serie';
import {WorkoutSerieService} from '../../services/workout.service';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit {
  categories$: Observable<Category[]> = this._category.getAllByProgram('method');
  programs$: Observable<any[]> = this._program.getAll();
  workoutSeries$: Observable<WorkoutSerie[]> = this._workoutSerie.getAll();
  dataTableConfig: DataTableConfig = {
    title: 'Logros por categorías',
    hasSearch: false,
    notFoundText: 'No se encontraron categorías',
  };
  programDataTableConfig: DataTableConfig = {
    title: 'Logros por programas',
    hasSearch: false,
    notFoundText: 'No se encontraron programas',
  };
  workoutSeriesDataTableConfig: DataTableConfig = {
    title: 'Logros por Workout Series',
    hasSearch: false,
    notFoundText: 'No se encontraron Workout Series',
  };

  constructor(private _category: CategoryService,
              private _program: ProgramsService,
              private _workoutSerie: WorkoutSerieService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  handleCategorySelected($event: any) {
    this.modal.show(AchievementModalComponent, {
      initialState: {
        ref: $event,
        type: 'category'
      },
      class: 'modal-lg'
    });
  }

  handleProgramSelected($event: any) {
    this.modal.show(AchievementModalComponent, {
      initialState: {
        ref: $event,
        type: 'program'
      },
      class: 'modal-lg'
    });
  }

  handleWorkoutSeriesSelected($event: any) {
    this.modal.show(AchievementModalComponent, {
      initialState: {
        ref: $event,
        type: 'workoutSeries'
      },
      class: 'modal-lg'
    });
  }
}
