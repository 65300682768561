import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-preview-ticket',
  templateUrl: './preview-ticket.component.html',
  styleUrls: ['./preview-ticket.component.css']
})
export class PreviewTicketComponent implements OnInit {
  @Input() imageUrl: string;

  constructor() { }

  ngOnInit(): void {
  }

}
