<div class="p-1 card">
  <div class="card-header">
    <h2>
      Configuración del correo electrónico de bienvenida de membresía comprada
    </h2>
  </div>
  <div class="d-flex justify-content-center">
    <div class="card-body col-md-6">
      <form [formGroup]="purchasedMembershipEmailSettingsForm">
        <div class="form-group">
          <div class="mt-1">
            <label for="subject">
              Asunto*
            </label>
            <input type="text" id="subject" class="form-control" formControlName="subject"
              [ngClass]="{'is-invalid': formControls.subject?.errors}">
            <small class="invalid-feedback">Campo obligatorio</small>
          </div>
          <div class="mt-1">
            <label for="message">
              Mensaje
            </label>
            <textarea type="text" id="message" class="form-control" rows="3" formControlName="message"
            [ngClass]="{'is-invalid': formControls.message?.errors}"></textarea>
            <small class="invalid-feedback">Campo obligatorio</small>
          </div>
          <div class="mt-1">
            <label for="link">
              Enlace
            </label>
            <input type="text" id="link" class="form-control" formControlName="link">
          </div>
          <div class="mt-1">
            <label for="linkText">
              Texto del enlace
            </label>
            <input type="text" id="linkText" class="form-control" formControlName="linkText">
          </div>
          <div class="mt-1">
            <app-upload-file-storage [routeFile]="routeFile"
              [showPreview]="!purchasedMembershipEmailSettingsForm.value.photoUrl || (!!purchasedMembershipEmailSettingsForm.value.photoUrl && !!imageLoaded)"
              label="imagen" #uploadPhoto (imageLoaded)="assignImageLoaded()">
            </app-upload-file-storage>
          </div>
          <img [src]="purchasedMembershipEmailSettingsForm.value.photoUrl" class="w-100 mt-1"
            *ngIf="!imageLoaded && !!purchasedMembershipEmailSettingsForm.value.photoUrl" alt="Imagen del correo de bienvenida">
        </div>
        <div class="mt-1">
          <app-upload-file-storage [routeFile]="getProgramRouteFile()"
            label="archivo de bienvenida" #uploadFile
            (imageLoaded)="assignFileLoaded()"></app-upload-file-storage>

        </div>
        <div class="mt-1">
          <a [href]="purchasedMembershipEmailSettingsForm.value.fileUrl"
             target="_blank"
             *ngIf="!!purchasedMembershipEmailSettingsForm.value.fileUrl">
            Descargar archivo
          </a>
          <span *ngIf="!purchasedMembershipEmailSettingsForm.value.fileUrl">No se encontró un archivo registrado</span>
        </div>
        <div class="mt-1 d-flex justify-content-end" (click)="submit()">
          <button class="btn btn-primary"[disabled]="loading">
            <span *ngIf="!loading">Guardar</span>
            <span *ngIf="loading">Guardando... <i class="la la-circle-o-notch la-spin"></i></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>