import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/internal/Observable';
import { DataTableConfig } from 'src/app/shared/interfaces/data-table-config';
import { AlertService } from 'src/app/shared/template-services/alert.service';
import { WorkoutSerie } from '../../interfaces/workout-serie';
import { WorkoutSerieModalComponent } from '../../modals/workout-serie-modal/workout-serie-modal.component';
import { WorkoutSerieService} from '../../services/workout.service';

@Component({
  selector: 'app-workout-series',
  templateUrl: './workout-series.component.html',
  styleUrls: ['./workout-series.component.css']
})
export class WorkoutSeriesComponent implements OnInit {
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron desafíos registrados',
    title: 'Lista de desafíos'
  };
  workoutSeries$: Observable<any[]>;
  workoutSerie: WorkoutSerie;

  constructor(private _workoutSerie: WorkoutSerieService,
              private _modal: BsModalService) {
  }

  async ngOnInit() {
    this.workoutSeries$ = this._workoutSerie.getAll();
  }

  openAchievementsModal() {
    this._modal.show(WorkoutSerieModalComponent);
  }

  openEditAchievementsModal(workoutSerie: WorkoutSerie) {
    this._modal.show(WorkoutSerieModalComponent, {
      initialState: {
        workoutSerie:  {...workoutSerie},
        isEdit: true
      },
      class: 'modal-lg'
    });
  }

  async deleteAchievement(workoutSerie: WorkoutSerie) {
    if (await AlertService.confirm('¿Estás seguro de que deseas borrar este reto?')) {
      this._workoutSerie.delete(workoutSerie.key);
      AlertService.success('Reto eliminado correctamente')
    }
  }
}
