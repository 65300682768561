import { Component, OnInit } from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';
import { MembershipService } from '../../services/membership.service';

@Component({
  selector: 'app-cancellation-opinions',
  templateUrl: './cancellation-opinions.component.html',
  styleUrls: ['./cancellation-opinions.component.css']
})
export class CancellationOpinionsComponent implements OnInit {
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Motivos de cancelación'
  };
  cancellationOpinions$: Observable<any[]>;

  constructor(private _membership: MembershipService) { }

  ngOnInit(): void {
    this.cancellationOpinions$ = this._membership.getCancellationOpinions();
  }

}
