import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../shared/template-services/firebase-data.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Category } from '../interfaces/category';
import { take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
  }

  getAll(): Observable<Category[]> {
    return this.db.colWithIds$<Category>('categories', ref => ref
      .where('trash', '==', false)
      .orderBy('position', 'asc'));
  }

  getAllTools(): Observable<any[]> {
    return this.db.colWithIds$<any>('tools', ref => ref
      .where('trash', '==', false));
  }

  add(category: Category): Promise<DocumentReference> {
    return this.afs.collection(`categories`).add(category);
  }

  addTool(tool: any): void {
    this.afs.collection(`tools`).add(tool);
  }

  update(categoryKey: string, category: Partial<Category>): Promise<void> {
    return this.afs.doc(`categories/${categoryKey}`).update(category);
  }

  updateTool(toolKey: string, tool: any): Promise<void> {
    return this.afs.doc(`tools/${toolKey}`).update(tool);
  }

  get(categoryKey: string): Observable<Category> {
    return this.db.doc$<Category>(`categories/${categoryKey}`);
  }

  delete(categoryKey: string): void {
    this.afs.doc(`categories/${categoryKey}`).update({ trash: true });
  }

  deleteTool(toolKey: string): void {
    this.afs.doc(`tools/${toolKey}`).update({ trash: true });
  }

  getReference(categoryKey: string): DocumentReference {
    return this.db.doc(`categories/${categoryKey}`).ref;
  }

  getReferenceTools(toolsKey: string): DocumentReference {
    return this.db.doc(`tools/${toolsKey}`).ref;
  }

  getAllByProgram(program: string): Observable<Category[]> {
    return this.db.colWithIds$<Category>('categories', ref => ref
      .where('trash', '==', false)
      .where('program', '==', program)
      .orderBy('position', 'asc'));
  }

  async uploadPicture(photo, videoKey: string) {
    const uploadRef = this.getStorageRef(videoKey);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey);

    return url;
  }

  getStorageRef(id: string) {
    return this.storage.ref(`categories/${id}`);
  }

  uploadImage(data, id) {
    return this.storage.upload(`categories/${id}`, data);
  }
}
