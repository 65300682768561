<app-datatable [rows$]="imagesCarousel$"
               [config]="dataTableConfig">

    <app-datatable-column title="Título">
        <ng-template let-row #column>
            <img src="{{row.photoUrl}}" class="image">
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Posición" [isClickable]="false">
        <ng-template let-row #column>
            <label class="ml-1 mr-1">{{row.position}}</label>
        </ng-template>
    </app-datatable-column>

    <app-datatable-column title="" [isClickable]="false">
        <ng-template let-row #column>
            <div class="d-flex flex-row w-100 justify-content-end">
                <i class="feather ft-edit fa-lg mr-2" (click)="editImageCarousel(row)"></i>
                <i class="feather ft-trash-2 fa-lg" (click)="deleteImageCarousel(row, row.position)"></i>
            </div>
        </ng-template>
    </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openImageCarouselModal()"></app-add-float-button>
