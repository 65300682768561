import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ImageCarousel } from '../../interfaces/image-carousel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../shared/template-services/alert.service';
import { UploadFileStorageComponent } from '../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { VideoService } from '../../services/video.service';
import { SettingService } from '../../services/setting.service';
import { ProgramsService } from '../../services/programs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-carousel-modal',
  templateUrl: './image-carousel-modal.component.html',
  styleUrls: ['./image-carousel-modal.component.css']
})
export class ImageCarouselModalComponent implements OnInit, OnDestroy {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  lastPosition: number;
  imageCarousel: ImageCarousel = { key: '' } as ImageCarousel;
  imageCarouselForm: FormGroup;
  formSubmitted: boolean = false;
  isEdit: boolean = false;
  routeFile: string = '';
  isThereImage: boolean = false;
  isLive: boolean = false;
  streaming: string;
  videos: any[] = [];
  programs: any[] = [];
  videosSubscription: Subscription = new Subscription();
  programsSubscription: Subscription = new Subscription();
  sections: any[] = [
    { label: 'Home', value: 'home' },
    { label: 'Membresías', value: 'memberships' },
    { label: 'Perfil', value: 'profile' },
    { label: 'Favoritos', value: 'favorites' },
    { label: 'Todos los videos', value: 'videos' },
    { label: 'Todos los programas', value: 'programs' },
    { label: 'Calendario', value: 'calendar' },
    { label: 'Recetas', value: 'recipes' },
    { label: 'Retos', value: 'challenges' },
    { label: 'Workout series', value: 'workout-series' },
    { label: 'Actividades', value: 'activity' }
  ];

  constructor(private formBuilder: FormBuilder,
              private _video: VideoService,
              private _program: ProgramsService,
              public modal: BsModalRef,
              private _fds: FirebaseDataService,
              private _setting: SettingService) {
    this.createForm();
    this.routeFile = `imagesCarousel/${new Date().getTime()}/image`;
  }

  ngOnInit(): void {
    if (!!this.imageCarousel.photoUrl) {
      this.isEdit = true;
      this.imageCarouselForm.patchValue(this.imageCarousel);
    }

    if (!this.imageCarousel.photoUrl) {
      this.imageCarousel.key = this._fds.createID();
    }

    this.videosSubscription = this._video.getAll().subscribe((videos: any[]) => {
      this.videos = videos;
    });

    this.programsSubscription = this._program.getAll().subscribe((programs: any[]) => {
      this.programs = programs.filter(program => !program.trash);
    });
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
    this.programsSubscription.unsubscribe();
  }

  get formControls() {
    return this.imageCarouselForm.controls;
  }

  createForm(): void {
    this.imageCarouselForm = this.formBuilder.group({
      photoUrl: ['', Validators.required],
      position: [''],
      isImage: [true],
      isCarousel: [true],
      url: [''],
      insideUrl: [''],
      redirectVideo: [''],
      redirectProgram: [''],
      categories: [[]],
      title: [''],
      trash: [false],
      streaming: [''],
      chat: ['']
    });
  }

  assignIsThereImage(event: boolean) {
    this.isThereImage = event;
  }

  async assignPhotoUrl() {
    const url = await this.filesStorage.uploadDocument();
    if (!!url) {
      this.imageCarouselForm.patchValue({ ['photoUrl']: url });
    } else {
      if (!this.imageCarousel.photoUrl) {
        return this.filesStorage.assignError();
      }
    }
  }

  async submit() {
    this.formSubmitted = true;
    if (this.filesStorage.isInputValid) await this.assignPhotoUrl();
    if (this.imageCarouselForm.valid) {
      if (this.imageCarouselForm.value.streaming != '') await this._setting.update({ streaming: this.imageCarouselForm.value.streaming });
      this._video.set(this.imageCarousel.key, this.imageCarouselForm.value);

      AlertService.toastSuccess(this.isEdit ? 'Se ha actualizado correctamente' : 'Se ha guardado correctamente');
      this.modal.hide();
    }
  }

  changeIsLive() {
    this.isLive = !this.isLive;

    if (this.isLive) {
      this.imageCarouselForm.patchValue({ url: 'http://envivo.umana.com.mx/#/user/envivo' });
    } else {
      this.imageCarouselForm.patchValue({ url: '' });
    }
  }

  onInsideUrl() {
    if (this.imageCarouselForm.value.insideUrl == 'video') this.imageCarouselForm.patchValue({ 'redirectProgram': '' });
    if (this.imageCarouselForm.value.insideUrl == 'program') this.imageCarouselForm.patchValue({ 'redirectVideo': '' });
    if (this.imageCarouselForm.value.insideUrl != 'video' && this.imageCarouselForm.value.insideUrl != 'program') {
      this.imageCarouselForm.patchValue({
        ['redirectVideo']: '',
        ['redirectProgram']: ''
      });
    }
  }
}
