import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Membership} from '../../interfaces/membership';
import {HistoryService} from '../../services/history.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import { Status } from 'src/app/shared/enums/status.enum';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  payment$: Observable<Membership[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron membresías registradas',
    title: 'Historial de membresías'
  };
  typeSatus = Status;
  paymentType = PaymentType
  constructor(private _history: HistoryService,
              private router: Router) { }

  ngOnInit(): void {
    this.payment$ = this._history.getAllPayment()
    this._history.getAllPayment().subscribe(resp => console.log(resp))
  }

  goToPaymentDetail(userKey: string, paymentKey: string){
    this.router.navigateByUrl(`/admin/payment-detail/${userKey}/${paymentKey}`)
  }

}
