import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {RecipeComponent} from '../../modals/recipe/recipe.component';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../shared/template-services/alert.service';
import {SlideService} from '../../services/slide.service';
import { SlideComponent } from '../../modals/slide/slide.component';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.css']
})
export class SlidesComponent implements OnInit {
  rows$: Observable<any[]> = new Observable<any[]>();
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Slides de bienvenida'
  };

  constructor(private _slide: SlideService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.rows$ = this._slide.getAll();
  }

  openEditRecipeModal(slide) {
    this.modal.show(SlideComponent, {
      initialState: {
        isEdit: true,
        slide: {...slide}
      }
    });
  }

  openAddRecipeModal() {
    this.modal.show(SlideComponent);
  }

  async handleDelete(row: any) {
    if (await AlertService.confirm('¿Está seguro que desea eliminar este elemento?')) {
      this._slide.update(row.key, {trash: true});
    }
  }
}
