<div class="background-image"></div>

<div class="content2 d-flex align-items-center justify-content-center w-100">
  <div class="card border-grey border-lighten-3 m-0">
    <div class="card-header border-0">
      <div class="card-title text-center">
        <div class="p-1">
          <img src="assets/images/Logo-_umana_azul-copy.png" alt="UMANA" class="w-100">
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form [formGroup]="loginForm" (ngSubmit)="submit()" class="form-horizontal form-simple">

          <div class="form-group position-relative has-icon-right mb-0">
            <mat-form-field class="w-100">
              <mat-label>Correo electrónico</mat-label>
              <i matSuffix class="feather ft-user fa-2x"></i>
              <input matInput
                     type="email"
                     formControlName="email"
                     required>
              <mat-error *ngIf="formSubmitted && formControls.email.errors">
                Es obligatorio
              </mat-error>
            </mat-form-field>
            <div class="form-group position-relative has-icon-right mt-1">
              <mat-form-field class="w-100">
                <mat-label>Contraseña</mat-label>
                <i matSuffix class="icon-lock fa-2x"></i>
                <input matInput
                       type="password"
                       formControlName="password"
                       required>
                <mat-error *ngIf="formSubmitted && formControls.password.errors">
                  Es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button class="btn btn-info btn-block"><i
            class="feather ft-unlock pr-1"></i>Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
