import {Component, Input, OnInit} from '@angular/core';
import {HistoryService} from "../../services/history.service";
import {Observable} from "rxjs";
import {Membership} from "../../interfaces/membership";
import {DataTableConfig} from "../../../shared/interfaces/data-table-config";

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  @Input() userKey: string;
  history$: Observable<Membership[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron membresías registradas',
    title: 'Historial de membresías'
  };

  constructor(private _history: HistoryService) {
  }

  ngOnInit(): void {
    this.history$ = this._history.getAllByUser(this.userKey);
  }
}
