import { Component, OnInit } from '@angular/core';
import { RecipesV2Service } from '../../services/recipes-v2.service';
import { Observable } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RecipeModalComponent } from '../../modals/calendar-day/components/recipe-modal/recipe-modal.component';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-recipes-v2',
  templateUrl: './recipes-v2.component.html',
  styleUrls: ['./recipes-v2.component.css']
})
export class RecipesV2Component implements OnInit {
  recipes$: Observable<any[]>;
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Recetas'
  };

  constructor(private _recipesV2: RecipesV2Service,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.recipes$ = this._recipesV2.getAll();
  }

  openAddRecipeModal() {
    this.modal.show(RecipeModalComponent);
  }

  openEditRecipeModal(recipe:any) {
    this.modal.show(RecipeModalComponent, {
      initialState:{
        recipe
      }
    });
  }

  async handleDelete(recipeKey: string) {
    if (await AlertService.confirm('¿Está seguro que desea eliminar este elemento?')) {
      await this._recipesV2.update({
        data: { trash: true },
        id: recipeKey
      });

      AlertService.toastSuccess('Receta eliminada correctamente');
    }
  }

}
