<div class="recipe-wrapper p-2 pb-4">
  <h4 class="mb-2">Nueva receta</h4>

  <ti-form [form]="recipeForm"
           (submit)="submit()"
           (onCancel)="modal.hide()"
           [submitted]="submitted">
    <ti-input name="title"
              label="Título de la receta">
    </ti-input>

    <ti-input name="calories"
              type="number"
              label="Calorías">
    </ti-input>

    <ti-input name="categories"
              type="select"
              [items]="categories"
              bindLabel="name"
              multiple
              label="Categorías">
    </ti-input>

    <button class="btn btn-success mb-1"
            type="button"
            (click)="addIngredient()">Agregar ingrediente
    </button>

    <div [formGroup]="recipeForm"
         class="mb-2">
      <ng-container formArrayName="ingredients">
        <ng-container *ngFor="let ingredientsForm of ingredients.controls; index as i">
          <ti-form [form]="ingredientsForm"
                   [hideSaveButton]="true">

            <div class="d-flex align-items-center mb-1">
              <ti-input placeholder="Ingrediente"
                        classes="mb-0"
                        name="name"></ti-input>
              <div>
                <ti-icon-button icon="trash"
                                class="ml-1"
                                color="danger"
                                tooltip="Eliminar"
                                (click)="deleteIngredient(i)"></ti-icon-button>
              </div>
            </div>
          </ti-form>
        </ng-container>
      </ng-container>
    </div>

    <ti-input name="instructions"
              type="ckeditor"
              label="Instrucciones">
    </ti-input>

    <ti-input type="file-dropzone"
              name="imageUrl"
              label="Imagen"
              dropzoneLabel="Arrastra una imagen o haz clic para seleccionarla"
              accept="image/*"></ti-input>

    <img [src]="recipe?.imageUrl" style="height: 100px" *ngIf="recipeForm.value.imageUrl == recipe?.imageUrl">
  </ti-form>
</div>

<div class="backdrop"></div>
