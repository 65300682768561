import { Injectable } from '@angular/core';
import {FirebaseDataService} from "../../shared/template-services/firebase-data.service";
import {AngularFirestore, DocumentReference} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {Notification} from "../interfaces/notification";
import {take} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/storage";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  getAll(): Observable<Notification[]> {
    return this.db.colWithIds$<Notification>('notification', ref => ref
      .where('trash', '==', false));
  }

  set(notificationKey: string, notification: Notification): void {
    this.afs.doc(`notification/${notificationKey}`).set(notification, {merge: true});
  }

  update(notificationKey: string, notification: Notification): Promise<void> {
    return this.afs.doc(`notification/${notificationKey}`).update(notification);
  }

  get(notificationKey: string): Observable<Notification> {
    return this.db.doc$<Notification>(`notification/${notificationKey}`);
  }

  delete(notificationKey: string): void {
    this.afs.doc(`notification/${notificationKey}`).update({trash: true});
  }

  getReference(notificationKey: string): DocumentReference {
    return this.db.doc(`notification/${notificationKey}`).ref;
  }

  async uploadPicture(photo, notificationKey: string, nameImage: string) {
    const uploadRef = this.getStorageRef(notificationKey, nameImage);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, notificationKey, nameImage);
    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`notifications/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`notifications/${id}/${photoName}.jpeg`, data);
  }

  createId(): string {
    return this.afs.createId();
  }
}
