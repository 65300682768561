import {Component, OnInit} from '@angular/core';
import {RecipeService} from '../../services/recipe.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {RecipeComponent} from '../../modals/recipe/recipe.component';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css']
})
export class RecipesComponent implements OnInit {
  rows$: Observable<any[]> = new Observable<any[]>();
  dataTableConfig: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: 'Recetas y más'
  };

  constructor(private _recipe: RecipeService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.rows$ = this._recipe.getAll();
  }

  openEditRecipeModal(recipe) {
    this.modal.show(RecipeComponent, {
      initialState: {
        isEdit: true,
        recipe: {...recipe}
      }
    });
  }

  openAddRecipeModal() {
    this.modal.show(RecipeComponent);
  }

  async handleDelete(row: any) {
    if (await AlertService.confirm('¿Está seguro que desea eliminar este elemento?')) {
      this._recipe.update(row.key, {trash: true});
    }
  }
}
