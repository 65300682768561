import {Component, OnInit} from '@angular/core';
import {Video} from '../../interfaces/video';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {VideoService} from '../../services/video.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Observable, Subscription} from 'rxjs';
import {CategoryService} from '../../services/category.service';
import {Category} from '../../interfaces/category';
import {MembershipService} from '../../services/membership.service';
import {take} from 'rxjs/operators';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.css']
})
export class FileModalComponent implements OnInit {
  video: Video = {key: '', isCarousel: false} as Video;
  videoForm: FormGroup;
  formSubmitted: boolean = false;
  categories$: Observable<Category[]>;
  memberships$: Observable<any[]>;
  picture: any;
  path;
  lastPosition: number;
  videosWithCarousel: Video[];
  color: string = '#ffffff';
  categorySubscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _video: VideoService,
              private _category: CategoryService,
              private _membership: MembershipService,
              private _fds: FirebaseDataService) {
    this.createForm();
  }

  async ngOnInit() {
    this.categories$ = this._category.getAll();
    this.memberships$ = this._membership.getAll();

    if (!!this.video.title) {
      this.color = this.video.color;
      let categories = await this.categories$.pipe(take(1)).toPromise();
      let memberships = await this.memberships$.pipe(take(1)).toPromise();

      this.video.categories = categories.filter(categoryRef => {
        return this.video.categories.some(category => category.id === categoryRef.key);
      });

      this.video.memberships = memberships.filter(membershipRef => {
        return this.video.memberships.some(membership => membership.id === membershipRef.key);
      });

      this.video.createdAt = new Date(this.video.createdAt);
      this.videoForm.patchValue(this.video);
      this.path = this.video.fileUrl;
    }
  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
  }

  get formControls() {
    return this.videoForm.controls;
  }

  createForm(): void {
    this.videoForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      videoUrl: [''],
      fileUrl: ['', Validators.required],
      memberships: [[], Validators.required],
      categories: [[], Validators.required],
      tools: [[]],
      photoUrl: [''],
      difficulty: [''],
      color: ['#ffffff'],
      createdAt: [''],
      duration: [''],
      isCarousel: [false],
      position: [''],
      isImage: [false],
      isImageFile: [false],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;

    if (this.videoForm.valid) {
      if (!this.video.title) {
        this.video.key = this._video.createId();
      }

      if (!!this.picture) {
        this.videoForm.patchValue({fileUrl: await this._video.uploadPicture(this.picture, this.video.key, 'fileUrl')});
        if (this.videoForm.value.isImageFile) {
          this.videoForm.patchValue({photoUrl: this.videoForm.value.fileUrl});
        }
      }

      if (!this.video.title) {
        this.videoForm.patchValue({createdAt: new Date().getTime()});
      } else {
        this.videoForm.patchValue({createdAt: new Date(this.videoForm.value.createdAt).getTime()});
      }

      this.saveCategoriesRefeference();
      this.saveMembershipsRefeference();

      this._video.set(this.video.key, {...this.videoForm.value, color: this.color} as Video);

      let membershipsKeys = this.videoForm.value.memberships.map(membership => membership.id);

      this._video.sendNotification(this.videoForm.value.title, this.videoForm.value.photoUrl, membershipsKeys);

      AlertService.toastSuccess('Se guardó el archivo exitosamente');
      this.modal.hide();
    }
  }

  async createCategory() {
    const category: string = await AlertService.input('Añadir categoría');
    if (!!category) {
      let categories = await this._category.getAll().pipe(take(1)).toPromise();
      this._category.add({name: category, trash: false, position: categories.length + 1});
      AlertService.toastSuccess(`Se ha creado "${category}" correctamente`);
    }
  }

  choosePicture(event) {
    this.picture = event.target.files[0];
    if (event.target.files && this.picture) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader> event.target).result;
      };
      reader.readAsDataURL(this.picture);
    }
  }

  saveCategoriesRefeference() {
    let categoriesReference = [];
    for (let category of this.videoForm.get('categories').value) {
      categoriesReference.push(this._category.getReference(category.key));
    }
    this.videoForm.patchValue({categories: categoriesReference});
  }

  saveMembershipsRefeference() {
    let membershipsReference = [];
    for (let membership of this.videoForm.get('memberships').value) {
      membershipsReference.push(this._fds.getReference(`memberships/${membership.key}`));
    }
    this.videoForm.patchValue({memberships: membershipsReference});
  }
}
