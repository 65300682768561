import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Achievement} from '../interfaces/achievement';
import {take} from 'rxjs/operators';
import _ from 'lodash';
import {ObjectService} from '../../shared/template-services/object.service';

@Injectable({
  providedIn: 'root'
})
export class AchievementService extends FirebaseDataService {

  constructor(private _db: AngularFirestore,
              private _storage: AngularFireStorage) {
    super(_db, _storage);
  }

  async add(achievement: Achievement): Promise<any> {
    return await this._db.collection(`achievements`).add({
      ...achievement,
      icons: await this.getUrlIcons(achievement.icons, achievement.name),
      createdAt: Date.now()
    });
  }

  async getUrlIcons(icons: any, name: string) {
    return await Object.keys(icons).reduce(async (acc, key) => {
      return {
        ...(await acc),
        [key]: ObjectService.isFile(icons[key])
          ? await this.uploadFile(`achievements/${name}-${key}`, icons[key] as File)
          : icons[key]
      }
    }, {})
  }

  async update(achievementKey: string, achievement: Achievement): Promise<any> {
    return await this._db.doc(`achievements/${achievementKey}`).update({
      ...achievement,
      icons: await this.getUrlIcons(achievement.icons, achievement.name),
      updatedAt: Date.now()
    });
  }

  async getByRef(ref: DocumentReference): Promise<any> {
    return _.first(await this.colWithIds$('achievements', queryRef => queryRef
      .where('ref', '==', ref))
      .pipe(take(1)).toPromise());
  }
}
